import { forwardRef } from 'react'
import { Select as AntSelect, Space } from 'antd'
import { useIntl } from 'react-intl'

const MultiSelect = forwardRef((props, ref) => {
  const intl = useIntl()
  let { options, value, onChange, onBlur, className, tabIndex, isValid, errorMessage, readOnly } = props
  const filteredOptions = options?.filter((x) => !value?.includes(x.value))

  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <AntSelect
          style={{
            width: '100%',
          }}
          ref={ref}
          mode='multiple'
          showSearch
          allowClear
          value={options?.filter((x) => value?.includes(x.value))}
          placeholder={intl.formatMessage({ id: 'SelectAnOption' })}
          optionFilterProp='label'
          className={className}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          options={filteredOptions}
          disabled={readOnly}
          tabIndex={tabIndex}
          status={isValid === false ? 'error' : ''}
          getPopupContainer={(trigger) => trigger.parentNode}
          size='large'
        />
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default MultiSelect
