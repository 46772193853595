import { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../../components/shared/Toast'
import NumberFormat from '../../../../components/shared/NumberFormat'
import { DATETIME_FORMAT_SLASH, DEFAULT_VALUE } from '../../../../utilities/constant'
import { WITHDRAWAL_STATUS_ENUM } from '../../../../utilities/enum'
import dayjs from 'dayjs'
import { Tag, Tooltip } from 'antd'
import Icon from '../../../../components/shared/Icon'
import Confirm from '../../../../components/shared/Confirm'
import { useAuth } from '../../../../components/auth/AuthContext'
import { useWithdrawalHistoryService } from '../../../../services/apiServices'
import { useAccountHeaderContext } from './AccountHeaderContext'
import Tabs from '../../../../components/shared/Tabs'
import Table from '../../../../components/shared/Table'
import WithdrawalEditor from './WithdrawalEditor'

const WithdrawalHistories = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const { reloadWithdrawalHistories, setReloadWithdrawalHistories } = useAccountHeaderContext()
  const { getByUserAsync, deleteAsync } = useWithdrawalHistoryService()

  const [modalState, setModalState] = useState({ id: null, show: false })
  const [selectedTab, setSelectedTab] = useState(1)
  const [dates, setDates] = useState([dayjs(), dayjs()])

  useEffect(() => {
    let startDate = null
    let endDate = null

    switch (selectedTab) {
      case 1:
        startDate = dayjs().subtract(6, 'month').startOf('month')
        endDate = dayjs().endOf('day')
        break
      case 2:
        startDate = dayjs().startOf('year')
        endDate = dayjs().endOf('year')
        break
      case 3:
      default:
        startDate = null
        endDate = null
        break
    }

    setReloadWithdrawalHistories(true)
    setDates([startDate, endDate])
  }, [selectedTab])

  const fetchData = async (pageIndex, pageSize) => {
    setReloadWithdrawalHistories(false)
    return await getByUserAsync({ pageIndex, pageSize, fromDate: dates[0], toDate: dates[1] })
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const deleteWithdrawalHistory = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setReloadWithdrawalHistories(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Date' })}`,
      dataIndex: 'createdDate',
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      width: 100,
      showSorterTooltip: false,
      render: (createdDate) => {
        return dayjs.utc(createdDate).local().format(DATETIME_FORMAT_SLASH.ddMMyyyyHHmm)
      },
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Description' })}`,
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Amount' })}`,
      dataIndex: 'withdrawalAmount',
      sorter: (a, b) => a.withdrawalAmount - b.withdrawalAmount,
      width: 100,
      showSorterTooltip: false,
      render: (withdrawalAmount) => <NumberFormat amount={withdrawalAmount} suffix={DEFAULT_VALUE.VND} />,
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Status' })}`,
      dataIndex: 'statusName',
      sorter: (a, b) => a.statusName.localeCompare(b.statusName),
      width: 80,
      showSorterTooltip: false,
      render: (statusName, record) => (
        <Tag
          color={`${record.status === WITHDRAWAL_STATUS_ENUM.NEW ? 'blue' : record.status === WITHDRAWAL_STATUS_ENUM.APPROVED ? 'cyan' : 'red'}`}
          className='me-1 fs-7 cursor-pointer'
        >
          {intl.formatMessage({ id: `User.Billing.History.Status.${statusName.toUpperCase()}` })}
        </Tag>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.TransactionDate' })}`,
      dataIndex: 'transactionDate',
      sorter: (a, b) => a.transactionDate.localeCompare(b.transactionDate),
      width: 100,
      showSorterTooltip: false,
      render: (transactionDate) => {
        return transactionDate ? dayjs.utc(transactionDate).local().format(DATETIME_FORMAT_SLASH.ddMMyyyyHHmm) : ''
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => {
        return (
          <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
            <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
              <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
                <Icon iconName={item.status === WITHDRAWAL_STATUS_ENUM.NEW ? 'EditOutlined' : 'EyeOutlined'} />
              </div>
            </Tooltip>
            {user.profile.sub === item.createdBy && item.status === WITHDRAWAL_STATUS_ENUM.NEW && (
              <Confirm
                title={intl.formatMessage({ id: 'Notifications' })}
                description={intl.formatMessage({ id: 'Confirm.Remove' })}
                onConfirm={() => deleteWithdrawalHistory(item.id)}
                tooltip={intl.formatMessage({ id: 'Delete' })}
                button={
                  <div className='btn btn-sm btn-icon btn-light-danger'>
                    <Icon iconName='DeleteOutlined' />
                  </div>
                }
              />
            )}
          </div>
        )
      },
    },
  ]

  var searchToolbar = [
    { value: 1, label: `${intl.formatMessage({ id: 'User.Billing.History.Month' })}` },
    { value: 2, label: `${intl.formatMessage({ id: 'User.Billing.History.Year' })}` },
    { value: 3, label: `${intl.formatMessage({ id: 'User.Billing.History.AllTime' })}` },
  ]

  return (
    <div className='card card-flush mt-4'>
      <div className='card-header border-0 py-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>
            <FormattedMessage id='User.Billing.History' />
          </span>
        </h3>

        <div className='card-toolbar m-0 align-items-center flex-column'>
          <Tabs header={searchToolbar} selectedTab={selectedTab} onChange={setSelectedTab} type={2} />
        </div>
      </div>
      <div className='card-body p-0'>
        <Table columns={columns} fetchData={fetchData} onReload={reloadWithdrawalHistories} />
      </div>
      <WithdrawalEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setReloadWithdrawalHistories(true)} />
    </div>
  )
}

export default WithdrawalHistories
