/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useNewsService, usePromotionService } from '../../../services/apiServices'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { MODULE } from '../../../utilities/constant'
import { addToFormData } from '../../../utilities/sharedFunction'

dayjs.extend(utc)

const NewsEditor = () => {
  const intl = useIntl()
  const { newsId } = useParams()
  const componentRefs = useRef({})
  const navigate = useNavigate()
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = useNewsService()
  const { getCurrentListAsync } = usePromotionService()

  const [title, setTitle] = useState()
  const [promotionId, setPromotionId] = useState()
  const [subTitle, setSubTitle] = useState()
  const [content, setContent] = useState()
  const [publishDate, setPublishDate] = useState()
  const [banner, setBanner] = useState()
  const [fileBanner, setFileBanner] = useState()
  const [isActive, setIsActive] = useState()

  const [promotions, setPromotions] = useState([])

  useEffect(() => {
    const fetchPromotions = async () => {
      const { result } = await getCurrentListAsync()
      if (result) {
        setPromotions(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchPromotions()
    if (newsId) {
      fetchData()
    } else {
      setData()
    }
  }, [newsId])

  const fetchData = async () => {
    const { result } = await getByIdAsync(newsId)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setTitle(item?.title)
    setPromotionId(item?.promotionId)
    setSubTitle(item?.subTitle)
    setContent(item?.content)
    setPublishDate(item?.publishDate ? dayjs.utc(item?.publishDate).local() : dayjs(new Date()))
    setBanner(item?.banner)
    setFileBanner(item?.fileBanner ?? [])
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'id', newsId)
    addToFormData(formData, 'title', title)
    addToFormData(formData, 'subTitle', subTitle)
    addToFormData(formData, 'promotionId', promotionId)
    addToFormData(formData, 'content', content)
    addToFormData(formData, 'publishDate', publishDate.toISOString())
    addToFormData(formData, 'isActive', isActive)
    addToFormData(formData, 'banner', banner)

    if (!_.isEmpty(fileBanner)) {
      let file = Array.isArray(fileBanner) ? fileBanner[0] : fileBanner
      if (file.originFileObj) {
        addToFormData(formData, 'fileBanner', file.originFileObj)
      }
    }

    const { error, result } = _.isEmpty(newsId) ? await insertAsync(formData) : await updateAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchData()
      navigate(`/${MODULE.ECOM}/news/${result.id}`)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <div id='news-detail' className='card card-flush'>
      <div className='card-header min-h-10px'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Thumbnail' />
          </h4>
        </div>
      </div>
      <div className='card-body'>
        <form className='d-flex flex-column gap-4'>
          <div className='row'>
            <div className='col-md-4 mb-4 mb-md-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.title = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.News.Title' })}
                value={title}
                onChange={setTitle}
                isRequired={true}
                tabIndex={tabIndex++}
              />
            </div>
            <div className='col-md-4 mb-4 mb-md-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.promotionId = ref)}
                controlType={CONTROL_TYPE.SELECT}
                label={intl.formatMessage({ id: 'Ecom.News.Promotion' })}
                value={promotionId}
                onChange={setPromotionId}
                options={promotions}
                tabIndex={tabIndex++}
              />
            </div>
            <div className='col-md-4'>
              <FormControl
                ref={(ref) => (componentRefs.current.subTitle = ref)}
                controlType={CONTROL_TYPE.DATEPICKER}
                label={intl.formatMessage({ id: 'Ecom.News.PublishDate' })}
                value={publishDate}
                isRequired={true}
                onChange={setPublishDate}
                tabIndex={tabIndex++}
              />
            </div>
          </div>

          <div className='col-md-12'>
            <FormControl
              ref={(ref) => (componentRefs.current.subTitle = ref)}
              controlType={CONTROL_TYPE.TEXTAREA}
              label={intl.formatMessage({ id: 'Ecom.News.SubTitle' })}
              value={subTitle}
              onChange={setSubTitle}
              tabIndex={tabIndex++}
            />
          </div>

          <div className='col-md-12'>
            <FormControl
              ref={(ref) => (componentRefs.current.fileBanner = ref)}
              controlType={CONTROL_TYPE.FILEUPLOAD}
              label={intl.formatMessage({ id: 'Ecom.News.Banner' })}
              value={fileBanner}
              onChange={setFileBanner}
              accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico'
              isRequired={true}
              isMultiple={false}
              tabIndex={tabIndex++}
            />
          </div>

          <div className='col-md-12'>
            <FormControl
              ref={(ref) => (componentRefs.current.content = ref)}
              controlType={CONTROL_TYPE.TEXTAREA}
              label={intl.formatMessage({ id: 'Ecom.News.Content' })}
              value={content}
              isRequired={true}
              onChange={setContent}
              tabIndex={tabIndex++}
              hasTime={true}
              rows={15}
            />
          </div>

          {newsId && (
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.isActive = ref)}
                controlType={CONTROL_TYPE.CHECKBOX}
                label={intl.formatMessage({ id: 'IsActive' })}
                value={isActive}
                onChange={setIsActive}
                tabIndex={tabIndex++}
              />
            </div>
          )}
        </form>
      </div>
      <div className='card-footer d-flex justify-content-end gap-4'>
        <Button
          title={intl.formatMessage({ id: 'Back' })}
          icon='ArrowLeftOutlined'
          onClick={() => navigate(`/${MODULE.ECOM}/news/list`)}
          tabIndex={tabIndex++}
          disabled={false}
        />
        <Button
          title={intl.formatMessage({ id: 'Save' })}
          icon='SaveOutlined'
          className='btn-success'
          onClick={handleSave}
          tabIndex={tabIndex++}
          disabled={false}
        />
      </div>
    </div>
  )
}

export default NewsEditor
