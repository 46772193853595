import { useState } from 'react'
import Icon from './Icon'

const Button = (props) => {
  const { title, icon, className, onClick, tabIndex, disabled, type } = props
  const [loading, setLoading] = useState(false)

  const handleClick = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      if (onClick) {
        await onClick()
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <button
      type={type}
      className={`${className ? `btn ${className} btn-sm` : 'btn btn-light btn-sm'} ${loading ? 'disabled' : ''}`}
      onClick={handleClick}
      tabIndex={tabIndex}
      disabled={disabled || loading}
    >
      <div className='d-flex align-items-center gap-2'>
        <Icon iconName={loading ? 'LoadingOutlined' : icon} />
        {title}
      </div>
    </button>
  )
}

export default Button
