import React, { useState, useEffect, useRef } from 'react'
import { Table as AntTable, Pagination, Select } from 'antd'
import { useIntl } from 'react-intl'
import { useSidebar } from '../layout/aside/SidebarContext'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import { formatString } from '../../utilities/sharedFunction'

const ChildTable = ({ columns, dataSource }) => {
  const tableColumns = columns.map((item) => ({
    ...item,
  }))

  const tableProps = {
    // bordered: false,
    // loading,
    size: 'small', //large, middle, small
    // expandable: colExpandable,
    //title: title ?? null,
    //showHeader: true,
    //footer: footer ?? null,
    //rowSelection: rowSelection ?? false,
    //scroll,
  }

  return (
    <AntTable
      bordered={false}
      size='small'
      pagination={false}
      columns={tableColumns}
      dataSource={
        dataSource
          ? dataSource.map((row, index) => ({
              ...row,
              key: row.id || index,
            }))
          : []
      }
    />
  )
}
export default ChildTable
