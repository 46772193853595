import { useState, useEffect } from 'react'
import _ from 'lodash'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'

const ProductInfo = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()
  let tabIndex = 1

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [userGuide, setUserGuide] = useState()
  const [fileLicense, setFileLicense] = useState()
  const [license, setLicense] = useState()

  useEffect(() => {
    setName(data?.name ?? null)
    setDescription(data?.description ?? null)
    setUserGuide(data?.userGuide ?? null)
    setFileLicense(data?.fileLicense ?? [])
    setLicense(data?.license ?? null)
  }, [data])

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      name,
      description,
      userGuide,
      fileLicense,
      license,
    }))
  }, [name, description, userGuide, fileLicense, license])

  return (
    <div className='card card-flush'>
      <div className='card-header'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Info' />
          </h4>
        </div>
      </div>
      <div className='card-body d-flex flex-column gap-4'>
        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.name = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.INPUT}
            label={intl.formatMessage({ id: 'Prodventory.Product.Name' })}
            value={name}
            onChange={setName}
            isRequired={true}
          />
        </div>

        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.description = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.EDITOR}
            label={intl.formatMessage({
              id: 'Prodventory.Product.Description',
            })}
            value={description}
            onChange={setDescription}
          />
        </div>

        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.userGuide = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.EDITOR}
            label={intl.formatMessage({
              id: 'Prodventory.Product.UserGuide',
            })}
            value={userGuide}
            onChange={setUserGuide}
          />
        </div>

        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.fileLicense = ref)}
            controlType={CONTROL_TYPE.FILEUPLOAD}
            label={intl.formatMessage({ id: 'Prodventory.Product.License' })}
            value={fileLicense}
            onChange={setFileLicense}
            accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico,.pdf'
            isMultiple={false}
            tabIndex={tabIndex++}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductInfo
