import { forwardRef } from 'react'
import { InputNumber, Space } from 'antd'

const NumberBox = forwardRef((props, ref) => {
  const { value, placeholder, onChange, className, tabIndex, prefix, suffix, min, max, onBlur, readOnly, isValid, errorMessage } = props
  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <InputNumber
          style={{
            width: '100%',
          }}
          ref={ref}
          defaultValue={value}
          value={value}
          placeholder={placeholder}
          className={`${className} ${readOnly ? 'border-dashed' : ''}`}
          tabIndex={tabIndex ?? 0}
          readOnly={readOnly}
          min={min}
          max={max ?? 10000000000000000}
          prefix={prefix}
          suffix={suffix}
          status={isValid === false ? 'error' : ''}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
          onChange={onChange}
          onBlur={onBlur}
          size='large'
        />
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default NumberBox
