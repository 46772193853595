import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSignalR } from '../../contexts/SignalRContext'
import Icon from '../../shared/Icon'

const Notification = () => {
  const intl = useIntl()
  const { notificationConnection } = useSignalR()
  const [notifications, setNotifications] = useState([])
  const [countUnread, setCountUnread] = useState(0)

  useEffect(() => {
    if (notificationConnection) {
      const handleReceiveNotification = (notification) => {
        setNotifications((notifications) => [...notifications, notification])
      }

      notificationConnection.on('ReceiveNotification', handleReceiveNotification)

      return () => {
        notificationConnection.off('ReceiveNotification', handleReceiveNotification)
      }
    }
  }, [notificationConnection])

  return (
    <div className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px' data-kt-menu="true">
      <div className='d-flex flex-column bgi-no-repeat rounded-top'>
        <h4 className='fw-bold px-4 my-4'>
          {intl.formatMessage({ id: 'Notifications' })}
          <span className='badge badge-danger ms-1 mb-1 fs-8 ps-3 float-end'>
            {countUnread} {intl.formatMessage({ id: 'News' })}
          </span>
        </h4>
      </div>

      <div className='scroll-y mh-325px my-5 px-8'>
        {notifications.map((item) => (
          <div key={item.id} className='d-flex flex-stack py-4'>
            <div className='d-flex align-items-center me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                {item.title}
              </a>

              <span className='badge badge-light fs-8'>{item.createdDateStr}</span>
            </div>
          </div>
        ))}
      </div>
      <div className='text-center border-top'>
        <Link to='/' className='btn btn-color-gray-600 btn-active-color-primary'>
          {intl.formatMessage({ id: 'ViewAll' })}
          <Icon iconName='ArrowRightOutlined' className='fs-5' />
        </Link>
      </div>
    </div>
  )
}

export default Notification
