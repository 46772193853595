import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import Table from '../../../../components/shared/Table'
import TableSearch from '../../../../components/shared/TableSearch'
import { useInventoryOutService } from '../../../../services/apiServices'
import ChildTable from '../../../../components/shared/ChildTable'
import NumberFormat from '../../../../components/shared/NumberFormat'

const InventoryOuts = ({ data }) => {
  const intl = useIntl()
  const { getListAsync } = useInventoryOutService()

  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState()

  useEffect(() => {
    setOnReload(true)
  }, [data])

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    if (!_.isEmpty(data.id)) {
      return await getListAsync(data?.id, { pageIndex, pageSize, ...filter })
    }
    return { result: [] }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Warehouse.Inventoy.Out' })}`,
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Warehouse.Inventoy.Out.DispatchDate' })}`,
      dataIndex: 'dispatchDateStr',
      sorter: (a, b) => a.dispatchDateStr.localeCompare(b.dispatchDateStr),
      width: 60,
      showSorterTooltip: false,
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column gap-2'>
      <div className='d-flex justify-content-end flex-column flex-md-row gap-4'>
        <div className='d-flex flex-column flex-sm-row gap-4'>
          <TableSearch onSearch={setFilter} />
        </div>
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table
        columns={columns}
        fetchData={fetchData}
        onReload={onReload}
        toolbar={toolbar}
        colExpandable={{
          expandedRowRender: (record) => {
            if (!record.details || record.details.length === 0) {
              return null
            }

            const childColumns = [
              {
                title: `${intl.formatMessage({ id: 'Prodventory.Product.Code' })}`,
                dataIndex: 'code',
                width: 100,
              },
              {
                title: `${intl.formatMessage({ id: 'Quantity' })}`,
                key: 'quantity',
                width: 80,
                render: (item) => <NumberFormat amount={item.quantity} suffix='đ' />,
              },
            ]

            return <ChildTable columns={childColumns} dataSource={record.details} />
          },
        }}
      />
    </div>
  )
}

export default InventoryOuts
