import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const ApproveConfirm = ({ onApprove, onReject, button, tooltip }) => {
  const intl = useIntl()

  return (
    <Popconfirm
      title={intl.formatMessage({ id: 'Notifications' })}
      description={intl.formatMessage({ id: 'Confirm.ApproveTilte' })}
      onConfirm={onApprove}
      onCancel={onReject}
      okText={intl.formatMessage({ id: 'Confirm.Approve' })}
      cancelText={intl.formatMessage({ id: 'Confirm.Reject' })}
      okButtonProps={{
        type: 'primary',
        icon: <CheckOutlined />,
      }}
      cancelButtonProps={{
        danger: true,
        type: 'primary',
        icon: <CloseOutlined />,
      }}
    >
      <Tooltip title={tooltip}>{button}</Tooltip>
    </Popconfirm>
  )
}
export default ApproveConfirm
