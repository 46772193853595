import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useFileService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.FILE)

  const uploadAsync = async (payload) => {
    return await api.postAsync('upload', { payload })
  }

  const uploadsAsync = async (payload) => {
    return await api.postAsync('uploads', { payload })
  }

  return { uploadAsync, uploadsAsync }
}

export default useFileService
