/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useLevelService } from '../../../services/apiServices'
import EditTable from '../../../components/shared/EditTable'

const LevelEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, getCurrentListAsync, insertAsync, updateAsync } = useLevelService()

  const [code, setCode] = useState()
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [levelNumber, setLevelNumber] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [maintainForMonths, setMaintainForMonths] = useState()
  const [monthInviteCount, setMonthInviteCount] = useState()
  const [levelSelected, setLevelSelected] = useState()
  const [isActive, setIsActive] = useState(true)
  const [details, setDetails] = useState([])

  const [levelOptions, setLevelOptions] = useState([])

  useEffect(() => {
    const fetchLevelOptions = async () => {
      const { result } = await getCurrentListAsync()
      if (result) {
        setLevelOptions(
          result
            .filter((x) => x.id !== modalState.id)
            .map((item) => {
              return { value: item.id, label: `${item.levelNumber} - ${item.name} (${item.code})` }
            })
        )
      }
    }

    if (modalState.show) {
      fetchLevelOptions()
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  useEffect(() => {
    const removedRows = details.filter((row) => !levelSelected?.includes(row.levelInvite))
    const addedRows = levelSelected
      ?.filter((item) => !details.find((row) => row.levelInvite === item))
      ?.map((item) => ({
        levelInvite: item,
        levelName: levelOptions.find((x) => x.value === item)?.label,
        inviteTotal: 0,
      }))

    if (addedRows) {
      setDetails((prevRows) => {
        let updatedRows = [...prevRows]
        removedRows?.forEach((row) => {
          updatedRows = updatedRows.filter((r) => r.levelInvite !== row.levelInvite)
        })
        updatedRows.push(...addedRows)
        return updatedRows
      })
    }
  }, [levelSelected])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setCode(item?.code)
    setName(item?.name)
    setDescription(item?.description)
    setLevelNumber(item?.levelNumber)
    setTotalAmount(item?.totalAmount)
    setMaintainForMonths(item?.maintainForMonths)
    setMonthInviteCount(item?.monthInviteCount)
    setIsActive(item?.isActive ?? true)
    setDetails(item?.details ?? [])
    setLevelSelected(item?.details?.map((x) => x.levelInvite) ?? [])
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id: modalState.id ?? null,
      levelNumber,
      code,
      name,
      description,
      totalAmount,
      maintainForMonths,
      monthInviteCount,
      isActive,
      details,
    }

    const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  const columms = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.LevelName' })}`,
      dataIndex: 'levelName',
      width: 150,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.TotalRank' })}`,
      dataIndex: 'inviteTotal',
      width: 80,
      editable: true,
      controlType: CONTROL_TYPE.NUMBERBOX,
      min: 0,
    },
  ]

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.levelNumber = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Level.LevelNumber' })}
                  value={levelNumber}
                  onChange={setLevelNumber}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>

              <div className='col-md-8'>
                <FormControl
                  ref={(ref) => (componentRefs.current.code = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Level.LevelCode' })}
                  value={code}
                  onChange={setCode}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Level.LevelName' })}
                value={name}
                onChange={setName}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Prodventory.Category.Sub.Description' })}
                value={description}
                rows={2}
                onChange={setDescription}
                tabIndex={tabIndex++}
              />
            </div>

            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.totalAmount = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Level.TotalAmount' })}
                  value={totalAmount}
                  onChange={setTotalAmount}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  min={0}
                  suffix={'đ'}
                />
              </div>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.maintainForMonths = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Level.MaintainForMonths' })}
                  value={maintainForMonths}
                  onChange={setMaintainForMonths}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  min={0}
                />
              </div>
              <div className='col-md-4'>
                <FormControl
                  ref={(ref) => (componentRefs.current.monthInviteCount = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Level.MonthInviteCount' })}
                  value={monthInviteCount}
                  onChange={setMonthInviteCount}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  min={0}
                />
              </div>
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelSelected = ref)}
                controlType={CONTROL_TYPE.MULTISELECT}
                label={intl.formatMessage({ id: 'Ecom.Level.BranchDetail' })}
                onChange={setLevelSelected}
                value={levelSelected}
                options={levelOptions}
                tabIndex={tabIndex++}
              />
            </div>

            <div className='col-md-12'>
              <EditTable columns={columms} dataSource={details} onUpdate={setDetails} />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default LevelEditor
