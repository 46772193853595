import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

const Confirmation = () => {
  const intl = useIntl()
  const location = useLocation()
  const { confirmMessage } = location.state || {}

  return (
    <div className='d-flex flex-column flex-center text-center'>
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>
        {confirmMessage?.title?.value ?? intl.formatMessage({ id: 'EmailConfirmation' })}
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Green24h.com</div>
      </div>
      
      <div className='fs-6 fw-semibold text-gray-500 mb-10'>{confirmMessage?.message}</div>
      <div className='mb-11'>
        <Link to='/login' className='link-success'>
          <FormattedMessage id='Login' />
        </Link>
      </div>
      <div className='mb-0'>
        <img src='/media/auth/ok.png' className='mw-100 mh-300px theme-light-show' alt='' />
        <img src='/media/auth/ok-dark.png' className='mw-100 mh-300px theme-dark-show' alt='' />
      </div>
    </div>
  )
}

export default Confirmation
