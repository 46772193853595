import React from 'react'
import { useLoading } from '../contexts/LoadingContext'
import { Spin } from 'antd'

const LoadingSpinner = () => {
  const { loading } = useLoading()

  return (
    <>
      {loading && (
        <div className='loading-overlay d-flex justify-content-center align-items-center'>
          <Spin size='large' />
        </div>
      )}
    </>
  )
}

export default LoadingSpinner
