/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../../components/shared/Toast'
import Confirm from '../../../../components/shared/Confirm'
import Icon from '../../../../components/shared/Icon'
import BankAccountEditor from './BankAccountEditor'
import { useBankAccountService } from '../../../../services/apiServices'
import { useAuth } from '../../../../components/auth/AuthContext'

const BankAccounts = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const { getListAsync, deleteAsync } = useBankAccountService()

  const [bankAccounts, setBankAccounts] = useState([])
  const [bankAccountModal, setBankAccountModal] = useState({ id: null, show: false })

  useEffect(() => {
    fetchBankAccount()
  }, [user])

  const fetchBankAccount = async () => {
    var { result } = await getListAsync()
    setBankAccounts(result ?? [])
  }

  const deleteBankAccount = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchBankAccount()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showBankAccountModal = (id) => {
    setBankAccountModal({ id, show: true })
  }

  const hideBankAccountModal = () => {
    setBankAccountModal({ id: null, show: false })
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 py-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>
            <FormattedMessage id='User.Billing.PaymentMethods' />
          </span>
        </h3>
      </div>
      <div className='card-body'>
        <div className='row gx-6 gy-6'>
          {bankAccounts?.map((x) => (
            <div className='col-xl-6' key={x.id}>
              <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>
                <div className='d-flex align-items-center fs-4 fw-bold'>{x.bankName}</div>

                <div className='d-flex justify-content-between flex-wrap py-2 w-100'>
                  <div className='d-flex align-items-center'>
                    <img src={`/media/bank-logos/${x.logo}`} alt='' className='me-4 w-80px' />
                    <div>
                      <div className='fs-4 fw-bold'>{x.accountNumber}</div>
                      <div className='fs-6 fw-semibold text-gray-500'>{x.holderName}</div>
                    </div>
                  </div>

                  <div className='d-flex align-items-center gap-2'>
                    <Confirm
                      title={intl.formatMessage({ id: 'Notifications' })}
                      description={intl.formatMessage({ id: 'Confirm.Remove' })}
                      onConfirm={() => deleteBankAccount(x.id)}
                      button={
                        <div className='d-flex gap-1 align-items-center btn btn-sm btn-light-danger'>
                          <Icon iconName='DeleteOutlined' />
                          <FormattedMessage id='Delete' />
                        </div>
                      }
                    />

                    <div onClick={() => showBankAccountModal(x.id)} className='d-flex gap-1 align-items-center btn btn-sm btn-light-success'>
                      <Icon iconName='EditOutlined' />
                      <FormattedMessage id='Edit' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className='col-xl-6'>
            <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
              <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                <div className='mb-3 mb-md-0 fw-semibold'>
                  <div className='fs-6 text-gray-700 pe-7'>
                    <FormattedMessage id='User.Billing.PaymentMethods.Guid' />
                  </div>
                </div>

                <div
                  onClick={() => showBankAccountModal()}
                  className='d-flex gap-1 align-items-center btn btn-sm btn-primary px-6 align-self-center text-nowrap'
                >
                  <Icon iconName='PlusOutlined' />
                  <FormattedMessage id='User.Billing.PaymentMethods.Add' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BankAccountEditor modalState={bankAccountModal} handleClose={() => hideBankAccountModal()} reloadList={() => fetchBankAccount()} />
    </div>
  )
}

export default BankAccounts
