import { useState, useEffect } from 'react'
import _ from 'lodash'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'
import { PRODUCT_STATUS_ENUM, getEnums } from '../../../../utilities/enum'

const Status = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()

  let tabIndex = 1
  const [status, setStatus] = useState()
  const statuses = getEnums(PRODUCT_STATUS_ENUM, intl)

  useEffect(() => {
    setStatus(data?.status ?? PRODUCT_STATUS_ENUM.PENDINGAPPROVAL)
  }, [data])

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      status,
    }))
  }, [status])

  return (
    <div className='card card-flush'>
      <div className='card-header'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Status' />
          </h4>
        </div>
      </div>
      <div className='card-body d-flex flex-column gap-4'>
        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.status = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.SELECT}
            label={intl.formatMessage({ id: 'Prodventory.Product.Status' })}
            value={status}
            onChange={setStatus}
            isRequired={true}
            options={statuses.filter((x) => x.value === status || (status === PRODUCT_STATUS_ENUM.APPROVED && x.value === PRODUCT_STATUS_ENUM.STOPSELLING))}
            readOnly={status === PRODUCT_STATUS_ENUM.PENDINGAPPROVAL ? true : false}
          />
        </div>
      </div>
    </div>
  )
}
export default Status
