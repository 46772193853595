import useApiConnection from '../apiConnection'
import { GLOBALADMIN_ENDPOINTS } from '../apiEndpoints'

const useLocationService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, GLOBALADMIN_ENDPOINTS.LOCATION)

  const getProvincesAsync = async () => {
    return await api.getAsync('get-provinces')
  }

  const getDistrictsAsync = async (provinceId) => {
    return await api.getAsync(`get-districts/${provinceId}`)
  }

  const getWardsAsync = async (districtId) => {
    return await api.getAsync(`get-wards/${districtId}`)
  }

  return { getProvincesAsync, getDistrictsAsync, getWardsAsync }
}

export default useLocationService
