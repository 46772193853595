import { forwardRef } from 'react'
import { InputNumber, Input, Space } from 'antd'

const PhoneNumber = forwardRef((props, ref) => {
  const { value, placeholder, onChange, className, tabIndex, onBlur, readOnly, isValid, errorMessage } = props
  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <Space.Compact>
          <Input readOnly={true} style={{ width: '20%' }} defaultValue='+84' />
          <InputNumber
            style={{
              width: '80%',
            }}
            ref={ref}
            value={value}
            placeholder={placeholder}
            className={`${className} ${readOnly ? 'border-dashed' : ''}`}
            tabIndex={tabIndex ?? 0}
            readOnly={readOnly}
            status={isValid === false ? 'error' : ''}
            onChange={onChange}
            onBlur={onBlur}
            size='large'
            min={0}
            maxLength={15}
          />
        </Space.Compact>
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default PhoneNumber
