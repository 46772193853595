import { useEffect } from 'react'
import { DrawerComponent, MenuComponent, ScrollComponent, ToggleComponent } from '../../../../assets/ts/components/index.ts'
import { MODULE } from '../../../../utilities/constant.js'
import Menu from './Menu'
import { settingMenuItems } from '../../../../routing/SettingRoutes.js'
import { getMenuItems } from '../../../../utilities/sharedFunction.js'
import { useAuth } from '../../../auth/AuthContext.js'

const SettingTab = () => {
  const { userPermissions } = useAuth()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
  }, [])

  return (
    <div
      className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary fw-semibold fs-6 px-2'
      id='setting_menu'
      data-kt-menu='true'
    >
      <div id='setting_menu_wrapper' className='menu-fit'>
        <Menu menuItems={getMenuItems(settingMenuItems, userPermissions)} prefix={MODULE.SETTINGS} />
      </div>
    </div>
  )
}

export default SettingTab
