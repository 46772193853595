import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { BANNER_POSITION_ENUM, ACTIVE_ENUM, getEnums } from '../../../utilities/enum'
import { usePromotionService } from '../../../services/apiServices'

const BannerFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getCurrentListAsync } = usePromotionService()

  const [title, setTitle] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [position, setPosition] = useState(null)
  const [promotionId, setPromotionId] = useState(null)
  const [isActive, setIsActive] = useState(true)

  const statuses = getEnums(ACTIVE_ENUM, intl)
  const positions = getEnums(BANNER_POSITION_ENUM, intl)
  const [promotions, setPromotions] = useState([])

  useEffect(() => {
    const fetchPromotions = async () => {
      const { result } = await getCurrentListAsync()
      if (result) {
        setPromotions(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchPromotions()
  }, [])

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      title,
      fromDate,
      toDate,
      position,
      promotionId,
    }),
    reset: () => {
      setTitle(null)
      setFromDate(null)
      setToDate(null)
      setPosition(null)
      setPromotionId(null)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.title = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Banner.Title' })}
          value={title}
          onChange={setTitle}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.fromDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Banner.FromDate' })}
          value={fromDate}
          onChange={setFromDate}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.toDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Banner.ToDate' })}
          value={toDate}
          onChange={(date) => setToDate(date)}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.positionId = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Banner.Position' })}
          value={position}
          onChange={setPosition}
          options={positions}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.promotionId = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Banner.Promotion' })}
          value={promotionId}
          onChange={setPromotionId}
          options={promotions}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.isActive = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Status' })}
          value={isActive}
          onChange={setIsActive}
          options={statuses}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default BannerFilter
