/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useCategoryService, useSubCategoryService } from '../../../services/apiServices'

const CategoryEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = useSubCategoryService()
  const { getCurrentListAsync } = useCategoryService()

  const [categories, setCategories] = useState([])

  const [categoryId, setCategoryId] = useState()
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [isActive, setIsActive] = useState()
  const [listName, setListName] = useState()

  useEffect(() => {
    if (modalState.show) {
      fetchCategories()
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  const fetchCategories = async () => {
    const { result } = await getCurrentListAsync()
    if (result) {
      setCategories(
        result.map((item) => {
          return { value: item.id, label: item.name }
        })
      )
    }
  }

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setCategoryId(item?.categoryId ?? modalState.categoryId)
    setName(item?.name)
    setDescription(item?.description)
    setIsActive(item?.isActive ?? true)
    setListName([])
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id: modalState.id ?? null,
      categoryId,
      name: modalState.id ? name: listName,
      description,
      isActive,
    }
    const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='col-md-12'>
              <FormControl
                controlType={CONTROL_TYPE.SELECT}
                ref={(ref) => (componentRefs.current.Select = ref)}
                label={intl.formatMessage({ id: 'Prodventory.Category.Sub.CategoryName' })}
                isRequired={true}
                value={categoryId}
                onChange={setCategoryId}
                readOnly={false}
                tabIndex={tabIndex++}
                options={categories}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.name = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Prodventory.Category.Sub.Name' })}
                  value={name}
                  onChange={setName}
                  isRequired={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}

            {!modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  controlType={CONTROL_TYPE.TAGS}
                  ref={(ref) => (componentRefs.current.listName = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Category.Sub.Name' })}
                  isRequired={true}
                  value={listName}
                  onChange={setListName}
                  readOnly={false}
                  tabIndex={tabIndex++}
                  options={[]}
                />
              </div>
            )}

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Prodventory.Category.Sub.Description' })}
                value={description}
                rows={2}
                onChange={setDescription}
                tabIndex={tabIndex++}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'Prodventory.Category.Sub.IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default CategoryEditor
