import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BarChart from '../../../../components/shared/BarChart'
import useDashboardService from '../../../../services/ecom/dashboardService'
import { DATETIME_FORMAT_DASH } from '../../../../utilities/constant'

const TopCategories = ({ dates, supplierId }) => {
  const { topCategoriesAsync } = useDashboardService()
  const [data, setData] = useState([])

  useEffect(() => {
    const fromDate = dates ? dates[0].format(DATETIME_FORMAT_DASH.yyyyMMdd) : null
    const toDate = dates ? dates[1].format(DATETIME_FORMAT_DASH.yyyyMMdd) : null

    fetchData(fromDate, toDate)
  }, [dates, supplierId])

  const fetchData = async (fromDate, toDate) => {
    const { result } = await topCategoriesAsync({ fromDate, toDate, supplierId })
    if (result) {
      setData(result)
    }
  }

  return (
    <div className='card card-flush max-h-xxl-550px h-100'>
      <div className='card-header border-0 py-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <FormattedMessage id='Ecom.Dashboard.Categories' />
          </span>
        </h3>
      </div>
      <div className='card-body p-0'>
        <BarChart data={data} />
      </div>
    </div>
  )
}

export default TopCategories
