import { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../shared/FormControl'
import { useAuthService } from '../../services/apiServices'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'
import Icon from '../shared/Icon'

const ForgotPassword = () => {
  const intl = useIntl()
  const { forgotPasswordAsync } = useAuthService()
  const navigate = useNavigate()
  const { returnUrl } = useAuth()

  const componentRefs = useRef({})
  let tabIndex = 1

  const [userName, setUserName] = useState('')

  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsSubmitting(true)
      const isValid = await validationAllComponents(componentRefs)
      if (isValid) {
        const { error, result } = await forgotPasswordAsync(userName, returnUrl())
        if (error) {
          setErrors([error.message])
        } else {
          navigate('/confirmation', {
            state: { confirmMessage: result },
          })
        }
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit}>
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>
          <FormattedMessage id='Login.ForgotPassword' />
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Green24h.com</div>
      </div>

      {Object.keys(errors).length > 0 && (
        <div className='text-danger'>
          {Object.values(errors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.userName = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Email' })}
          value={userName}
          onChange={setUserName}
          tabIndex={tabIndex++}
          isRequired={true}
          isEmail={true}
        />
      </div>

      <div className='d-flex flex-wrap justify-content-center d-grid mb-10'>
        <button type='submit' className='btn btn-success me-4' disabled={isSubmitting}>
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <FormattedMessage id={isSubmitting ? 'PleaseWait' : 'Submit'} />
            <Icon iconName={isSubmitting ? 'LoadingOutlined' : ''} />
          </div>
        </button>
        <Link to='/login' className='btn btn-light'>
          <FormattedMessage id='Cancel' />
        </Link>
      </div>

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        <FormattedMessage id='Login.NotAMemberYet' />{' '}
        <Link to='/registration' className='link-success'>
          <FormattedMessage id='Register' />
        </Link>
      </div> */}
    </form>
  )
}

export default ForgotPassword
