import { useEffect, useState } from 'react'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'
import SupplierProfile from './components/SupplierProfile'
import UserProfile from './components/UserProfile'

const Overview = () => {
  const { user } = useAuth()
  const [isAdmin, setIsAdmin] = useState(true)

  useEffect(() => {
    setIsAdmin(user.ROLE === ROLE.ADMIN)
  }, [user])

  return (
    <>
      {/* {!isAdmin && <SupplierProfile />}
      {isAdmin && <UserProfile />} */}
      <UserProfile />
    </>
  )
}
export default Overview
