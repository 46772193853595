import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { useBankAccountService } from '../../../services/apiServices'

const FinanceFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getBanksAsync } = useBankAccountService()

  const [name, setName] = useState(null)
  const [accountNumber, setAccountNumber] = useState(null)
  const [holderName, setHolderName] = useState(null)
  const [bankId, setBankId] = useState(null)

  const [banks, setBanks] = useState([])

  useEffect(() => {
    const fetchBanks = async () => {
      const { result } = await getBanksAsync()
      if (result) {
        setBanks(
          result.map((item) => {
            return { value: item.id, label: `${item.name} (${item.code})` }
          })
        )
      }
    }

    fetchBanks()
  }, [])

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      name,
      accountNumber,
      holderName,
      bankId,
    }),
    reset: () => {
      setName(null)
      setAccountNumber(null)
      setHolderName(null)
      setBankId(null)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'User.Billing.History.User.FullName' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.accountNumber = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'User.Billing.History.Account.AccountNumber' })}
          value={accountNumber}
          onChange={setAccountNumber}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.holderName = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'User.Billing.History.Account.HolderName' })}
          value={holderName}
          onChange={setHolderName}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.phoneNumber = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'User.Billing.History.Account.BankName' })}
          value={bankId}
          onChange={setBankId}
          tabIndex={tabIndex++}
          options={banks}
        />
      </div>
    </form>
  )
})

export default FinanceFilter
