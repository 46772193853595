import { lazy } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import App from '../App'
import MasterLayout from '../components/layout/MasterLayout'
import Login from '../components/auth/Login'
import Logout from '../components/auth/Logout'
import { MODULE } from '../utilities/constant'
import AuthLayout from '../components/auth/AuthLayout'
import ForgotPassword from '../components/auth/ForgotPassword'
import Confirmation from '../components/auth/Confirmation'
import NewPassword from '../components/auth/NewPassword'

const EcomRoutes = lazy(() => import('./EcomRoutes'))
const ProdventoryRoutes = lazy(() => import('./ProdventoryRoutes'))
const SettingRoutes = lazy(() => import('./SettingRoutes'))

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/confirmation' element={<Confirmation />} />
        </Route>
        <Route element={<App />}>
          <Route element={<MasterLayout />}>
            <Route path={`${MODULE.ECOM}/*`} element={<EcomRoutes />} />
            <Route path={`${MODULE.PRODVENTORY}/*`} element={<ProdventoryRoutes />} />
            <Route path={`${MODULE.SETTINGS}/*`} element={<SettingRoutes />} />
            <Route path='/' element={<Navigate to={`/${MODULE.ECOM}/dashboard`} />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
