import { useState, useEffect } from 'react'
import _ from 'lodash'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSupplierService } from '../../../../services/apiServices'
import { useAuth } from '../../../../components/auth/AuthContext'
import { ROLE } from '../../../../utilities/constant'

const Supplier = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()
  const { user } = useAuth()
  const { getComboboxAsync: getSupplierComboboxAsync } = useSupplierService()

  let tabIndex = 1
  const [isSupplier] = useState(user.profile.role === ROLE.SUPPLIER)
  const [supplierId, setSupplierId] = useState()
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      supplierId,
    }))
  }, [supplierId])

  useEffect(() => {
    setSupplierId(isSupplier ? user.profile.supplierId : data?.supplierId)
  }, [data])

  useEffect(() => {
    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync()
      if (result) {
        setSuppliers(result)
      }
    }

    fetchSuppliers()
  }, [])

  return (
    <div className='card card-flush'>
      <div className='card-header'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Supplier' />
          </h4>
        </div>
      </div>
      <div className='card-body d-flex flex-column gap-4'>
        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.supplierId = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.SELECT}
            label={intl.formatMessage({ id: 'Prodventory.Product.Supplier' })}
            value={supplierId}
            options={suppliers}
            isRequired={true}
            onChange={setSupplierId}
            readOnly={isSupplier}
          />
        </div>
      </div>
    </div>
  )
}
export default Supplier
