import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import useDashboardService from '../../../../services/ecom/dashboardService'
import { DATETIME_FORMAT_DASH, DEFAULT_VALUE } from '../../../../utilities/constant'
import NumberFormat from '../../../../components/shared/NumberFormat'

const TopAffiliates = ({ dates }) => {
  const { topAffiliatesAsync } = useDashboardService()
  const [data, setData] = useState([])

  useEffect(() => {
    const fromDate = dates ? dates[0].format(DATETIME_FORMAT_DASH.yyyyMMdd) : null
    const toDate = dates ? dates[1].format(DATETIME_FORMAT_DASH.yyyyMMdd) : null

    fetchData(fromDate, toDate)
  }, [dates])

  const fetchData = async (fromDate, toDate) => {
    const { result } = await topAffiliatesAsync({ fromDate, toDate })
    if (result) {
      setData(result)
    }
  }

  return (
    <div className='card card-flush h-xl-100'>
      <div className='card-header border-0 py-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <FormattedMessage id='Ecom.Dashboard.Affiliate' />
          </span>
        </h3>
      </div>
      <div className='card-body py-0  min-h-250px'>
        <div className='me-n7 pe-7'>
          {data?.map((item, index) => (
            <div
              key={item.Email}
              className={`d-flex flex-stack py-2 border-bottom border-gray-300 border-bottom-dashed ${index === data?.length - 1 ? 'border-bottom-0' : ''}`}
            >
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px symbol-circle'>
                  <span className='symbol-label bg-light-danger text-danger fw-semibold'>{++index}</span>
                </div>

                <div className='ms-6 fw-semibold'>
                  {item.affiliateName}
                  <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>{item.email}</span>
                </div>
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-semibold'>{item.countChild}</div>
                <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                  <FormattedMessage id='Ecom.Dashboard.Affiliates' />
                </span>
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-semibold'>
                  <NumberFormat amount={item.countOrder} />
                </div>
                <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                  <FormattedMessage id='Ecom.Dashboard.Orders' />
                </span>
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-semibold'>
                  <NumberFormat amount={item.totalAmount} suffix={DEFAULT_VALUE.VND} />
                </div>
                <span className='text-gray-500 fw-semibold fs-7 d-block text-start ps-0'>
                  <FormattedMessage id='Ecom.Dashboard.Revenue' />
                </span>
              </div>
            </div>
          ))}
          {_.isEmpty(data) && (
            <div className='d-flex justify-content-center py-2'>
              <div className='text-muted'>
                <FormattedMessage id='NoItems' />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopAffiliates
