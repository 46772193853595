import { forwardRef } from 'react'
import { Input as AntInput, Space } from 'antd'

const Input = forwardRef((props, ref) => {
  const { value, placeholder, onChange, className, tabIndex, onBlur, readOnly, isValid, errorMessage, size, hideText = false } = props

  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        {hideText && (
          <AntInput.Password
            ref={ref}
            value={value ?? ''}
            size={size ?? 'large'}
            placeholder={placeholder}
            className={`${className} ${readOnly ? 'border-dashed' : ''}`}
            tabIndex={tabIndex ?? 0}
            readOnly={readOnly}
            status={isValid === false ? 'error' : ''}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
          />
        )}
        {!hideText && (
          <AntInput
            ref={ref}
            value={value ?? ''}
            size={size ?? 'large'}
            placeholder={placeholder}
            className={`${className} ${readOnly ? 'border-dashed' : ''}`}
            tabIndex={tabIndex ?? 0}
            readOnly={readOnly}
            status={isValid === false ? 'error' : ''}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
          />
        )}
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default Input
