/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { addToFormData, getFileFromUrl } from '../../../utilities/sharedFunction'
import { useCategoryService } from '../../../services/apiServices'

const CategoryEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = useCategoryService()

  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [fileThumbnail, setFileThumbnail] = useState([])
  const [image, setImage] = useState(null)
  const [fileImage, setFileImage] = useState([])
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (modalState.show) {
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setName(item?.name)
    setDescription(item?.description)
    setThumbnail(item?.thumbnail)
    setFileThumbnail(item?.fileThumbnail ?? [])
    setImage(item?.image)
    setFileImage(item?.fileImage ?? [])
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'id', modalState.id)
    addToFormData(formData, 'name', name)
    addToFormData(formData, 'description', description)
    addToFormData(formData, 'isActive', isActive)
    addToFormData(formData, 'thumbnail', thumbnail)
    addToFormData(formData, 'fileThumbnail', fileThumbnail)
    addToFormData(formData, 'image', image)
    addToFormData(formData, 'fileImage', fileImage)

    if (fileThumbnail) {
      let file = Array.isArray(fileThumbnail) ? fileThumbnail[0] : fileThumbnail
      addToFormData(formData, 'fileThumbnail', file.url ? await getFileFromUrl(file.url, file.name, file.type) : file.originFileObj)
    }

    if (fileImage) {
      let file = Array.isArray(fileImage) ? fileImage[0] : fileImage
      addToFormData(formData, 'fileImage', file.url ? await getFileFromUrl(file.url, file.name, file.type) : file.originFileObj)
    }

    const { error } = modalState.id ? await updateAsync(formData) : await insertAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Prodventory.Category.Name' })}
                value={name}
                onChange={setName}
                isRequired={true}
                tabIndex={tabIndex++}
              />
            </div>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Prodventory.Category.Description' })}
                value={description}
                rows={2}
                onChange={setDescription}
                tabIndex={tabIndex++}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fileThumbnail = ref)}
                  controlType={CONTROL_TYPE.FILEUPLOAD}
                  label={intl.formatMessage({ id: 'Prodventory.Category.Thumbnail' })}
                  value={fileThumbnail}
                  onChange={setFileThumbnail}
                  accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico'
                  isRequired={true}
                  isMultiple={false}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fileImage = ref)}
                  controlType={CONTROL_TYPE.FILEUPLOAD}
                  label={intl.formatMessage({ id: 'Prodventory.Category.Image' })}
                  value={fileImage}
                  onChange={setFileImage}
                  accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico'
                  isRequired={true}
                  isMultiple={false}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default CategoryEditor
