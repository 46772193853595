/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import LevelEditor from './LevelEditor'
import { useLevelService } from '../../../services/apiServices'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import TableSearch from '../../../components/shared/TableSearch'
import Table from '../../../components/shared/Table'
import Button from '../../../components/shared/Button'
import { Tooltip } from 'antd'
import Icon from '../../../components/shared/Icon'
import Checkbox from '../../../components/shared/Checkbox'
import Confirm from '../../../components/shared/Confirm'
import NumberFormat from '../../../components/shared/NumberFormat'

const Levels = () => {
  const intl = useIntl()
  const { getListAsync, deleteAsync } = useLevelService()
  const [modalState, setModalState] = useState({ id: null, show: false })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.LevelNumber' })}`,
      dataIndex: 'levelNumber',
      sorter: (a, b) => a.levelNumber - b.levelNumber,
      width: 60,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.LevelName' })}`,
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.TotalAmount' })}`,
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      showSorterTooltip: false,
      width: 80,
      render: (item) => <NumberFormat amount={item.totalAmount} suffix='đ' />,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.MaintainForMonths' })}`,
      dataIndex: 'maintainForMonths',
      sorter: (a, b) => a.maintainForMonths - b.maintainForMonths,
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Level.MonthInviteCount' })}`,
      dataIndex: 'monthInviteCount',
      sorter: (a, b) => a.monthInviteCount - b.monthInviteCount,
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
            <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
              <Icon iconName='EditOutlined' />
            </div>
          </Tooltip>

          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            tooltip={intl.formatMessage({ id: 'Delete' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success me-5'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
      <LevelEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default Levels
