import useApiConnection from '../apiConnection'
import { GLOBALADMIN_ENDPOINTS } from '../apiEndpoints'

const useAuthService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const view = process.env.REACT_APP_AUTH_LOCAL_USER
  const api = useApiConnection(endpoint, GLOBALADMIN_ENDPOINTS.AUTH)

  const loginAsync = async (payload) => {
    payload = {...payload, view}
    return await api.postAsync('login', { payload })
  }

  const registerAsync = async (payload) => {
    return await api.postAsync('register', { payload })
  }

  const logoutAsync = async () => {
    return await api.postAsync('')
  }

  const resendConfirmationEmailAsync = async (email, returnUrl) => {
    return await api.postAsync('resend-confirmation-email', { payload: { email, returnUrl } })
  }

  const forgotPasswordAsync = async (email, returnUrl) => {
    return await api.postAsync('forgot-password', { payload: { email, returnUrl } })
  }

  const newPasswordAsync = async (payload) => {
    return await api.postAsync('new-password', { payload })
  }

  return { loginAsync, logoutAsync, registerAsync, resendConfirmationEmailAsync, forgotPasswordAsync, newPasswordAsync }
}

export default useAuthService
