import { Route, Routes } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../components/layout/header/breadcrumb/PageData'
import Categories from '../pages/prodventory/category/Categories'
import SubCategories from '../pages/prodventory/subCategory/SubCategories'
import CategoryTags from '../pages/prodventory/categoryTag/CategoryTags'
import Products from '../pages/prodventory/product/Products'
import ProductEditor from '../pages/prodventory/product/ProductEditor'
import Warehouses from '../pages/prodventory/warehouse/Warehouses'
import InventoryIns from '../pages/prodventory/warehouse/InventoryIns'
import InventoryOuts from '../pages/prodventory/warehouse/InventoryOuts'
import { getMenuItems } from '../utilities/sharedFunction'
import { useAuth } from '../components/auth/AuthContext'

export var prodventoryMenuItems = [
  {
    section: 'Prodventory.Category',
    items: [
      {
        to: `categories/list`,
        title: 'Prodventory.Category.List',
        hasBullet: true,
        id: 'prodventory-category-list',
        component: Categories,
      },
      {
        to: `categories/subs/list`,
        title: 'Prodventory.Category.Sub.List',
        hasBullet: true,
        id: 'prodventory-category-sub-list',
        component: SubCategories,
      },
      {
        to: `categories/tags/list`,
        title: 'Prodventory.Category.Tag.List',
        hasBullet: true,
        id: 'prodventory-category-tag-list',
        component: CategoryTags,
      },
    ],
  },
  {
    section: 'Prodventory.Product',
    items: [
      {
        to: `products/list`,
        title: 'Prodventory.Product.List',
        hasBullet: true,
        id: 'prodventory-product-list',
        component: Products,
      },
      {
        to: `products/add-new`,
        title: 'Prodventory.Product.Add',
        hasBullet: true,
        id: 'prodventory-product-add',
        component: ProductEditor,
      },
      {
        to: `products/:productId/*`,
        title: 'Prodventory.Product.Add',
        hasBullet: true,
        id: 'prodventory-product-update',
        component: ProductEditor,
        hidden: true,
      },
    ],
  },
  {
    section: 'Prodventory.Warehouse',
    items: [
      {
        to: `warehouses/list`,
        title: 'Prodventory.Warehouse.List',
        hasBullet: true,
        id: 'prodventory-warehouse-list',
        component: Warehouses,
      },
      // {
      //   to: `warehouses/inventoy-in`,
      //   title: 'Prodventory.Warehouse.Inventoy.In',
      //   hasBullet: true,
      //   id: 'prodventory-warehouse-inventoy-in',
      //   component: InventoryIns,
      // },
      // {
      //   to: `warehouses/inventoy-out`,
      //   title: 'Prodventory.Warehouse.Inventoy.Out',
      //   hasBullet: true,
      //   id: 'prodventory-warehouse-inventoy-out',
      //   component: InventoryOuts,
      // },
    ],
  },
]

const ProdventoryRoutes = () => {
  const intl = useIntl()
  const { userPermissions } = useAuth()
  
  const renderRoutes = (items) => {
    return items.flatMap((item) => {
      if (item.items) {
        return renderRoutes(item.items)
      }

      if (item.component) {
        const breadCrumbs = [{ title: intl.formatMessage({ id: 'Menu.Prodventory' }) }]
        return (
          <Route
            key={item.id}
            path={item.to}
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({ id: item.title })}</PageTitle>
                <item.component />
              </>
            }
          />
        )
      }

      return []
    })
  }

  return <Routes>{renderRoutes(getMenuItems(prodventoryMenuItems, userPermissions))}</Routes>
}
export default ProdventoryRoutes
