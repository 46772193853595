/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { addToFormData } from '../../../utilities/sharedFunction'
import { usePromotionService } from '../../../services/apiServices'
import { PROMOTION_DISCOUNT_TYPE_ENUM, PROMOTION_TYPE_ENUM } from '../../../utilities/enum'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const PromotionEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = usePromotionService()

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [discountType, setDiscountType] = useState()
  const [discountValue, setDiscountValue] = useState()
  const [type, setType] = useState()
  const [banner, setBanner] = useState()
  const [fileBanner, setFileBanner] = useState()
  const [isActive, setIsActive] = useState()

  const [discountTypes, setDiscountTypes] = useState([])

  useEffect(() => {
    const types = Object.keys(PROMOTION_DISCOUNT_TYPE_ENUM).map((key) => ({
      value: PROMOTION_DISCOUNT_TYPE_ENUM[key],
      label: intl.formatMessage({ id: `Enum.${key}` }),
    }))

    setDiscountTypes(types)
  }, [discountValue, intl])

  useEffect(() => {
    if (modalState.show) {
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setName(item?.name)
    setDescription(item?.description)
    setFromDate(item?.fromDate ? dayjs.utc(item?.fromDate).local() : dayjs(new Date()))
    setToDate(item?.toDate ? dayjs.utc(item?.toDate).local() : null)
    setDiscountType(item?.discountType)
    setDiscountValue(item?.discountValue)
    setType(item?.type ?? PROMOTION_TYPE_ENUM.PROMOTION_PRODUCT)
    setBanner(item?.banner)
    setFileBanner(item?.fileBanner ?? [])
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'id', modalState.id)
    addToFormData(formData, 'name', name)
    addToFormData(formData, 'description', description)
    addToFormData(formData, 'fromDate', fromDate.toISOString())
    addToFormData(formData, 'toDate', toDate ? toDate.toISOString() : null)
    addToFormData(formData, 'discountType', discountType)
    addToFormData(formData, 'discountValue', discountValue)
    addToFormData(formData, 'type', type)
    addToFormData(formData, 'banner', banner)
    addToFormData(formData, 'isActive', isActive)

    if (!_.isEmpty(fileBanner)) {
      let file = Array.isArray(fileBanner) ? fileBanner[0] : fileBanner
      if (file.originFileObj) {
        addToFormData(formData, 'fileBanner', file.originFileObj)
      }
    }

    const { error } = modalState.id ? await updateAsync(formData) : await insertAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Promotion.Name' })}
                value={name}
                onChange={setName}
                isRequired={true}
                tabIndex={tabIndex++}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fromDate = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.FromDate' })}
                  value={fromDate}
                  isRequired={true}
                  onChange={setFromDate}
                  tabIndex={tabIndex++}
                  hasTime={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.toDate = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.ToDate' })}
                  value={toDate}
                  onChange={(date) => setToDate(date)}
                  tabIndex={tabIndex++}
                  hasTime={true}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Ecom.Promotion.Description' })}
                value={description}
                rows={2}
                onChange={setDescription}
                tabIndex={tabIndex++}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountType = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.DiscountType' })}
                  value={discountType}
                  isRequired={true}
                  onChange={setDiscountType}
                  options={discountTypes}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountType = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.DiscountValue' })}
                  value={discountValue}
                  isRequired={true}
                  onChange={setDiscountValue}
                  tabIndex={tabIndex++}
                  min={0}
                  max={discountType === PROMOTION_DISCOUNT_TYPE_ENUM.PERCENTAGE ? 100 : null}
                  suffix={
                    discountType === PROMOTION_DISCOUNT_TYPE_ENUM.PERCENTAGE ? '%' : discountType === PROMOTION_DISCOUNT_TYPE_ENUM.FIXED_PRICE ? 'đ' : null
                  }
                />
              </div>
            </div>

            <div className='col-12 mb-3'>
              <label className='fs-6 fw-semibold'>
                <FormattedMessage id='Ecom.Promotion.Type' />
              </label>
              <div className='row row-cols-3'>
                {Object.keys(PROMOTION_TYPE_ENUM).map((key) => {
                  return (
                    <div key={key} className='pe-2 pt-2'>
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4 ${
                          type === PROMOTION_TYPE_ENUM[key] ? 'active' : ''
                        }`}
                        onChange={(e) => setType(parseInt(e.target.value))}
                      >
                        <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start me-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='discount_option'
                            value={PROMOTION_TYPE_ENUM[key]}
                            checked={type === PROMOTION_TYPE_ENUM[key]}
                          />
                        </span>

                        <span className='fw-bold d-block'>
                          <FormattedMessage id={`Enum.${key}`} />
                        </span>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.banner = ref)}
                controlType={CONTROL_TYPE.FILEUPLOAD}
                label={intl.formatMessage({ id: 'Ecom.Promotion.Banner' })}
                value={fileBanner}
                onChange={setFileBanner}
                accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico'
                isRequired={true}
                isMultiple={false}
                tabIndex={tabIndex++}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default PromotionEditor
