import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSupplierService, useProductService, useOrderService } from '../../../../services/apiServices'

const AccountHeaderContext = createContext()

export const useAccountHeaderContext = () => useContext(AccountHeaderContext)

export const AccountHeaderProvider = ({ children }) => {
  const { getAccountBalanceAsync } = useSupplierService()
  const { countProductAsync } = useProductService()
  const { countOrderAsync } = useOrderService()
  const [reloadWithdrawalHistories, setReloadWithdrawalHistories] = useState(false)
  const [accountBalance, setAccountBalance] = useState(0)
  const [products, setProducts] = useState(0)
  const [orders, setOrders ] = useState(0)

  useEffect(() => {
    const getAccountBalance = async () => {
      const { result } = await getAccountBalanceAsync()
      if (result) {
        setAccountBalance(result)
      }
    }

    const countProduct = async () => {
      const { result } = await countProductAsync()
      if (result) {
        setProducts(result)
      }
    }

    const countOrder = async () => {
      const { result } = await countOrderAsync()
      if (result) {
        setOrders(result)
      }
    }

    getAccountBalance()
    countProduct()
    countOrder()
  }, [])

  return (
    <AccountHeaderContext.Provider
      value={{
        reloadWithdrawalHistories,
        setReloadWithdrawalHistories,
        accountBalance,
        products,
        orders,
      }}
    >
      {children}
    </AccountHeaderContext.Provider>
  )
}
