import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import Icon from '../../shared/Icon'
import Tabs from './tabs/Tabs'
import TabsBase from './tabs/TabsBase'
import { MODULE } from '../../../utilities/constant'
import { useSidebar } from '../aside/SidebarContext.js'

const Aside = () => {
  const pathname = useLocation().pathname
  const [currentTab, setCurrentTab] = useState(MODULE.ECOM)
  const { toggleAside } = useSidebar()

  useEffect(() => {
    setCurrentTab((pathname && pathname.substring(1).split('/')[0]) || MODULE.ECOM)
  }, [pathname])

  return (
    <div
      id='kt_aside'
      className='aside aside-extended'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='false'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
        <div id='kt_aside_logo' className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10'>
          <Link to='/'>
            <img src='/media/logos/logo.png' alt='logo' className='h-35px' />
          </Link>
        </div>
        <div id='kt_aside_nav' className='aside-nav d-flex flex-column align-items-end flex-column-fluid w-100 pt-5 pt-lg-0'>
          <Tabs currentTab={currentTab} setTab={setCurrentTab} />
        </div>
      </div>

      <div className='aside-secondary d-flex flex-row-fluid'>
        <div className='aside-workspace my-5 px-2' id='kt_aside_wordspace'>
          <TabsBase currentTab={currentTab} />
        </div>
      </div>

      <button
        id='kt_aside_toggle'
        className='btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex '
        data-kt-toggle='true'
        data-kt-toggle-state='active'
        data-kt-toggle-target='body'
        data-kt-toggle-name='aside-minimize'
        style={{ marginBottom: '1.35rem' }}
        onClick={toggleAside}
      >
        <Icon iconName='ArrowLeftOutlined' className='fs-5 rotate-180' />
      </button>
    </div>
  )
}

export default Aside
