import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { DatePicker as AntDatePicker, Space } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DATETIME_FORMAT_SLASH } from '../../utilities/constant'

dayjs.extend(customParseFormat)

const DatePicker = forwardRef((props, ref) => {
  const intl = useIntl()
  const { value, onChange, placeholder, tabIndex, onBlur, isValid, errorMessage, readOnly, hasTime = false } = props
  const dateFormat = hasTime ? DATETIME_FORMAT_SLASH.ddMMyyyyHHmm : DATETIME_FORMAT_SLASH.ddMMyyyy
  return (
    <div className='d-flex flex-column gap-1'>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <AntDatePicker
          ref={ref}
          showTime={hasTime}
          value={value}
          format={dateFormat}
          placeholder={placeholder ?? intl.formatMessage({ id: 'SelectAnOption' })}
          className={readOnly ? 'border-dashed' : ''}
          tabIndex={tabIndex ?? 0}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          disabled={readOnly}
          getPopupContainer={(trigger) => trigger.parentNode}
          style={{
            width: '100%',
          }}
          size='large'
        />
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </div>
  )
})

export default DatePicker
