import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import LineChart from '../../../../components/shared/LineChart'
import useDashboardService from '../../../../services/ecom/dashboardService'

const SalesRevenue = ({ year, supplierId }) => {
  const { salesRevenueAsync } = useDashboardService()
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [year, supplierId])

  const fetchData = async () => {
    const { result } = await salesRevenueAsync({ year, supplierId })
    if (result) {
      setData(result)
    }
  }
  return (
    <div className='card card-flush h-xl-100'>
      <div className='card-header border-0 py-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            <FormattedMessage id='Ecom.Dashboard.Sales' values={{ year }} />
          </span>
        </h3>
      </div>
      <div className='card-body p-0'>
        <LineChart data={data} />
      </div>
    </div>
  )
}

export default SalesRevenue
