/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import {
  useFeaturedProductService,
  useCategoryService,
  useSubCategoryService,
  useCategoryTagService,
  useSupplierService,
  useProductService,
} from '../../../services/apiServices'
import Table from '../../../components/shared/Table'
import dayjs from 'dayjs'
import TableSearch from '../../../components/shared/TableSearch'

const FeaturedProductEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getListAsync } = useProductService()
  const { insertAsync } = useFeaturedProductService()
  const { getComboboxAsync: getSupplierComboboxAsync } = useSupplierService()
  const { getCurrentListAsync: getCategoriesAsync } = useCategoryService()
  const { getCurrentListAsync: getSubCategoriesAsync } = useSubCategoryService()
  const { getCurrentListAsync: getCategoryTagsAsync } = useCategoryTagService()

  const [code, setCode] = useState()
  const [name, setName] = useState()
  const [supplierId, setSupplierId] = useState()
  const [categoryId, setCategoryId] = useState()
  const [subCategoryId, setSubCategoryId] = useState()
  const [tags, setTags] = useState()

  const [filter, setFilter] = useState()
  const [onReload, setOnReload] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState()
  const [fromDate, setFromDate] = useState(dayjs(new Date()))
  const [toDate, setToDate] = useState()

  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [categoryTags, setCategoryTags] = useState([])

  useEffect(() => {
    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync()
      if (result) {
        setSuppliers(result)
      }
    }

    const fetchCategories = async () => {
      const { result } = await getCategoriesAsync()
      if (result) {
        setCategories(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    if (modalState.show) {
      fetchCategories()
      fetchSuppliers()
      handleSearch()
    }
  }, [modalState])

  useEffect(() => {
    setOnReload(true)
    setSelectedRowKeys([])
  }, [filter])

  useEffect(() => {
    const fetchSubCategories = async () => {
      const { result } = await getSubCategoriesAsync(categoryId)
      if (result) {
        const subCategoryList = result.map((item) => {
          return { value: item.id, label: item.name }
        })

        setSubCategories(subCategoryList)
        if (!subCategoryList?.some((item) => item.value === subCategoryId)) {
          setSubCategoryId(null)
        }
      }
    }

    const fetchCategoryTags = async () => {
      const { result } = await getCategoryTagsAsync(categoryId)
      if (result) {
        const categoryTagList = result.map((item) => {
          return { value: item.id, label: item.name }
        })
        setCategoryTags(categoryTagList)
        if (!categoryTagList?.some((item) => tags === item.value)) {
          setTags(null)
        }
      }
    }

    if (categoryId) {
      fetchSubCategories()
      fetchCategoryTags()
    } else {
      setSubCategoryId(null)
      setTags(null)
    }
  }, [categoryId])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const handleSearch = () => {
    setFilter({
      name,
      code,
      supplierId,
      categoryId,
      subCategoryId,
      tags: tags && [tags],
    })
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      productIds: selectedRowKeys,
      type: modalState.type,
      fromDate,
      toDate,
    }

    const { error } = await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Thumbnail' })}`,
      dataIndex: 'fileThumbnail',
      width: 60,
      render: (fileThumbnail) => (
        <div className='symbol d-flex justify-content-center'>
          <img className={`symbol-label`} src={fileThumbnail.url} alt={fileThumbnail.name} />
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Name' })}`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Category' })}`,
      dataIndex: 'categoryName',
      width: 80,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Supplier' })}`,
      dataIndex: 'supplierName',
      sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
      showSorterTooltip: false,
      width: 100,
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-xl-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <FormControl
          ref={(ref) => (componentRefs.current.fromDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.FeaturedProduct.FromDate' })}
          value={fromDate}
          onChange={setFromDate}
          tabIndex={tabIndex++}
          isHideLabel={true}
          isRequired={true}
        />
        <FormControl
          ref={(ref) => (componentRefs.current.toDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.FeaturedProduct.ToDate' })}
          value={toDate}
          onChange={setToDate}
          tabIndex={tabIndex++}
          isHideLabel={true}
        />
        <Button
          title={intl.formatMessage({ id: 'Save' })}
          icon='SaveOutlined'
          className='btn-success'
          onClick={handleSave}
          tabIndex={tabIndex++}
          disabled={false}
        />
        <Button
          title={intl.formatMessage({ id: 'Search' })}
          icon='SearchOutlined'
          className='btn-info'
          onClick={handleSearch}
          tabIndex={tabIndex++}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-xl'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.categoryId = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Product.Category' })}
                  value={categoryId}
                  onChange={setCategoryId}
                  tabIndex={tabIndex++}
                  options={categories}
                />
              </div>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.subCategoryId = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Product.SubCategory' })}
                  value={subCategoryId}
                  onChange={setSubCategoryId}
                  tabIndex={tabIndex++}
                  options={subCategories}
                />
              </div>
              <div className='col-md-4'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.categoryTagIds = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Product.CategoryTag' })}
                  value={tags}
                  onChange={setTags}
                  tabIndex={tabIndex++}
                  options={categoryTags}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.code = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Prodventory.Product.Code' })}
                  value={code}
                  onChange={setCode}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.name = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Prodventory.Product.Name' })}
                  value={name}
                  onChange={setName}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-4'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.supplierId = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
                  value={supplierId}
                  onChange={setSupplierId}
                  tabIndex={tabIndex++}
                  options={suppliers}
                />
              </div>
            </div>
            <Table
              columns={columns}
              fetchData={fetchData}
              onReload={onReload}
              toolbar={toolbar}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  setSelectedRowKeys(selectedRowKeys)
                },
              }}
            />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default FeaturedProductEditor
