import { Radio, Segmented } from 'antd'

const Tabs = ({ header, content, selectedTab, onChange, type = 1 }) => {
  const renderTabs = () => {
    switch (type) {
      case 1:
      default:
        return (
          <Radio.Group onChange={(e) => onChange(e.target.value)} size='large' value={selectedTab}>
            {header?.map((x) => (
              <Radio.Button key={x.value} value={x.value}>
                {x.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        )

      case 2:
        return (
          <>
            <Segmented value={selectedTab} size='large' onChange={(e) => onChange(e)} options={header} />
          </>
        )
    }
  }

  return (
    <>
      {renderTabs()}
      {content && <div className='mt-2'>{content}</div>}
    </>
  )
}

export default Tabs
