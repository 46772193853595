import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import { useSupplierService } from '../../../services/apiServices'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import TableFilter from '../../../components/shared/TableFilter'
import SupplierEditor from './SupplierEditor'
import Icon from '../../../components/shared/Icon'
import { Tag, Tooltip } from 'antd'
import { SUPPLIER_STATUS_ENUM, getEnums } from '../../../utilities/enum'
import Select from '../../../components/shared/Select'
import SupplierFilter from './SupplierFilter'
import ApproveConfirm from '../../../components/shared/ApproveConfirm'
import { BASE_URL_API_SLEEK_ECOM } from '../../../utilities/constant'
import { downloadFile } from '../../../utilities/sharedFunction'

const Suppliers = () => {
  const intl = useIntl()
  const { getListAsync, getListExportAsync, deleteAsync, approveAsync, rejectAsync } = useSupplierService()
  const [modalState, setModalState] = useState({ id: null, show: false })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)
  const [status, setStatus] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const statuses = getEnums(SUPPLIER_STATUS_ENUM, intl)

  useEffect(() => {
    setOnReload(true)
    setSelectedRowKeys([])
  }, [filter, status])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter, status })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const handleApprove = async (id) => {
    const { error } = await approveAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleReject = async (id) => {
    const { error } = await rejectAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleExportExcel = async () => {
    setOnReload(false)
    const { result } = await getListExportAsync({ ...filter, status })
    if (result && result !== '') {
      downloadFile(BASE_URL_API_SLEEK_ECOM + result)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.Logo' })}`,
      dataIndex: 'fileLogo',
      width: 60,
      render: (fileLogo) => (
        <div className='symbol d-flex justify-content-center'>
          <img className={`symbol-label`} src={fileLogo?.url} alt={fileLogo?.name} />
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.CompanyName' })}`,
      dataIndex: 'companyName',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      width: 100,
      showSorterTooltip: false,
      render: (companyName, data) => (
        <>
          {companyName}
          <br />
          {data.status === SUPPLIER_STATUS_ENUM.PENDINGAPPROVAL && (
            <ApproveConfirm
              onApprove={() => handleApprove([data.id])}
              onReject={() => handleReject([data.id])}
              button={
                <Tag
                  color={data.status === SUPPLIER_STATUS_ENUM.PENDINGAPPROVAL ? 'volcano' : data.status === SUPPLIER_STATUS_ENUM.APPROVED ? 'blue' : 'red'}
                  className='me-1 fs-7 cursor-pointer'
                >
                  {intl.formatMessage({ id: `Enum.${data.statusName.toUpperCase()}` })}
                </Tag>
              }
            />
          )}

          {data.status !== SUPPLIER_STATUS_ENUM.PENDINGAPPROVAL && (
            <Tag
              color={data.status === SUPPLIER_STATUS_ENUM.PENDINGAPPROVAL ? 'volcano' : data.status === SUPPLIER_STATUS_ENUM.APPROVED ? 'blue' : 'red'}
              className='me-1 fs-7'
            >
              {intl.formatMessage({ id: `Enum.${data.statusName.toUpperCase()}` })}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.ContactName' })}`,
      dataIndex: 'contactName',
      sorter: (a, b) => a.contactName.localeCompare(b.contactName),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.Email' })}`,
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.PhoneNumber' })}`,
      dataIndex: 'phoneNumber',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      width: 80,
      showSorterTooltip: false,
      render: (phoneNumber) => `+84 ${phoneNumber}`,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.Address' })}`,
      dataIndex: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Supplier.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
            <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
              <Icon iconName='EditOutlined' />
            </div>
          </Tooltip>

          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            tooltip={intl.formatMessage({ id: 'Delete' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />
        <Button
          title={intl.formatMessage({ id: 'ExportExcel' })}
          icon='ExportOutlined'
          className='btn-primary'
          onClick={() => handleExportExcel()}
          tabIndex={1}
          disabled={false}
        />
        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <ApproveConfirm
            onApprove={() => handleApprove(selectedRowKeys)}
            onReject={() => handleReject(selectedRowKeys)}
            button={
              <div className='btn btn-primary btn-sm'>
                <div className='d-flex align-items-center gap-2'>
                  <Icon iconName={'CheckOutlined'} />
                  {intl.formatMessage({ id: 'Confirm.Approve' })}
                </div>
              </div>
            }
          />
        )}
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<SupplierFilter />} />
        <div className='w-200px'>
          <Select value={status} onChange={setStatus} options={statuses} placeholder={intl.formatMessage({ id: 'Ecom.Supplier.Status' })} />
        </div>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table
        columns={columns}
        fetchData={fetchData}
        onReload={onReload}
        rowSelection={SUPPLIER_STATUS_ENUM.PENDINGAPPROVAL === status ? rowSelection : false}
        toolbar={toolbar}
      />
      <SupplierEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default Suppliers
