import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import Select from '../../../components/shared/Select'
import { useCategoryService, useCategoryTagService } from '../../../services/apiServices'
import CategoryTagEditor from './CategoryTagEditor'
import Icon from '../../../components/shared/Icon'
import CategoryTagFilter from './CategoryTagFilter'
import TableFilter from '../../../components/shared/TableFilter'
import { Tooltip } from 'antd'

const CategoryTags = () => {
  const intl = useIntl()
  const { getListAsync, deleteAsync, deleteRangeAsync } = useCategoryTagService()
  const { getCurrentListAsync } = useCategoryService()

  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState()

  const [modalState, setModalState] = useState({ id: null, show: false, categoryId: null })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    setOnReload(true)
  }, [filter, category])

  const fetchCategories = async () => {
    const { result } = await getCurrentListAsync()
    if (result) {
      setCategories(
        result.map((item) => {
          return { value: item.id, label: item.name }
        })
      )
    }
  }

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter, categoryId: category })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const deleteRange = async () => {
    const { error } = await deleteRangeAsync(selectedRowKeys)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true, categoryId: category })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false, categoryId: category })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Category.Sub.CategoryName' })}`,
      dataIndex: 'categoryName',
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Category.Tag.Name' })}`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Category.Description' })}`,
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
            <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
              <Icon iconName='EditOutlined' />
            </div>
          </Tooltip>

          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            tooltip={intl.formatMessage({ id: 'Delete' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const toolbar = (
    <div className='d-flex flex-column flex-md-row gap-4 justify-content-between'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />

        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteRange()}
            button={
              <div className='btn btn-danger btn-sm'>
                <div className='d-flex align-items-center gap-2'>
                  <Icon iconName={'DeleteOutlined'} />
                  {intl.formatMessage({ id: 'Delete' })}
                </div>
              </div>
            }
          />
        )}
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<CategoryTagFilter />} />
        <div className='w-250px'>
          <Select value={category} onChange={setCategory} options={categories} />
        </div>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} rowSelection={rowSelection} />
      <CategoryTagEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default CategoryTags
