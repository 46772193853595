import React from 'react'
import MenuItem from './MenuItem'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'

const Menu = ({ menuItems, prefix }) => {
  const intl = useIntl()

  return (
    <>
      {menuItems
        .filter((x) => !x.hidden)
        .map((item, index) => {
          if (item.section) {
            if (!_.isEmpty(item.items)) {
              return (
                <React.Fragment key={index}>
                  <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        <FormattedMessage id={item.section} />
                      </span>
                    </div>
                  </div>
                  {item.items
                    .filter((x) => !x.hidden)
                    .map((subItem, subIndex) => (
                      <MenuItem
                        key={`sub-${subIndex}`}
                        to={`${prefix}/${subItem.to}`}
                        title={intl.formatMessage({ id: subItem.title })}
                        hasBullet={subItem.hasBullet}
                        icon={subItem.icon}
                      />
                    ))}
                </React.Fragment>
              )
            }
          } else {
            return <MenuItem key={index} to={`${prefix}/${item.to}`} icon={item.icon} title={intl.formatMessage({ id: item.title })} />
          }
        })}
    </>
  )
}

export default Menu
