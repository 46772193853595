import React from 'react'
import { Line } from '@ant-design/charts'

const LineChart = ({ data }) => {
  const config = {
    data,
    xField: 'label',
    yField: 'value',
    smooth: true,
  }

  return <Line {...config} />
}

export default LineChart
