import { useState, useEffect } from 'react'
import _, { isEmpty } from 'lodash'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'
import { GUID_NEWID } from '../../../../utilities/constant'

const Attribute = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()
  let tabIndex = 1

  const [attributes, setAttributes] = useState()
  const [attribute1, setAttribute1] = useState('')
  const [attribute2, setAttribute2] = useState('')

  useEffect(() => {
    var items = data?.attributes ?? []

    if (items[0]) {
      items[0].name = _.isEmpty(items[0]?.name) ? '' : items[0].name
    }

    if (items[1]) {
      items[1].name = _.isEmpty(items[1]?.name) ? '' : items[1].name
    }

    setAttribute1(_.isEmpty(items[0]?.name) ? '' : items[0].name)
    setAttribute2(_.isEmpty(items[1]?.name) ? '' : items[1].name)

    setAttributes(items)
  }, [data])

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      attributes,
    }))
  }, [attributes])

  useEffect(() => {
    const id = attributes?.find((attr) => attr.id === attributes[0]?.id)?.id
    updateAttributeName(id ?? GUID_NEWID(), attribute1, id ? false : true)
  }, [attribute1])

  useEffect(() => {
    const id = attributes?.find((attr) => attr.id === attributes[1]?.id)?.id
    updateAttributeName(id ?? GUID_NEWID(), attribute2, id ? false : true)
  }, [attribute2])

  const updateAttributeName = (id, newName, isAddnew) => {
    setAttributes((prevAttributes) => {
      const existingAttribute = prevAttributes?.find((attr) => attr.id === id)
      if (existingAttribute) {
        return prevAttributes.map((attr) => (attr.id === id ? { ...attr, name: newName, isAddnew } : attr))
      } else {
        return [...prevAttributes, { id, name: newName, isAddnew }]
      }
    })
  }

  return (
    <div className='card card-flush'>
      <div className='card-header'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Attribute' />
          </h4>
        </div>
      </div>
      <div className='card-body d-flex flex-column gap-4'>
        <div className='row'>
          <div className='col-md-6 mb-4 mb-md-0'>
            <FormControl
              ref={(ref) => (componentRefs.current.attribute1 = ref)}
              tabIndex={tabIndex++}
              controlType={CONTROL_TYPE.INPUT}
              label={intl.formatMessage({ id: 'Prodventory.Product.Attribute1' })}
              value={attribute1}
              onChange={setAttribute1}
            />
          </div>

          <div className='col-md-6 mb-4 mb-md-0'>
            <FormControl
              ref={(ref) => (componentRefs.current.attribute2 = ref)}
              tabIndex={tabIndex++}
              controlType={CONTROL_TYPE.INPUT}
              label={intl.formatMessage({ id: 'Prodventory.Product.Attribute2' })}
              value={attribute2}
              onChange={setAttribute2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Attribute
