import { forwardRef, useRef, useState } from 'react'
import { Button, Divider, Input, Select, Space } from 'antd'
import { useIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons'
import { GUID_NEWID } from '../../utilities/constant'

const Dropdown = forwardRef((props, ref) => {
  let { options, value, onChange, onBlur, className, tabIndex, isValid, errorMessage, readOnly } = props
  const intl = useIntl()
  const [name, setName] = useState('')
  const [items, setItems] = useState(options)
  const inputRef = useRef(null)

  const addItem = (e) => {
    e.preventDefault()

    if (name) {
      setItems([
        ...items,
        {
          value: `AddNew_${GUID_NEWID()}`,
          label: name,
        },
      ])
      setName('')
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }
  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <Select
          style={{
            width: '100%',
          }}
          ref={ref}
          showSearch
          allowClear
          value={options?.find((x) => x.value === value)}
          placeholder={intl.formatMessage({ id: 'SelectAnOption' })}
          optionFilterProp='label'
          className={className}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          options={items}
          disabled={readOnly}
          tabIndex={tabIndex}
          status={isValid === false ? 'error' : ''}
          size='large'
          getPopupContainer={(trigger) => trigger.parentNode}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
              <Space
                style={{
                  padding: '0 8px 4px',
                }}
              >
                <Input
                  placeholder={intl.formatMessage({ id: 'EnterItem' })}
                  ref={inputRef}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
                  {intl.formatMessage({ id: 'AddItem' })}
                </Button>
              </Space>
            </>
          )}
        />
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default Dropdown
