import _ from 'lodash'
import { useAuth } from '../components/auth/AuthContext'

const useApiConnection = (endpoint, prefix) => {
  const { user, login } = useAuth()

  const defaultHeaders = {
    pragma: 'no-cache',
    Accept: 'application/json',
    Authorization: `Bearer ${user?.access_token}`,
    'cache-control': 'no-cache',
    'is-api': true,
    'Access-Control-Allow-Origin': '*',
  }

  const buildUrl = (event, payload) => {
    const baseUrl = `${endpoint}/${prefix}`

    if (String(event)?.includes('?')) {
      return `${baseUrl}/${event}`
    }

    const queryString = Object.keys(payload).length > 0 ? buildQuery(payload) : null
    const url = event ? `${baseUrl}/${event}` : baseUrl

    return queryString ? `${url}?${queryString}` : url
  }

  const buildQuery = (queryObject) => {
    const cleanedParams = _.pickBy(queryObject, (value) => value != null)
    return new URLSearchParams(cleanedParams).toString()
  }

  const fetchApi = async (method, event, data = {}) => {
    const { payload = {}, headers: customHeaders = {} } = data
    const url = buildUrl(event, method === 'GET' ? payload : {})

    const config = {
      method,
      headers: {
        ...defaultHeaders,
        ...customHeaders,
        ...(payload instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      },
      credentials: 'include',
      body: method !== 'GET' ? (payload instanceof FormData ? payload : JSON.stringify(payload)) : null,
    }

    try {
      const response = await fetch(url, config)
      if (!response.ok) {
        if (response.status === 401) {
           login()
        }

        const errorData = await response.json().catch(() => ({}))
        console.error(errorData.error || errorData.errors || response)
        return { error: errorData.error || errorData.errors || 'Something went wrong' }
      }

      const binaryContentTypes = [
        'image/png',
        'image/jpeg',
        'application/pdf',
        'application/octet-stream',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ]

      const contentType = response.headers.get('content-type')
      const isBinary = contentType && binaryContentTypes.includes(contentType)
      const result = isBinary ? await response.arrayBuffer() : await response.json()

      return { result }
    } catch (error) {
      return { error: { message: error.message } }
    }
  }

  const apiMethods = {
    getAsync: (event, data) => fetchApi('GET', event, data),
    postAsync: (event, data) => fetchApi('POST', event, data),
    putAsync: (event, data) => fetchApi('PUT', event, data),
    deleteAsync: (event, data) => fetchApi('DELETE', event, data),
  }

  return { ...apiMethods }
}

export default useApiConnection
