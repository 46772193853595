import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [asideMinimized, setAsideMinimized] = useState(false);

  const toggleAside = () => {
    setAsideMinimized(prev => !prev);
  };

  return (
    <SidebarContext.Provider value={{ asideMinimized, toggleAside }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
