import viMessage from './resource/vi-VN.json'
import enMessage from './resource/en-US.json'

const cultures = [
  {
    culture: 'vi-VN',
    name: 'Việt Nam',
    flag: '/media/flags/vietnam.svg',
    messages: viMessage,
  },
  {
    culture: 'en-US',
    name: 'English',
    flag: '/media/flags/united-states.svg',
    messages: enMessage,
  },
]

export { cultures }
