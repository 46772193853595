import { forwardRef } from 'react'
import { Input, Space } from 'antd'

const { TextArea: AntTextArea } = Input

const TextArea = forwardRef((props, ref) => {
  const { value, placeholder, onChange, className, tabIndex, onBlur, readOnly, rows, isValid, errorMessage } = props

  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <AntTextArea
          ref={ref}
          value={value ?? ''}
          placeholder={placeholder}
          className={`${className} ${readOnly ? 'border-dashed' : ''}`}
          tabIndex={tabIndex ?? 0}
          disabled={readOnly}
          status={isValid === false ? 'error' : ''}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          rows={rows}
          size='large'
        />
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default TextArea
