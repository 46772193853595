import useApiConnection from '../apiConnection'
import { GLOBALADMIN_ENDPOINTS } from '../apiEndpoints'

const useLocalizationService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, GLOBALADMIN_ENDPOINTS.LOCALIZATION)

  const getCurrentCultureAsync = async () => {
    return await api.getAsync('current-culture')
  }

  const changeCultureAsync = async (culture) => {
    return await api.postAsync(`change-culture/${culture}`)
  }

  return { getCurrentCultureAsync, changeCultureAsync }
}

export default useLocalizationService
