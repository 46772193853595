import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'

const OrderFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()

  let tabIndex = 1

  const [code, setCode] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      code,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
    }),
    reset: () => {
      setCode(null)
      setFromDate(null)
      setToDate(null)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.code = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Order.Code' })}
          value={code}
          onChange={setCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.fromDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Order.FromDate' })}
          value={fromDate}
          onChange={setFromDate}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.toDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Order.ToDate' })}
          value={toDate}
          onChange={(date) => setToDate(date)}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default OrderFilter
