import { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { ACTIVE_ENUM, getEnums, PROMOTION_DISCOUNT_TYPE_ENUM, PROMOTION_TYPE_ENUM } from '../../../utilities/enum'

const PromotionFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1

  const [name, setName] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [discountType, setDiscountType] = useState(null)
  const [type, setType] = useState(null)
  const [isActive, setIsActive] = useState(true)

  const statuses = getEnums(ACTIVE_ENUM, intl)
  const types = getEnums(PROMOTION_TYPE_ENUM, intl)
  const discountTypes = Object.keys(PROMOTION_DISCOUNT_TYPE_ENUM).map((key) => ({
    value: PROMOTION_DISCOUNT_TYPE_ENUM[key],
    label: intl.formatMessage({ id: `Enum.${key}` }, { 0: intl.formatMessage({ id: 'Multiple' }) }),
  }))

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      name,
      fromDate,
      toDate,
      discountType,
      type,
      isActive,
    }),
    reset: () => {
      setName(null)
      setFromDate(null)
      setToDate(null)
      setDiscountType(null)
      setType(null)
      setIsActive(true)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Promotion' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.fromDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Promotion.FromDate' })}
          value={fromDate}
          onChange={setFromDate}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.toDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Promotion.ToDate' })}
          value={toDate}
          onChange={(date) => setToDate(date)}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.discountType = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Promotion.DiscountType' })}
          value={discountType}
          onChange={setDiscountType}
          options={discountTypes}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.type = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Promotion.Type' })}
          value={type}
          onChange={setType}
          options={types}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.positionId = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'IsActive' })}
          value={isActive}
          onChange={setIsActive}
          options={statuses}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default PromotionFilter
