import ProdventoryTab from './ProdventoryTab'
import EcomTab from './EcomTab'
import SettingTab from './SettingTab'
import { MODULE } from '../../../../utilities/constant'

const SelectedTab = ({ currentTab }) => {
  switch (currentTab) {
    case MODULE.ECOM:
      return <EcomTab />
    case MODULE.PRODVENTORY:
      return <ProdventoryTab />
    case MODULE.SETTINGS:
      return <SettingTab />
    default:
      return <EcomTab />
  }
}

const TabsBase = ({ currentTab, modules }) => {
  return (
    <div className='d-flex h-100 flex-column'>
      <div
        className='flex-column-fluid hover-scroll-y'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_aside_wordspace'
        data-kt-scroll-dependencies='#kt_aside_secondary_footer'
        data-kt-scroll-offset='0px'
      >
        <div className='tab-content'>
          <div className='tab-pane fade active show' id={`kt_aside_nav_tab_${currentTab}`} role='tabpanel'>
            <SelectedTab currentTab={currentTab} modules={modules} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabsBase
