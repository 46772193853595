import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive } from '../../../../utilities/sharedFunction'
import Icon from '../../../shared/Icon'

const MenuItem = ({ children, ...props }) => {
  const location = useLocation()
  const { to, title, icon, hasBullet = false } = props
  const isActive = checkIsActive(location.pathname, to)

  return (
    <div className='menu-item'>
      <Link className={`menu-link without-sub${isActive ? ' active' : ''}`} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon &&
          (icon.includes('.svg') ? (
            <div className='symbol symbol-40px me-5'>
              <span className='symbol-label bg-light-success'>
                <img src={`/media/svg/icons/${icon}`} alt='' className='h-50 align-self-center' />
              </span>
            </div>
          ) : (
            <span className='menu-icon'>
              <Icon iconName={icon} className='fs-3' />
            </span>
          ))}

        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export default MenuItem
