/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Icon from '../../shared/Icon'
import { FormattedMessage } from 'react-intl'

const ThemeMode = () => {
  return (
    <div>
      <div
        className='btn btn-icon btn-active-light-primary btn-custom w-40px h-40px menu-dropdown'
        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
      >
        <Icon iconName='SunOutlined' className='theme-light-show' />
        <Icon iconName='MoonOutlined' className='theme-dark-show' />
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px'
        data-kt-menu='true'
        data-kt-element='theme-mode-menu'
      >
        <div className='menu-item px-3 my-0'>
          <a className='menu-link px-3 py-2' data-kt-element='mode' data-kt-value='light'>
            <span className='menu-icon' data-kt-element='icon'>
              <Icon iconName='SunOutlined' />
            </span>
            <span className='menu-title'>
              <FormattedMessage id='Light' />
            </span>
          </a>
        </div>
        <div className='menu-item px-3 my-0'>
          <a className='menu-link px-3 py-2' data-kt-element='mode' data-kt-value='dark'>
            <span className='menu-icon' data-kt-element='icon'>
              <Icon iconName='MoonOutlined' />
            </span>
            <span className='menu-title'>
              <FormattedMessage id='Dark' />
            </span>
          </a>
        </div>
        <div className='menu-item px-3 my-0'>
          <a className='menu-link px-3 py-2' data-kt-element='mode' data-kt-value='system'>
            <span className='menu-icon' data-kt-element='icon'>
              <Icon iconName='SettingOutlined' />
            </span>
            <span className='menu-title'>
              <FormattedMessage id='System' />
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ThemeMode
