import { createContext, useState, useEffect, useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { cultures } from '../localization/cultures'
import { useLocalizationService } from '../../services/apiServices'

const I18nContext = createContext()

export const useI18n = () => useContext(I18nContext)

export const I18nProvider = ({ children }) => {
  const { changeCultureAsync, getCurrentCultureAsync } = useLocalizationService()

  const [locale, setLocale] = useState('vi-VN')
  const [messages, setMessages] = useState(cultures.find((x) => x.culture === locale)?.messages)

  useEffect(() => {
    fetchCulture()
  }, [])

  const fetchCulture = async () => {
    const { result } = await getCurrentCultureAsync()
    if (result) {
      setLocale(result.culture)
      setMessages(cultures.find((x) => x.culture === result.culture)?.messages)
    }
  }

  const changeCulture = async (newCulture) => {
    await changeCultureAsync(newCulture)
    setLocale(newCulture)
    setMessages(cultures.find((x) => x.culture === newCulture)?.messages)
  }

  return (
    <I18nContext.Provider value={{ locale, changeCulture }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  )
}