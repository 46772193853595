import { Tooltip } from 'antd'
import React from 'react'

const Label = ({ title, isRequired, className, contentTooltip, ...restProps }) => {
  return (
    <div className='label-container'>
      <label className={`${isRequired ? 'required' : ''} ${className ?? 'form-label'}`} {...restProps}>
        {title}
        {contentTooltip && <Tooltip content={contentTooltip} />}
      </label>
    </div>
  )
}

export default Label
