import useApiConnection from '../apiConnection'
import { GLOBALADMIN_ENDPOINTS } from '../apiEndpoints'

const useUserService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, GLOBALADMIN_ENDPOINTS.USER)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const changePasswordAsync = async (payload) => {
    return await api.postAsync('change-password', { payload })
  }

  const resetPasswordAsync = async (payload) => {
    return await api.postAsync('reset-password', { payload })
  }

  const changeStatusAsync = async (id, payload) => {
    return await api.postAsync(`change-status/${id}`, { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  return { getByIdAsync, getListAsync, changePasswordAsync, resetPasswordAsync, changeStatusAsync, updateAsync }
}

export default useUserService
