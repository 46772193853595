import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const NumberFormat = ({ amount, type, suffix, className }) => {
  const formatter = (value) => {
    return `${value ?? ''} ${suffix ?? ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <Text type={type} className={className}>
      {formatter(amount)}
    </Text>
  )
}

export default NumberFormat
