import { useState, useEffect } from 'react'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'

const Images = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()
  let tabIndex = 1

  const [images, setImages] = useState(data?.images)
  const [fileImages, setFileImages] = useState(data?.fileImages)

  useEffect(() => {
    setImages(data?.images ?? null)
    setFileImages(data?.fileImages ?? [])
  }, [data])

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      images,
      fileImages,
    }))
  }, [fileImages, images])

  return (
    <div className='card card-flush'>
      <div className='card-header min-h-10px'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Image' />
          </h4>
        </div>
      </div>
      <div className='card-body text-center'>
        <FormControl
          ref={(ref) => (componentRefs.current.fileImages = ref)}
          controlType={CONTROL_TYPE.FILEUPLOAD}
          label={intl.formatMessage({ id: 'Prodventory.Product.Image' })}
          value={fileImages}
          onChange={setFileImages}
          accept='.png,.jpg,.jpeg'
          isMultiple={true}
          tabIndex={tabIndex++}
          isHideLabel={true}
        />

        <div className='text-muted fs-7 mt-4'>
          <FormattedMessage id='Prodventory.Product.ThumbnailGuidance' />
        </div>
      </div>
    </div>
  )
}

export default Images
