import { useState, useEffect } from 'react'
import _ from 'lodash'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCategoryService, useSubCategoryService, useCategoryTagService } from '../../../../services/apiServices'

const Category = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()
  const { getCurrentListAsync: getCategoriesAsync } = useCategoryService()
  const { getCurrentListAsync: getSubCategoriesAsync } = useSubCategoryService()
  const { getCurrentListAsync: getCategoryTagAsync } = useCategoryTagService()

  let tabIndex = 1

  const [categoryId, setCategoryId] = useState()
  const [subCategoryId, setSubCategoryId] = useState()
  const [tags, setTags] = useState()

  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [categoryTags, setCategoryTags] = useState([])

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      categoryId,
      subCategoryId,
      tags,
    }))
  }, [categoryId, subCategoryId, tags])

  useEffect(() => {
    setSubCategoryId(data?.subCategoryId ?? null)
    setTags(data?.tags?.map((x) => x.categoryTagId) ?? [])
    setCategoryId(data?.categoryId ?? null)
  }, [data])

  useEffect(() => {
    const fetchCategories = async () => {
      const { result } = await getCategoriesAsync()
      if (result) {
        setCategories(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchCategories()
  }, [])

  useEffect(() => {
    const fetchSubCategories = async () => {
      const { result } = await getSubCategoriesAsync(categoryId)
      if (result) {
        const subCategoryList = result.map((item) => {
          return { value: item.id, label: item.name }
        })

        setSubCategories(subCategoryList)
        if (!subCategoryList.some((item) => item.value === subCategoryId)) {
          setSubCategoryId(null)
        }
      }
    }

    const fetchCategoryTag = async () => {
      const { result } = await getCategoryTagAsync(categoryId)
      if (result) {
        const categoryTagList = result.map((item) => {
          return { value: item.id, label: item.name }
        })

        setCategoryTags(categoryTagList)

        if (!categoryTagList.some((item) => tags.includes(item.value))) {
          setTags([])
        }
      }
    }

    if (!_.isEmpty(categoryId)) {
      fetchSubCategories()
      fetchCategoryTag()
    } else {
      setSubCategoryId(null)
      setTags([])
    }
  }, [categoryId])

  return (
    <div className='card card-flush'>
      <div className='card-header'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Category' />
          </h4>
        </div>
      </div>
      <div className='card-body d-flex flex-column gap-4'>
        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.categoryId = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.SELECT}
            label={intl.formatMessage({ id: 'Prodventory.Product.Category' })}
            value={categoryId}
            options={categories}
            isRequired={true}
            onChange={setCategoryId}
          />
        </div>
        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.subCategoryId = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.SELECT}
            label={intl.formatMessage({ id: 'Prodventory.Product.SubCategory' })}
            value={subCategoryId}
            options={subCategories}
            isRequired={true}
            onChange={setSubCategoryId}
          />
        </div>
        <div className='col-md-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.groupSelected = ref)}
            tabIndex={11}
            controlType={CONTROL_TYPE.MULTISELECT}
            onChange={setTags}
            value={tags}
            label={intl.formatMessage({ id: 'Prodventory.Product.CategoryTag' })}
            options={categoryTags}
            mu
          />
        </div>
      </div>
    </div>
  )
}
export default Category
