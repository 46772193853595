/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../../components/shared/Toast'
import { useBankAccountService, useWithdrawalHistoryService } from '../../../../services/apiServices'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { useAccountHeaderContext } from './AccountHeaderContext'
import bindValidationMessages from '../../../../utilities/validationMessage'
import { useAuth } from '../../../../components/auth/AuthContext'
import { WITHDRAWAL_STATUS_ENUM } from '../../../../utilities/enum'

const WithdrawalEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  let tabIndex = 1
  const validationMessage = bindValidationMessages()
  const { accountBalance } = useAccountHeaderContext()
  const { getListAsync, getBanksAsync } = useBankAccountService()
  const { getByIdAsync, insertAsync, updateAsync } = useWithdrawalHistoryService()

  const [accountNumber, setAccountNumber] = useState()
  const [holderName, setHolderName] = useState()
  const [bankId, setBankId] = useState()
  const [branchName, setBranchName] = useState()
  const [withdrawalAmount, setWithdrawalAmount] = useState()
  const [description, setDescription] = useState()
  const [selectedAccountId, setSelectedAccountId] = useState()
  const [userId, setUserId] = useState()
  const [status, setStatus] = useState()

  const [bankAccounts, setBankAccounts] = useState([])
  const [banks, setBanks] = useState([])

  useEffect(() => {
    const fetchBankAccounts = async () => {
      const { result } = await getListAsync()
      if (result) {
        setBankAccounts(result)
        modalState.id ? fetchData() : setData()
      } else {
        setData()
      }
    }

    const fetchBanks = async () => {
      const { result } = await getBanksAsync()
      if (result) {
        setBanks(
          result.map((item) => {
            return { value: item.id, label: `${item.name} (${item.code})` }
          })
        )
      }
    }

    if (modalState.show) {
      fetchBanks()
      fetchBankAccounts()
    } else {
      setData()
    }
  }, [modalState])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setAccountNumber(item?.accountNumber)
    setHolderName(item?.holderName)
    setBankId(item?.bankId)
    setBranchName(item?.branchName)
    setWithdrawalAmount(item?.withdrawalAmount)
    setDescription(item?.description)
    setUserId(item?.userId)
    setStatus(item?.status)

    var bankAccount = bankAccounts.find((x) => x.accountNumber === item?.accountNumber)
    setSelectedAccountId(bankAccount?.id)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id: modalState.id,
      accountNumber,
      holderName,
      bankId,
      branchName,
      withdrawalAmount,
      description,
    }

    if (_.isEmpty(modalState.id) || (user?.profile?.sub === userId && status === WITHDRAWAL_STATUS_ENUM.NEW)) {
      const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        reloadList()
        handleClose()
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      }
    }
  }

  const handleChangeBankAccount = (e) => {
    setSelectedAccountId(e.target.value)

    var bankAccount = bankAccounts.find((x) => x.id === e.target.value)
    setAccountNumber(bankAccount?.accountNumber)
    setHolderName(bankAccount?.holderName)
    setBankId(bankAccount?.bankId)
    setBranchName(bankAccount?.branchName)
  }

  const handleCheckBalance = () => {
    if (withdrawalAmount <= 0 || withdrawalAmount > accountBalance) {
      return { error: true, message: validationMessage.insufficientBalance }
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-xl'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-title'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <div className='d-flex flex-column flex-xl-row flex-row-fluid between'>
            <div className='d-flex flex-column justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-400px'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                <span>
                  <FormattedMessage id='Ecom.Supplier.Withdrawal.Account' />
                </span>
              </label>
              <div className='scrollable-area'>
                {bankAccounts?.map((x, index) => {
                  return (
                    <label
                      key={index}
                      className={`card card-dashed d-flex flex-row flex-stack cursor-pointer p-4 
                        ${index === bankAccounts.length - 1 ? 'mb-0' : 'mb-4'} 
                        ${selectedAccountId === x.id ? 'border-primary' : ''}`}
                    >
                      <div className='d-flex align-items-center'>
                        <img src={`/media/bank-logos/${x.logo}`} alt='' className='me-4 w-60px' />
                        <div>
                          <div className='fs-4 fw-bold'>{x.accountNumber}</div>
                          <div className='fs-6 fw-semibold text-gray-500'>{x.holderName}</div>
                        </div>
                      </div>

                      <span className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='bank-account'
                          value={x.id}
                          checked={selectedAccountId === x.id}
                          onChange={handleChangeBankAccount}
                        />
                      </span>
                    </label>
                  )
                })}
              </div>
            </div>

            <div className='flex-row-fluid ps-lg-15'>
              <form className='d-flex flex-column gap-4'>
                <div className='col-md-12'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.accountNumber = ref)}
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({ id: 'Ecom.Supplier.AccountNumber' })}
                    value={accountNumber}
                    onChange={setAccountNumber}
                    tabIndex={tabIndex++}
                    isRequired={true}
                  />
                </div>

                <div className='col-md-12'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.holderName = ref)}
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({ id: 'Ecom.Supplier.HolderName' })}
                    value={holderName}
                    onChange={setHolderName}
                    tabIndex={tabIndex++}
                    isRequired={true}
                  />
                </div>

                <div className='col-md-12'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.bankId = ref)}
                    controlType={CONTROL_TYPE.SELECT}
                    label={intl.formatMessage({ id: 'Ecom.Supplier.BankName' })}
                    value={bankId}
                    onChange={setBankId}
                    tabIndex={tabIndex++}
                    isRequired={true}
                    options={banks}
                  />
                </div>

                <div className='col-md-12'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.branchName = ref)}
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({ id: 'Ecom.Supplier.BranchName' })}
                    value={branchName}
                    onChange={setBranchName}
                    tabIndex={tabIndex++}
                  />
                </div>

                <div className='col-md-12'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.withdrawalAmount = ref)}
                    controlType={CONTROL_TYPE.NUMBERBOX}
                    label={intl.formatMessage({ id: 'Ecom.Supplier.Withdrawal.Amount' })}
                    value={withdrawalAmount}
                    onChange={setWithdrawalAmount}
                    tabIndex={tabIndex++}
                    min={0}
                    reffix={DEFAULT_VALUE.VND}
                    isRequired={true}
                    validator={handleCheckBalance}
                  />
                </div>

                <div className='col-md-12'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.description = ref)}
                    controlType={CONTROL_TYPE.TEXTAREA}
                    label={intl.formatMessage({ id: 'Ecom.Supplier.Withdrawal.Description' })}
                    value={description}
                    onChange={setDescription}
                    tabIndex={tabIndex++}
                    isRequired={true}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='modal-footer gap-2'>
          {(_.isEmpty(modalState.id) || (user?.profile?.sub === userId && status === WITHDRAWAL_STATUS_ENUM.NEW)) && (
            <Button
              title={intl.formatMessage({ id: 'Save' })}
              icon='SaveOutlined'
              className='btn-success'
              onClick={handleSave}
              tabIndex={tabIndex++}
              disabled={false}
            />
          )}
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default WithdrawalEditor
