import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { useSupplierService, useCategoryService, useSubCategoryService, useCategoryTagService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'

const ProductFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  const { getComboboxAsync: getSupplierComboboxAsync } = useSupplierService()
  const { getCurrentListAsync: getCategoriesAsync } = useCategoryService()
  const { getCurrentListAsync: getSubCategoriesAsync } = useSubCategoryService()
  const { getCurrentListAsync: getCategoryTagsAsync } = useCategoryTagService()

  let tabIndex = 1

  const [code, setCode] = useState(null)
  const [name, setName] = useState(null)
  const [supplierId, setSupplierId] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [tags, setTags] = useState(null)
  const [stock, setStock] = useState(null)

  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [categoryTags, setCategoryTags] = useState([])

  useEffect(() => {
    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync()
      if (result) {
        setSuppliers(result)
      }
    }

    const fetchCategories = async () => {
      const { result } = await getCategoriesAsync()
      if (result) {
        setCategories(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchSuppliers()
    fetchCategories()
  }, [])

  useEffect(() => {
    const fetchSubCategories = async () => {
      const { result } = await getSubCategoriesAsync(categoryId)
      if (result) {
        const subCategoryList = result.map((item) => {
          return { value: item.id, label: item.name }
        })

        setSubCategories(subCategoryList)
        if (!subCategoryList?.some((item) => item.value === subCategoryId)) {
          setSubCategoryId(null)
        }
      }
    }

    const fetchCategoryTags = async () => {
      const { result } = await getCategoryTagsAsync(categoryId)
      if (result) {
        const categoryTagList = result.map((item) => {
          return { value: item.id, label: item.name }
        })
        setCategoryTags(categoryTagList)
        if (!categoryTagList?.some((item) => tags === item.value)) {
          setTags(null)
        }
      }
    }

    if (categoryId) {
      fetchSubCategories()
      fetchCategoryTags()
    } else {
      setSubCategoryId(null)
      setTags(null)
    }
  }, [categoryId])

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      name,
      code,
      supplierId,
      categoryId,
      subCategoryId,
      tags: tags && [tags],
      stock,
    }),
    reset: () => {
      setName(null)
      setCode(null)
      setSupplierId(null)
      setCategoryId(null)
      setTags(null)
      setStock(null)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.code = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Prodventory.Product.Code' })}
          value={code}
          onChange={setCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Prodventory.Product.Name' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>

      {user.profile.role === ROLE.ADMIN && (
        <div className='col-md-12'>
          <FormControl
            controlType={CONTROL_TYPE.SELECT}
            ref={(ref) => (componentRefs.current.supplierId = ref)}
            label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
            value={supplierId}
            onChange={setSupplierId}
            tabIndex={tabIndex++}
            options={suppliers}
          />
        </div>
      )}

      <div className='col-md-12'>
        <FormControl
          controlType={CONTROL_TYPE.SELECT}
          ref={(ref) => (componentRefs.current.categoryId = ref)}
          label={intl.formatMessage({ id: 'Prodventory.Product.Category' })}
          value={categoryId}
          onChange={setCategoryId}
          tabIndex={tabIndex++}
          options={categories}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          controlType={CONTROL_TYPE.SELECT}
          ref={(ref) => (componentRefs.current.subCategoryId = ref)}
          label={intl.formatMessage({ id: 'Prodventory.Product.SubCategory' })}
          value={subCategoryId}
          onChange={setSubCategoryId}
          tabIndex={tabIndex++}
          options={subCategories}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          controlType={CONTROL_TYPE.SELECT}
          ref={(ref) => (componentRefs.current.categoryTagIds = ref)}
          label={intl.formatMessage({ id: 'Prodventory.Product.CategoryTag' })}
          value={tags}
          onChange={setTags}
          tabIndex={tabIndex++}
          options={categoryTags}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.stock = ref)}
          controlType={CONTROL_TYPE.NUMBERBOX}
          label={intl.formatMessage({ id: 'Prodventory.Product.Stock' })}
          value={stock}
          onChange={setStock}
          tabIndex={tabIndex++}
          min={0}
        />
      </div>
    </form>
  )
})

export default ProductFilter
