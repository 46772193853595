import useApiConnection from '../apiConnection'
import { PRODVENTORY_ENDPOINTS } from '../apiEndpoints'

const useProductDetailService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, PRODVENTORY_ENDPOINTS.PRODUCT_INVENTORY)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getCurrentListAsync = async (productId, payload) => {
    return await api.postAsync(`get-current-list/${productId}`, { payload })
  }

  const getListAsync = async (productId, payload) => {
    return await api.getAsync(`get-by-productId/${productId}`, { payload })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  const deleteRangeAsync = async (payload) => {
    return await api.deleteAsync('', { payload })
  }

  return { getByIdAsync, getCurrentListAsync, getListAsync, insertAsync, updateAsync, deleteAsync, deleteRangeAsync }
}

export default useProductDetailService
