import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../../../components/shared/FormControl'
import Button from '../../../components/shared/Button'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useUserService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'
import { formattedError } from '../../../utilities/sharedFunction'

const ChangePassword = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  const { changePasswordAsync } = useUserService()

  const [currentPassword, setCurrentPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [confirmNewPassword, setConfirmNewPassword] = useState(null)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null)
  const [error, setError] = useState('')

  const handleSave = async () => {
    let isValid = await validationAllComponents(componentRefs)
    if (!isValid || !isValidConfirmPassword) return

    const password = {
      id: user?.profile?.id,
      currentPassword: currentPassword,
      newPassword: newPassword,
    }
    const { error } = await changePasswordAsync(password)
    if (_.isEmpty(error)) {
      setError('')
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
    } else {
      setError(error.message)
    }
  }

  const validatorConfirmNewPassword = () => {
    setIsValidConfirmPassword(true)
    if (_.isEmpty(newPassword) || _.isEmpty(confirmNewPassword)) return
    if (newPassword !== confirmNewPassword) {
      setIsValidConfirmPassword(false)
      return { error: true, message: `${intl.formatMessage({ id: 'User.ResetPassword.ValidatorConfirmPassword' })}` }
    }
  }

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h4 className='fw-bolder m-0'>{intl.formatMessage({ id: 'User.ResetPassword' })}</h4>
        </div>
      </div>
      <div className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='flex-row-fluid'>
            <form className='form'>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                {error && (
                  <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formattedError(error)}</div>
                  </div>
                )}
                <div className='mb-4 col-6'>
                  <FormControl
                    controlType={CONTROL_TYPE.INPUT}
                    ref={(ref) => (componentRefs.current.currentPassword = ref)}
                    label={intl.formatMessage({ id: 'User.ResetPassword.CurrentPassword' })}
                    size='large'
                    value={currentPassword}
                    onChange={setCurrentPassword}
                    readOnly={false}
                    tabIndex={1}
                    isRequired={true}
                    hideText={true}
                  />
                </div>
                <div className='mb-4 col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.newPassword = ref)}
                    tabIndex={2}
                    size='large'
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({
                      id: 'User.ResetPassword.NewPassword',
                    })}
                    value={newPassword}
                    isRequired={true}
                    isPassword={true}
                    hideText={true}
                    onChange={setNewPassword}
                  />
                  <div className='form-text'>{intl.formatMessage({ id: 'User.ResetPassword.Note' })}</div>
                </div>
                <div className='mb-8 col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.confirmNewPassword = ref)}
                    tabIndex={3}
                    size='large'
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({
                      id: 'User.ResetPassword.ConfirmNewPassword',
                    })}
                    value={confirmNewPassword}
                    isRequired={true}
                    isPassword={true}
                    hideText={true}
                    validator={() => validatorConfirmNewPassword()}
                    onChange={setConfirmNewPassword}
                  />
                </div>

                <Button onClick={handleSave} icon='SaveOutlined' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
