import { Route, Routes } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../components/layout/header/breadcrumb/PageData'
import Dashboard from '../pages/ecom/dashboard/Dashboard'
import Banners from '../pages/ecom/banner/Banners'
import Orders from '../pages/ecom/order/Orders'
import Suppliers from '../pages/ecom/supplier/Suppliers'
import Affiliates from '../pages/ecom/affiliate/Affiliates'
import Customers from '../pages/ecom/customer/Customers'
import Promotions from '../pages/ecom/promotion/Promotions'
import Vouchers from '../pages/ecom/voucher/Vouchers'
import News from '../pages/ecom/news/News'
import NewsEditor from '../pages/ecom/news/NewsEditor'
import Levels from '../pages/ecom/level/Levels'
import LevelGroups from '../pages/ecom/levelGroup/LevelGroups'
import FeaturedProducts from '../pages/ecom/featuredProduct/FeaturedProducts'
import Finances from '../pages/ecom/finance/Finances'
import { getMenuItems } from '../utilities/sharedFunction'
import { useAuth } from '../components/auth/AuthContext'

export var ecomMenuItems = [
  {
    to: 'dashboard',
    icon: 'HomeOutlined',
    title: 'Ecom.Dashboard',
    id: 'ecom-dashboard',
    component: Dashboard,
  },
  {
    to: 'banners/list',
    icon: 'FileImageOutlined',
    title: 'Ecom.Banner',
    id: 'ecom-banner',
    component: Banners,
  },
  {
    to: 'orders/list',
    icon: 'OrderedListOutlined',
    title: 'Ecom.Order',
    id: 'ecom-order',
    component: Orders,
  },
  {
    to: 'suppliers/list',
    icon: 'TeamOutlined',
    title: 'Ecom.Supplier',
    id: 'ecom-supplier',
    component: Suppliers,
  },
  {
    to: 'affiliate-product/list',
    icon: 'UsergroupAddOutlined',
    title: 'Ecom.Affiliate',
    id: 'ecom-affiliate',
    component: Affiliates,
  },
  {
    to: 'customers/list',
    icon: 'UsergroupAddOutlined',
    title: 'Ecom.Customer',
    id: 'ecom-customer',
    component: Customers,
  },
  {
    to: 'featured-product/list',
    icon: 'ProductOutlined',
    title: 'Ecom.FeaturedProduct',
    id: 'ecom-featured-product',
    component: FeaturedProducts,
  },
  {
    to: 'finance/list',
    icon: 'DollarOutlined',
    title: 'Ecom.Finance',
    id: 'ecom-finance',
    component: Finances,
  },
  {
    section: 'Ecom.Discount',
    items: [
      {
        to: 'promotions/list',
        title: 'Ecom.Promotion',
        hasBullet: true,
        id: 'ecom-promotion',
        component: Promotions,
      },
      {
        to: 'promotions/product',
        title: 'Ecom.Promotion.Product',
        hasBullet: true,
        id: 'ecom-promotion-product',
        component: Promotions,
      },
      {
        to: 'promotions/category',
        title: 'Ecom.Promotion.Category',
        hasBullet: true,
        id: 'ecom-promotion-category',
        component: Promotions,
      },
      {
        to: 'promotions/supplier',
        title: 'Ecom.Promotion.Supplier',
        hasBullet: true,
        id: 'ecom-promotion-supplier',
        component: Promotions,
      },
      {
        to: 'vouchers/list',
        title: 'Ecom.Voucher',
        hasBullet: true,
        id: 'ecom-voucher',
        component: Vouchers,
      },
    ],
  },
  // {
  //   section: 'Ecom.News',
  //   items: [
  //     {
  //       to: 'news/list',
  //       title: 'Ecom.News.List',
  //       hasBullet: true,
  //       id: 'ecom-news-list',
  //       component: News,
  //     },
  //     {
  //       to: 'news/add-new',
  //       title: 'Ecom.News.Add',
  //       hasBullet: true,
  //       id: 'ecom-news-add',
  //       component: NewsEditor,
  //     },
  //     {
  //       to: `news/:newsId/*`,
  //       title: 'Ecom.News.Add',
  //       hasBullet: true,
  //       id: 'ecom-news-update',
  //       component: NewsEditor,
  //       hidden: true,
  //     },
  //   ],
  // },
  {
    section: 'Ecom.Setting',
    items: [
      {
        to: 'level/list',
        title: 'Ecom.Level.List',
        hasBullet: true,
        id: 'ecom-level',
        component: Levels,
      },
      {
        to: 'level-groups/list',
        title: 'Ecom.LevelGroup.List',
        hasBullet: true,
        id: 'ecom-level-group',
        component: LevelGroups,
      },
    ],
  },
]

const EcomRoutes = () => {
  const intl = useIntl()
  const { userPermissions } = useAuth()

  const renderRoutes = (items) => {
    return items.flatMap((item) => {
      if (item.items) {
        return renderRoutes(item.items)
      }

      if (item.component) {
        const breadCrumbs = [{ title: intl.formatMessage({ id: 'Menu.Ecom' }) }]
        return (
          <Route
            key={item.id}
            path={item.to}
            element={
              <>
                <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({ id: item.title })}</PageTitle>
                <item.component />
              </>
            }
          />
        )
      }

      return []
    })
  }

  return <Routes>{renderRoutes(getMenuItems(ecomMenuItems, userPermissions))}</Routes>
}
export default EcomRoutes
