import React, { forwardRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useFileService } from '../../services/apiServices'
import { addToFormData } from '../../utilities/sharedFunction'

const CkEditor = forwardRef((props, ref) => {
  const { onChange, value, tabIndex, onBlur, readOnly, isValid, errorMessage } = props
  const { uploadAsync } = useFileService()

  function uploadAdapter(loader) {
    return {
      upload: async () => {
        try {
          const file = await loader.file

          const formData = new FormData()
          addToFormData(formData, 'file', file)

          const { result } = await uploadAsync(formData)

          if (result && result.url) {
            return { default: result.url }
          } else {
            throw new Error('No URL returned from uploadAsync')
          }
        } catch (error) {
          console.error('Upload failed:', error)
          throw error
        }
      },
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  const editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'blockQuote',
        'link',
        'numberedList',
        'bulletedList',
        'imageUpload',
        'insertTable',
        'mediaEmbed',
        '|',
        'undo',
        'redo',
        'alignment',
        'code',
        'codeBlock',
        'fontColor',
        'fontFamily',
        'fontSize',
        'fontBackgroundColor',
        'highlight',
        'horizontalLine',
        'htmlEmbed',
        'imageInsert',
      ],
    },
    image: {
      toolbar: ['imageTextAlternative', 'toggleImageCaption', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:slide'],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    extraPlugins: [uploadPlugin],
  }

  return (
    <>
      <CKEditor
        config={editorConfig}
        editor={ClassicEditor}
        data={value ?? ''}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange(data)
        }}
        tabIndex={tabIndex ?? 0}
        onBlur={onBlur}
        data-isvalid={isValid}
        readOnly={readOnly}
        ref={ref}
      />
      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default CkEditor
