import React from 'react'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { SignalRProvider } from './components/contexts/SignalRContext'
import { I18nProvider } from './components/contexts/I18nContext'
import InitLayout from './components/layout/InitLayout'
import { Toastify } from './components/shared/Toast'
import { LoadingProvider } from './components/contexts/LoadingContext'
import LoadingSpinner from './components/shared/LoadingSpinner'
import { Spin } from 'antd'

const App = () => {
  return (
    <Suspense
      fallback={
        <div className='loading-overlay d-flex justify-content-center align-items-center'>
          <Spin size='large' />
        </div>
      }
    >
      <InitLayout />
      <Toastify />
      <I18nProvider>
        <SignalRProvider>
          <LoadingProvider>
            <LoadingSpinner />
            <Outlet />
          </LoadingProvider>
        </SignalRProvider>
      </I18nProvider>
    </Suspense>
  )
}

export default App
