/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useWarehouseService, useLocationService, useSupplierService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'
import { WAREHOUSE_TYPE_ENUM, getEnums } from '../../../utilities/enum'

const WarehouseEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const { user } = useAuth()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, getComboboxAsync: getWarehouseComboboxAsync, insertAsync, updateAsync } = useWarehouseService()
  const { getProvincesAsync, getDistrictsAsync, getWardsAsync } = useLocationService()
  const { getComboboxByWarehouseIdAsync: getSupplierComboboxAsync } = useSupplierService()

  const [warehouseId, setWarehouseId] = useState()
  const [supplierIds, setSupplierIds] = useState()
  const [name, setName] = useState()
  const [type, setType] = useState()
  const [addressId, setAddressId] = useState()
  const [provinceId, setProvinceId] = useState()
  const [districtId, setDistrictId] = useState()
  const [wardId, setWardId] = useState()
  const [addressLine, setAddressLine] = useState()
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [isActive, setIsActive] = useState()
  const [useAdminWarehouse, setUseAdminWarehouse] = useState()

  const [types] = useState(getEnums(WAREHOUSE_TYPE_ENUM, intl))
  const [warehouses, setWarehouses] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvincesAsync()
      if (result) {
        setProvinces(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    const fetchWarehouse = async () => {
      const { result } = await getWarehouseComboboxAsync()
      if (result) {
        setWarehouses(result)
      }
    }

    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync(modalState.id)
      if (result) {
        setSuppliers(result)
      }
    }

    setUseAdminWarehouse(false)
    setWarehouseId(null)
    setSupplierIds(null)

    if (modalState.show) {
      fetchProvinces()
      fetchWarehouse()
      fetchSuppliers()
      modalState.id ? fetchData(modalState.id) : setData()
    } else {
      setData()
    }
  }, [modalState])

  useEffect(() => {
    const fetchDistricts = async (provinceId) => {
      const { result } = await getDistrictsAsync(provinceId)
      if (result) {
        const districtList = result.map((item) => ({ value: item.id, label: item.name }))

        setDistricts(districtList)

        if (!districtList.some((district) => district.value === districtId)) {
          setDistrictId(null)
        }
      }
    }

    if (provinceId) {
      fetchDistricts(provinceId)
    } else {
      setDistricts([])
      setDistrictId(null)
    }
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async (districtId) => {
      const { result } = await getWardsAsync(districtId)
      if (result) {
        const wardsList = result.map((item) => ({ value: item.id, label: item.name }))

        setWards(wardsList)

        if (!wardsList.some((ward) => ward.value === wardId)) {
          setWardId(null)
        }
      }
    }

    if (districtId) {
      fetchWards(districtId)
    } else {
      setWards([])
      setWardId(null)
    }
  }, [districtId])

  useEffect(() => {
    setUseAdminWarehouse(warehouseId ? true : false)
    fetchData(warehouseId)
  }, [warehouseId])

  useEffect(() => {
    if (!modalState.id) {
      setSupplierIds(null)
    }
  }, [type])

  const setData = (item) => {
    let defaultType = user.profile.role === ROLE.SUPPLIER ? WAREHOUSE_TYPE_ENUM.SUPPLIER : WAREHOUSE_TYPE_ENUM.ADMIN

    setName(item?.name)
    setAddressId(item?.addressId)
    setProvinceId(item?.address?.provinceId)
    setDistrictId(item?.address?.districtId)
    setWardId(item?.address?.wardId)
    setAddressLine(item?.address?.addressLine)
    setLatitude(item?.address?.latitude)
    setLongitude(item?.address?.longitude)
    setIsActive(item?.isActive ?? true)
    setType(warehouseId || modalState.id ? item?.type : defaultType)
    if (modalState.id) {
      setSupplierIds(item?.type === WAREHOUSE_TYPE_ENUM.ADMIN ? item?.suppliers?.map((x) => x.id) : item?.suppliers?.[0]?.id)
    }
  }

  const fetchData = async (id) => {
    const { result } = await getByIdAsync(id)
    if (result) {
      setData(result)
    }
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id: modalState.id ?? null,
      name,
      type,
      address: {
        id: addressId,
        provinceId,
        districtId,
        wardId,
        addressLine,
        latitude,
        longitude,
        isActive: true,
      },
      isActive,
      supplierIds: !_.isEmpty(supplierIds) ? (Array.isArray(supplierIds) ? supplierIds : [supplierIds]) : null,
      warehouseId,
    }

    const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            {user.profile.role === ROLE.ADMIN && (
              <div className='col-md-12'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.type = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Warehouse.Type' })}
                  value={type}
                  onChange={setType}
                  tabIndex={tabIndex++}
                  options={types}
                  isRequired={true}
                  readOnly={useAdminWarehouse}
                />
              </div>
            )}

            {!modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.warehouseId = ref)}
                  label={intl.formatMessage({ id: 'Prodventory.Warehouse.AdminWarehouse' })}
                  value={warehouseId}
                  onChange={setWarehouseId}
                  tabIndex={tabIndex++}
                  options={warehouses}
                />
              </div>
            )}

            {user.profile.role === ROLE.ADMIN &&
              (WAREHOUSE_TYPE_ENUM.ADMIN === type ? (
                <div className='col-md-12 mt-4'>
                  <FormControl
                    controlType={CONTROL_TYPE.MULTISELECT}
                    ref={(ref) => (componentRefs.current.supplierIds = ref)}
                    label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
                    value={supplierIds}
                    onChange={setSupplierIds}
                    tabIndex={tabIndex++}
                    options={suppliers}
                    isRequired={useAdminWarehouse}
                    readOnly={user.profile.role === ROLE.SUPPLIER}
                  />
                </div>
              ) : (
                <div className='col-md-12 mt-4'>
                  <FormControl
                    controlType={CONTROL_TYPE.SELECT}
                    ref={(ref) => (componentRefs.current.supplierId = ref)}
                    label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
                    value={supplierIds}
                    onChange={setSupplierIds}
                    tabIndex={tabIndex++}
                    options={suppliers}
                    isRequired={true}
                    readOnly={user.profile.role === ROLE.SUPPLIER}
                  />
                </div>
              ))}

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Prodventory.Warehouse.Name' })}
                value={name}
                onChange={setName}
                isRequired={true}
                tabIndex={tabIndex++}
                readOnly={useAdminWarehouse}
              />
            </div>

            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.provinceId = ref)}
                  label={intl.formatMessage({ id: 'Province' })}
                  isRequired={true}
                  value={provinceId}
                  onChange={setProvinceId}
                  tabIndex={tabIndex++}
                  options={provinces}
                  readOnly={useAdminWarehouse}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.districtId = ref)}
                  label={intl.formatMessage({ id: 'District' })}
                  isRequired={true}
                  value={districtId}
                  onChange={setDistrictId}
                  tabIndex={tabIndex++}
                  options={districts}
                  readOnly={useAdminWarehouse}
                />
              </div>

              <div className='col-md-4'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.wardId = ref)}
                  label={intl.formatMessage({ id: 'Ward' })}
                  isRequired={true}
                  value={wardId}
                  onChange={setWardId}
                  tabIndex={tabIndex++}
                  options={wards}
                  readOnly={useAdminWarehouse}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.addressLine = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'AddressLine' })}
                value={addressLine}
                rows={2}
                onChange={setAddressLine}
                tabIndex={tabIndex++}
                isRequired={true}
                readOnly={useAdminWarehouse}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'Prodventory.Category.Tag.IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default WarehouseEditor
