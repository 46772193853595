import BankAccounts from './components/BankAccounts'
import SalesRevenues from './components/SalesRevenues'
import WithdrawalHistories from './components/WithdrawalHistories'

const Billing = () => {
  return (
    <>
      <BankAccounts />
      <WithdrawalHistories />
      <SalesRevenues />
    </>
  )
}
export default Billing
