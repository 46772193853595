import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const TOAST_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
}

const showToast = (message, type, options) => {
  const defaultOptions = {
    position: 'top-right',
    autoClose: 3000,
    type: type,
  }
  options = options ? options : defaultOptions
  toast(message, options)
}

const Toastify = () => {
  return <ToastContainer />
}

export { showToast, Toastify, TOAST_TYPES }
