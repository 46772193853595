import React from 'react'
import * as AntIcons from '@ant-design/icons'

const Icon = ({ className = '', iconType = 'default', iconName }) => {
  const IconComponent = AntIcons[iconName]

  if (!IconComponent) {
    return <></>
  }

  return <div className={`ant-icon ${className}`}>{iconType === 'spin' ? <IconComponent spin /> : <IconComponent />}</div>
}

export default Icon
