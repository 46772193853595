/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { AFFILIATE_STATUS_ENUM, GENDER_ENUM, getEnums } from '../../../utilities/enum'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useAffiliateService, useLocationService } from '../../../services/apiServices'
import { addToFormData } from '../../../utilities/sharedFunction'
import dayjs from 'dayjs'

const AffiliateEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync, approveAsync, rejectAsync } = useAffiliateService()
  const { getProvincesAsync, getDistrictsAsync, getWardsAsync } = useLocationService()

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [avatar, setAvatar] = useState()
  const [gender, setGender] = useState()
  const [birthday, setBirthday] = useState()
  const [taxCode, setTaxCode] = useState()
  const [identificationCardCode, setIdentificationCardCode] = useState()
  const [isActive, setIsActive] = useState(true)
  const [fileAvatar, setFileAvatar] = useState()
  const [status, setStatus] = useState()
  const [addressId, setAddressId] = useState()
  const [provinceId, setProvinceId] = useState()
  const [districtId, setDistrictId] = useState()
  const [wardId, setWardId] = useState()
  const [addressLine, setAddressLine] = useState()
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])
  const genders = getEnums(GENDER_ENUM, intl)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvincesAsync()
      if (result) {
        setProvinces(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    if (modalState.show) {
      fetchProvinces()
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  useEffect(() => {
    const fetchDistricts = async (provinceId) => {
      const { result } = await getDistrictsAsync(provinceId)
      if (result) {
        const districtList = result.map((item) => ({ value: item.id, label: item.name }))

        setDistricts(districtList)

        if (!districtList.some((district) => district.value === districtId)) {
          setDistrictId(null)
        }
      }
    }

    if (provinceId) {
      fetchDistricts(provinceId)
    } else {
      setDistricts([])
      setDistrictId(null)
    }
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async (districtId) => {
      const { result } = await getWardsAsync(districtId)
      if (result) {
        const wardsList = result.map((item) => ({ value: item.id, label: item.name }))

        setWards(wardsList)

        if (!wardsList.some((ward) => ward.value === wardId)) {
          setWardId(null)
        }
      }
    }

    if (districtId) {
      fetchWards(districtId)
    } else {
      setWards([])
      setWardId(null)
    }
  }, [districtId])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setFirstName(item?.firstName)
    setLastName(item?.lastName)
    setEmail(item?.email)
    setPhoneNumber(item?.phoneNumber)
    setAvatar(item?.avatar)
    setGender(item?.gender)
    setBirthday(item?.birthday ? dayjs(item?.birthday) : null)
    setTaxCode(item?.taxCode)
    setIdentificationCardCode(item?.identificationCardCode)
    setFileAvatar(item?.fileAvatar ?? [])
    setIsActive(item?.isActive ?? true)
    setStatus(item?.status)
    setAddressId(item?.addressId)
    setProvinceId(item?.address?.provinceId)
    setDistrictId(item?.address?.districtId)
    setWardId(item?.address?.wardId)
    setAddressLine(item?.address?.addressLine)
    setLatitude(item?.address?.latitude)
    setLongitude(item?.address?.longitude)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'id', modalState.id)
    addToFormData(formData, 'firstName', firstName)
    addToFormData(formData, 'lastName', lastName)
    addToFormData(formData, 'phoneNumber', phoneNumber)
    addToFormData(formData, 'email', email)
    addToFormData(formData, 'avatar', avatar)
    addToFormData(formData, 'gender', gender)
    addToFormData(formData, 'birthday', birthday.toISOString())
    addToFormData(formData, 'taxCode', taxCode)
    addToFormData(formData, 'identificationCardCode', identificationCardCode)
    addToFormData(formData, 'isActive', isActive)
    addToFormData(formData, 'address', {
      id: addressId,
      provinceId,
      districtId,
      wardId,
      addressLine,
      latitude,
      longitude,
      isActive: true,
    })

    if (!_.isEmpty(fileAvatar)) {
      let file = Array.isArray(fileAvatar) ? fileAvatar[0] : fileAvatar
      addToFormData(formData, 'fileAvatar', file.url ? null : file.originFileObj)
    }

    const { error } = modalState.id ? await updateAsync(formData) : await insertAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleApprove = async () => {
    const { error } = await approveAsync([modalState.id])

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleReject = async () => {
    const { error } = await rejectAsync([modalState.id])

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-title'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.firstName = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.FirstName' })}
                  value={firstName}
                  onChange={setFirstName}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>

              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.lastName = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.LastName' })}
                  value={lastName}
                  onChange={setLastName}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.email = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.Email' })}
                  value={email}
                  onChange={setEmail}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  isEmail={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                  controlType={CONTROL_TYPE.PHONENUMBER}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.PhoneNumber' })}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.birthday = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.Birthday' })}
                  value={birthday}
                  isRequired={true}
                  onChange={setBirthday}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.gender = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.Gender' })}
                  value={gender}
                  isRequired={true}
                  onChange={setGender}
                  options={genders}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.identificationCardCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.IdentificationCardCode' })}
                  value={identificationCardCode}
                  onChange={setIdentificationCardCode}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.taxCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.TaxCode' })}
                  value={taxCode}
                  onChange={setTaxCode}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.provinceId = ref)}
                  label={intl.formatMessage({ id: 'Province' })}
                  isRequired={true}
                  value={provinceId}
                  onChange={setProvinceId}
                  tabIndex={tabIndex++}
                  options={provinces}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.districtId = ref)}
                  label={intl.formatMessage({ id: 'District' })}
                  isRequired={true}
                  value={districtId}
                  onChange={setDistrictId}
                  tabIndex={tabIndex++}
                  options={districts}
                />
              </div>

              <div className='col-md-4'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.wardId = ref)}
                  label={intl.formatMessage({ id: 'Ward' })}
                  isRequired={true}
                  value={wardId}
                  onChange={setWardId}
                  tabIndex={tabIndex++}
                  options={wards}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.addressLine = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'AddressLine' })}
                value={addressLine}
                rows={2}
                onChange={setAddressLine}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />

          {AFFILIATE_STATUS_ENUM.PENDINGAPPROVAL === status && (
            <>
              <Button
                title={intl.formatMessage({ id: 'Confirm.Approve' })}
                icon='CheckOutlined'
                className='btn-primary'
                onClick={handleApprove}
                tabIndex={tabIndex++}
                disabled={false}
              />

              <Button
                title={intl.formatMessage({ id: 'Confirm.Reject' })}
                icon='CloseOutlined'
                className='btn-danger'
                onClick={handleReject}
                tabIndex={tabIndex++}
                disabled={false}
              />
            </>
          )}

          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default AffiliateEditor
