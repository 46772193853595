import React, { useState, useEffect, useRef } from 'react'
import { Table as AntTable } from 'antd'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useSidebar } from '../layout/aside/SidebarContext'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import Input from './Input'
import { CONTROL_TYPE } from './FormControl'
import NumberBox from './NumberBox'
import NumberFormat from './NumberFormat'

const EditableCell = (props) => {
  const { controlType, value, editable, onSave, onHover, isHovered } = props
  const [inputValue, setInputValue] = useState(value)
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    setIsChanged(true)
  }, [inputValue])

  const handleBlur = () => {
    if (isChanged) {
      onSave(inputValue)
      setIsChanged(false)
    }
  }

  const renderControl = () => {
    switch (controlType) {
      case CONTROL_TYPE.NUMBERBOX:
        const numberBoxProps = {
          prefix: props.prefix,
          suffix: props.suffix,
          min: props.min,
          max: props.max,
        }
        return <NumberBox {...numberBoxProps} value={inputValue} onChange={setInputValue} onBlur={handleBlur} />
      default:
        return <Input value={inputValue} onChange={setInputValue} onBlur={handleBlur} />
    }
  }

  const renderDisplayValue = () => {
    switch (controlType) {
      case CONTROL_TYPE.NUMBERBOX:
        return <NumberFormat amount={inputValue} suffix={props.suffix} />
      default:
        return inputValue
    }
  }

  return (
    <td
      onMouseEnter={onHover}
      onMouseLeave={() => {
        onHover()
        handleBlur()
      }}
    >
      {editable && isHovered ? renderControl() : renderDisplayValue()}
    </td>
  )
}

const EditTable = ({ columns, fetchData, onReload, toolbar, dataSource, onUpdate }) => {
  const parentRef = useRef(null)
  const intl = useIntl()
  const { asideMinimized } = useSidebar()
  const [loading, setLoading] = useState(false)
  const [scrollX, setScrollX] = useState(undefined)
  const [hoveredCell, setHoveredCell] = useState(null)
  const scroll = { y: true }

  useEffect(() => {
    if (onReload) {
      loadData()
    }
  }, [onReload])

  const loadData = async () => {
    setLoading(true)
    onUpdate([])

    try {
      const { result } = await fetchData()
      if (result) {
        onUpdate(result)
      } else {
        showToast(`${intl.formatMessage({ id: 'Error.Loading' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      console.log(error)
      showToast(`${intl.formatMessage({ id: 'Error.Loading' })}`, TOAST_TYPES.ERROR)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        // const totalWidth = columns.reduce((acc, col) => acc + col.width, 0)
        // const parentWidth = parentRef.current.offsetWidth
        // setScrollX(totalWidth > parentWidth ? totalWidth : undefined)
      }
    }

    handleResize()
  }, [asideMinimized])

  const getValueFromRecord = (record, dataIndex) => {
    const keys = dataIndex?.replace(/\[(\d+)\]/g, '.$1').split('.')
    return keys?.reduce((acc, key) => acc?.[key], record) || ''
  }

  const tableColumns = columns.map((item) => ({
    ...item,
    onCell: (record, rowIndex) => {
      const value = getValueFromRecord(record, item.dataIndex)

      return {
        controlType: item.controlType,
        value: value,
        editable: item.editable,
        onSave: (value) => handleSave(rowIndex, item.dataIndex, value),
        onHover: () => setHoveredCell(rowIndex),
        isHovered: hoveredCell === rowIndex,
        ...item,
      }
    },
  }))

  if (scrollX) {
    scroll.x = '100vw'
    tableColumns[0].fixed = true
    tableColumns[tableColumns.length - 1].fixed = 'right'
  }

  const tableProps = {
    bordered: true,
    loading,
    size: 'small', //large, middle, small
    showHeader: true,
  }

  const handleSave = (rowIndex, dataIndex, value) => {
    const newData = [...dataSource]
    newData[rowIndex] = {
      ...newData[rowIndex],
      [dataIndex]: value,
    }
    onUpdate(newData)
  }

  return (
    <div
      ref={parentRef}
      // style={{
      //   overflowX: 'auto',
      //   width: `calc(100vw - ${asideMinimized ? '125px' : '385px'})`,
      //   maxWidth: `calc(100vw - ${asideMinimized ? '125px' : '385px'})`,
      // }}
    >
      <AntTable
        {...tableProps}
        pagination={false}
        columns={tableColumns}
        dataSource={
          dataSource
            ? dataSource.map((row, index) => ({
                ...row,
                key: row.id || index,
              }))
            : []
        }
        scroll={scroll}
        title={toolbar ? () => toolbar : null}
        components={!_.isEmpty(dataSource) ? { body: { cell: EditableCell } } : null}
      />
    </div>
  )
}
export default EditTable
