import useApiConnection from '../apiConnection'
import { GLOBALADMIN_ENDPOINTS } from '../apiEndpoints'

const useBankAccountService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, GLOBALADMIN_ENDPOINTS.BANK_ACCOUNT)

  const getBanksAsync = async () => {
    return await api.getAsync('get-banks')
  }

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async () => {
    return await api.getAsync('')
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  return { getBanksAsync, getByIdAsync, getListAsync, insertAsync, updateAsync, deleteAsync }
}

export default useBankAccountService
