import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { BANNER_POSITION_ENUM, getEnums } from '../../../utilities/enum'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useBannerService, usePromotionService } from '../../../services/apiServices'
import dayjs from 'dayjs'
import { addToFormData, getFileFromUrl } from '../../../utilities/sharedFunction'

const BannerEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = useBannerService()
  const { getCurrentListAsync } = usePromotionService()

  const [title, setTitle] = useState(null)
  const [subTitle, setSubTitle] = useState(null)
  const [displaySeconds, setDisplaySeconds] = useState(5)
  const [fromDate, setFromDate] = useState(dayjs(new Date()))
  const [toDate, setToDate] = useState(null)
  const [order, setOrder] = useState(null)
  const [position, setPosition] = useState(null)
  const [promotionId, setPromotionId] = useState(null)
  const [images, setImages] = useState(null)
  const [fileImages, setFileImages] = useState([])
  const [isActive, setIsActive] = useState(true)

  const positions = getEnums(BANNER_POSITION_ENUM, intl)
  const [promotions, setPromotions] = useState([])

  useEffect(() => {
    const fetchPromotions = async () => {
      const { result } = await getCurrentListAsync()
      if (result) {
        setPromotions(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    if (modalState.show) {
      fetchPromotions()
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setTitle(item?.title)
    setSubTitle(item?.subTitle)
    setDisplaySeconds(item?.displaySeconds ?? 5)
    setFromDate(dayjs(item?.fromDate) ?? dayjs(new Date()))
    setToDate(item?.toDate ? dayjs(item?.toDate) : null)
    setOrder(item?.order)
    setPosition(item?.position)
    setPromotionId(item?.promotionId)
    setImages(item?.images)
    setFileImages(item?.fileImages ?? [])
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'id', modalState.id)
    addToFormData(formData, 'title', title)
    addToFormData(formData, 'subTitle', subTitle)
    addToFormData(formData, 'displaySeconds', displaySeconds)
    addToFormData(formData, 'fromDate', fromDate.toISOString())
    addToFormData(formData, 'toDate', toDate ? toDate.toISOString() : null)
    addToFormData(formData, 'order', order)
    addToFormData(formData, 'position', position)
    addToFormData(formData, 'promotionId', promotionId)
    addToFormData(formData, 'isActive', isActive)
    addToFormData(formData, 'images', images)

    if (fileImages) {
      const fileImagesPromises = fileImages.map((file) => (file.url ? getFileFromUrl(file.url, file.name, file.type) : Promise.resolve(file.originFileObj)))
      const resolvedFiles = await Promise.all(fileImagesPromises)

      resolvedFiles.forEach((file) => addToFormData(formData, 'fileImages', file))
    }

    const { error } = modalState.id ? await updateAsync(formData) : await insertAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-title'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.promotionId = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Banner.Promotion' })}
                  value={promotionId}
                  onChange={setPromotionId}
                  options={promotions}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.title = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Banner.Title' })}
                  value={title}
                  onChange={setTitle}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.subTitle = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Ecom.Banner.SubTitle' })}
                value={subTitle}
                rows={2}
                onChange={setSubTitle}
                tabIndex={tabIndex++}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.positionId = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Banner.Position' })}
                  value={position}
                  isRequired={true}
                  onChange={setPosition}
                  options={positions}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.displaySeconds = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Banner.DisplaySeconds' })}
                  value={displaySeconds}
                  onChange={setDisplaySeconds}
                  min={0}
                  isRequired={true}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fromDate = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Banner.FromDate' })}
                  value={fromDate}
                  isRequired={true}
                  onChange={setFromDate}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.toDate = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Banner.ToDate' })}
                  value={toDate}
                  onChange={(date) => setToDate(date)}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.fileImages = ref)}
                controlType={CONTROL_TYPE.FILEUPLOAD}
                label={intl.formatMessage({ id: 'Ecom.Banner.Images' })}
                value={fileImages}
                onChange={setFileImages}
                accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico'
                isRequired={true}
                isMultiple={true}
                tabIndex={tabIndex++}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default BannerEditor
