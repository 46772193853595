/* eslint-disable jsx-a11y/anchor-is-valid */
import { useI18n } from '../../contexts/I18nContext'
import { cultures } from '../../localization/cultures'

const Languages = () => {
  const { locale, changeCulture } = useI18n()
  const currentLanguage = cultures.find((x) => x.culture === locale)

  return (
    <>
      <div className='menu-item p-0' data-kt-menu-trigger='hover' data-kt-menu-placement='bottom-end' data-kt-menu-flip='bottom'>
        <a className='content menu-link'>
          <span className='menu-title position-relative'>
            <img className='w-20px h-20px rounded-1' src={currentLanguage?.flag} alt='flag' />
            <span className='ms-2'>{currentLanguage?.name}</span>
          </span>
        </a>
      </div>

      <div className='menu-gray-600 menu-state-bg menu-sub menu-sub-dropdown w-175px py-4' data-kt-menu='true'>
        {cultures.map((l, index) => (
          <div
            className='content menu-item px-3'
            key={index}
            onClick={() => {
              changeCulture(l.culture)
            }}
          >
            <a className={`menu-link d-flex px-5 ${l.culture === currentLanguage?.culture ? 'active' : ''}`}>
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='flag' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export default Languages
