import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../../components/shared/FormControl'
import Button from '../../components/shared/Button'
import { useAuthService } from '../../services/apiServices'
import { useAuth } from '../../components/auth/AuthContext'
import { formattedError } from '../../utilities/sharedFunction'
import { Link } from 'react-router-dom'

const NewPassword = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  const { newPasswordAsync } = useAuthService()
  const { reloadUser } = useAuth()

  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const [returnUrl, setReturnUrl] = useState('')
  const [newPassword, setNewPassword] = useState(null)
  const [confirmNewPassword, setConfirmNewPassword] = useState(null)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null)
  const [error, setError] = useState('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    const emailParam = urlParams.get('email')
    const tokenParam = urlParams.get('token')
    const returnUrlParam = urlParams.get('returnUrl')

    if (emailParam) setEmail(decodeURIComponent(emailParam))
    if (tokenParam) setToken(decodeURIComponent(tokenParam))
    if (returnUrlParam) setReturnUrl(decodeURIComponent(returnUrlParam))
  }, [])

  const handleSubmit = async () => {
    let isValid = await validationAllComponents(componentRefs)
    if (!isValid || !isValidConfirmPassword) return

    const { error, result } = await newPasswordAsync({
      id: user?.profile?.id,
      password: newPassword,
      confirmPassword: confirmNewPassword,
      email,
      token,
      returnUrl,
    })
    if (_.isEmpty(error)) {
      reloadUser(result)
    } else {
      setError(error.message)
    }
  }

  const validatorConfirmNewPassword = () => {
    setIsValidConfirmPassword(true)
    if (_.isEmpty(newPassword) || _.isEmpty(confirmNewPassword)) return
    if (newPassword !== confirmNewPassword) {
      setIsValidConfirmPassword(false)
      return { error: true, message: `${intl.formatMessage({ id: 'User.ResetPassword.ValidatorConfirmPassword' })}` }
    }
  }

  return (
    <form className='form'>
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>
          <FormattedMessage id='Affiliate.NewPassword' />
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Green24h.com</div>
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        {error && (
          <div className='alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formattedError(error)}</div>
          </div>
        )}
        <div className='mb-4 col-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.newPassword = ref)}
            tabIndex={2}
            size='large'
            controlType={CONTROL_TYPE.INPUT}
            label={intl.formatMessage({
              id: 'User.ResetPassword.NewPassword',
            })}
            value={newPassword}
            isRequired={true}
            isPassword={true}
            hideText={true}
            onChange={setNewPassword}
          />
          <div className='form-text'>{intl.formatMessage({ id: 'User.ResetPassword.Note' })}</div>
        </div>
        <div className='mb-8 col-12'>
          <FormControl
            ref={(ref) => (componentRefs.current.confirmNewPassword = ref)}
            tabIndex={3}
            size='large'
            controlType={CONTROL_TYPE.INPUT}
            label={intl.formatMessage({
              id: 'User.ResetPassword.ConfirmNewPassword',
            })}
            value={confirmNewPassword}
            isRequired={true}
            isPassword={true}
            hideText={true}
            validator={() => validatorConfirmNewPassword()}
            onChange={setConfirmNewPassword}
          />
        </div>

        <div className='d-flex flex-wrap justify-content-center d-grid mb-10 gap-4'>
          <Button onClick={handleSubmit} icon='SaveOutlined' title={intl.formatMessage({ id: 'Submit' })} className={'btn btn-success'} />
          <Link to='/login' className='btn btn-light'>
            <FormattedMessage id='Cancel' />
          </Link>
        </div>
      </div>
    </form>
  )
}

export default NewPassword
