import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import Button from '../../../../components/shared/Button'
import { useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../../components/shared/Toast'
import { useUserService } from '../../../../services/apiServices'
import { addToFormData } from '../../../../utilities/sharedFunction'
import { useAuth } from '../../../../components/auth/AuthContext'

const UserProfile = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { user } = useAuth()
  const { getByIdAsync, updateAsync } = useUserService()

  const [id, setId] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [email, setEmail] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState(null)

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  const fetchData = async () => {
    const { result } = await getByIdAsync(user?.profile?.id)
    setData(result)
  }

  const setData = (item) => {
    setId(item?.id)
    setFirstName(item?.firstName)
    setLastName(item?.lastName)
    setPhoneNumber(item?.phoneNumber)
    setEmail(item?.email)
    setAvatarUrl(item?.avatarUrl)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id,
      firstName,
      lastName,
      phoneNumber: '' + phoneNumber,
      email,
      userName: email,
      isActive: true,
      avatarUrl
    }

    const { error } = await updateAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0 d-flex justify-content-between w-100'>
          <h4 className='fw-bolder m-0'>{intl.formatMessage({ id: 'User.UserProfile' })}</h4>
          <Button onClick={handleSave} icon='SaveOutlined' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} />
        </div>
      </div>
      <div className='collapse show'>
        <div className='card-body border-top'>
          <div className='flex-row-fluid'>
            <form className='d-flex flex-column gap-4 align-items-center'>
              <div className='col-12 col-md-6 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.customerName = ref)}
                  tabIndex={tabIndex++}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.FirstName' })}
                  value={firstName}
                  isRequired={true}
                  onChange={setFirstName}
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.customerName = ref)}
                  tabIndex={tabIndex++}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.LastName' })}
                  value={lastName}
                  isRequired={true}
                  onChange={setLastName}
                />
              </div>

              <div className='col-12 col-md-6 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                  tabIndex={tabIndex++}
                  controlType={CONTROL_TYPE.PHONENUMBER}
                  label={intl.formatMessage({ id: 'PhoneNumber' })}
                  value={phoneNumber}
                  isRequired={true}
                  onChange={setPhoneNumber}
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.email = ref)}
                  tabIndex={tabIndex++}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Email' })}
                  isRequired={true}
                  value={email}
                  onChange={setEmail}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserProfile
