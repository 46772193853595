import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import Icon from '../../../components/shared/Icon'
import TableFilter from '../../../components/shared/TableFilter'
import { useWarehouseService } from '../../../services/apiServices'
import WarehouseEditor from './WarehouseEditor'
import WarehouseFilter from './WarehouseFilter'
import { Tag, Tooltip } from 'antd'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'
import { WAREHOUSE_TYPE_ENUM } from '../../../utilities/enum'

const Warehouses = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const { getListAsync, deleteAsync } = useWarehouseService()
  const [modalState, setModalState] = useState({ id: null, show: false })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState()

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Warehouse.Code' })}`,
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: 80,
      showSorterTooltip: false,
      render: (code, data) => (
        <>
          {code}
          <br />
          <Tag color='blue' className='me-1 fs-7'>
            {intl.formatMessage({ id: `Enum.${data.typeName.toUpperCase()}` })}
          </Tag>
        </>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Warehouse.Name' })}`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 120,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}`,
      dataIndex: 'suppliers',
      sorter: (a, b) => {
        const supplierA = a.suppliers?.[0]?.name || ''
        const supplierB = b.suppliers?.[0]?.name || ''
        return supplierA.localeCompare(supplierB)
      },
      width: 120,
      showSorterTooltip: false,
      render: (suppliers) =>
        suppliers ? (
          <>
            {suppliers.map((supplier, index) => (
              <Tag key={index} color='blue' className='me-1 mb-1 fs-7'>
                {supplier.companyName}
              </Tag>
            ))}
          </>
        ) : (
          ''
        ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Warehouse.Address' })}`,
      dataIndex: 'address',
      sorter: (a, b) => {
        const addressA = a.address ? a.address.fullAddress : ''
        const addressB = b.address ? b.address.fullAddress : ''
        return addressA.localeCompare(addressB)
      },
      render: (address) => (address ? address.fullAddress : ''),
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => {
        const isAdmin = user.profile.role === ROLE.ADMIN
        const isSupplier = WAREHOUSE_TYPE_ENUM.SUPPLIER === item.type
        return isAdmin || isSupplier ? (
          <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
            <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
              <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
                <Icon iconName='EditOutlined' />
              </div>
            </Tooltip>

            <Confirm
              title={intl.formatMessage({ id: 'Notifications' })}
              description={intl.formatMessage({ id: 'Confirm.Remove' })}
              onConfirm={() => deleteData(item.id)}
              tooltip={intl.formatMessage({ id: 'Delete' })}
              button={
                <div className='btn btn-sm btn-icon btn-light-danger'>
                  <Icon iconName='DeleteOutlined' />
                </div>
              }
            />
          </div>
        ) : (
          <></>
        )
      },
    },
  ]

  const toolbar = (
    <div className='d-flex justify-content-between flex-column flex-md-row gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success me-5'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<WarehouseFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
      <WarehouseEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default Warehouses
