import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { ACTIVE_ENUM, WAREHOUSE_TYPE_ENUM, getEnums } from '../../../utilities/enum'
import { useSupplierService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'

const WarehouseFilter = forwardRef((_, ref) => {
  const { getComboboxAsync: getSupplierComboboxAsync } = useSupplierService()
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  let tabIndex = 1

  const [name, setName] = useState(null)
  const [code, setCode] = useState(null)
  const [type, setType] = useState(null)
  const [supplierId, setSupplierId] = useState(null)
  const [isActive, setIsActive] = useState(true)

  const statuses = getEnums(ACTIVE_ENUM, intl)
  const types = getEnums(WAREHOUSE_TYPE_ENUM, intl)
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync()
      if (result) {
        setSuppliers(result)
      }
    }

    fetchSuppliers()
  }, [])

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      name,
      code,
      type,
      supplierId,
      isActive,
    }),
    reset: () => {
      setName(null)
      setCode(null)
      setType(null)
      setSupplierId(null)
      setIsActive(true)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      {user.profile.role === ROLE.ADMIN && (
        <div className='col-md-12'>
          <FormControl
            controlType={CONTROL_TYPE.SELECT}
            ref={(ref) => (componentRefs.current.supplierId = ref)}
            label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
            value={supplierId}
            onChange={setSupplierId}
            tabIndex={tabIndex++}
            options={suppliers}
          />
        </div>
      )}

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.code = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Prodventory.Warehouse.Code' })}
          value={code}
          onChange={setCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Prodventory.Warehouse.Name' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.type = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Prodventory.Warehouse.Type' })}
          value={type}
          onChange={setType}
          options={types}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.positionId = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Status' })}
          value={isActive}
          onChange={setIsActive}
          options={statuses}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default WarehouseFilter
