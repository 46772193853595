/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, lazy } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { validationAllComponents } from '../../../components/shared/FormControl'
import Button from '../../../components/shared/Button'
import { addToFormData } from '../../../utilities/sharedFunction'
import { useProductService } from '../../../services/apiServices'
import Thumbnail from './components/Thumbnail'
import Category from './components/Category'
import Supplier from './components/Supplier'
import Status from './components/Status'
import ProductInfo from './components/ProductInfo'
import Images from './components/Images'
import Attribute from './components/Attribute'
import { MODULE } from '../../../utilities/constant'
import { PRODUCT_STATUS_ENUM } from '../../../utilities/enum'
import { useLoading } from '../../../components/contexts/LoadingContext'
import InventoryOuts from './components/InventoryOuts'

const ProductDetail = lazy(() => import('./components/ProductDetail'))

const ProductEditor = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const navigate = useNavigate()
  const { productId } = useParams()
  const { getByIdAsync, insertAsync, updateAsync, approveAsync, rejectAsync } = useProductService()
  const { showLoading, hideLoading } = useLoading()

  let tabIndex = 1
  const [data, setData] = useState({})
  const [payload, setPayload] = useState({})

  useEffect(() => {
    if (productId) {
      fetchData()
    } else {
      setData({})
    }
  }, [productId])

  const fetchData = async () => {
    try {
      showLoading()
      const { result } = await getByIdAsync(productId)
      if (result) {
        setData(result)
      } else {
        navigate(`/${MODULE.PRODVENTORY}/products/add-new`)
      }
    } finally {
      setTimeout(() => {
        hideLoading()
      }, 500)
    }
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'id', productId)
    addToFormData(formData, 'categoryId', payload.categoryId)
    addToFormData(formData, 'subCategoryId', payload.subCategoryId)
    addToFormData(formData, 'tags', payload.tags)
    addToFormData(formData, 'supplierId', payload.supplierId)
    addToFormData(formData, 'status', payload.status)
    addToFormData(formData, 'name', payload.name)
    addToFormData(formData, 'description', payload.description)
    addToFormData(formData, 'userGuide', payload.userGuide)
    addToFormData(formData, 'thumbnail', payload.thumbnail)
    addToFormData(formData, 'license', payload.license)
    addToFormData(formData, 'images', payload.images)
    addToFormData(formData, 'attributes', payload.attributes)

    if (!_.isEmpty(payload.fileThumbnail)) {
      let file = Array.isArray(payload.fileThumbnail) ? payload.fileThumbnail[0] : payload.fileThumbnail
      if (file.originFileObj) {
        addToFormData(formData, 'fileThumbnail', file.originFileObj)
      }
    }

    if (!_.isEmpty(payload.fileLicense)) {
      let file = Array.isArray(payload.fileLicense) ? payload.fileLicense[0] : payload.fileLicense
      if (file.originFileObj) {
        addToFormData(formData, 'fileLicense', file.originFileObj)
      }
    }

    if (!_.isEmpty(payload.fileImages)) {
      const newFiles = []
      const currentfiles = []

      payload.fileImages.forEach((file) => {
        if (file.originFileObj) {
          newFiles.push(file.originFileObj)
        } else if (file.url) {
          currentfiles.push(file.id)
        }
      })

      addToFormData(formData, 'currentImages', currentfiles)
      newFiles.forEach((file) => addToFormData(formData, 'fileImages', file))
    }

    const { error, result } = _.isEmpty(productId) ? await insertAsync(formData) : await updateAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchData()
      navigate(`/${MODULE.PRODVENTORY}/products/${result.id}`)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleApprove = async () => {
    const { error } = await approveAsync([productId])

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      fetchData()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleReject = async () => {
    const { error } = await rejectAsync([productId])

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      fetchData()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <form id='product-detail' className='form d-flex flex-column flex-lg-row'>
      <div className='d-flex flex-column gap-4 w-100 w-lg-300px mb-5 me-lg-5'>
        <Thumbnail data={data} onChange={setPayload} componentRefs={componentRefs} />
        <Category data={data} onChange={setPayload} componentRefs={componentRefs} />
        <Supplier data={data} onChange={setPayload} componentRefs={componentRefs} />
        <Status data={data} onChange={setPayload} componentRefs={componentRefs} />
      </div>

      <div className='d-flex flex-column flex-row-fluid gap-7'>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2' role='tablist'>
          <li className='nav-item'>
            <a className='nav-link text-active-primary active fs-6 pb-2' data-bs-toggle='tab' href='#product_info'>
              <FormattedMessage id='Prodventory.Product.Info' />
            </a>
          </li>
          {productId && (
            <>
              <li className='nav-item'>
                <a className='nav-link text-active-primary fs-6 pb-2' data-bs-toggle='tab' href='#product_detail'>
                  <FormattedMessage id='Prodventory.Product.Inventory' />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-active-primary fs-6 pb-2' data-bs-toggle='tab' href='#product_inventory_out'>
                  <FormattedMessage id='Prodventory.Product.Inventory.Out' />
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link text-active-primary fs-6 pb-2' data-bs-toggle='tab' href='#product_review'>
                  <FormattedMessage id='Prodventory.Product.Review' />
                </a>
              </li>
            </>
          )}
        </ul>

        <div className='tab-content'>
          <div className='tab-pane fade show active' id='product_info'>
            <div className='d-flex flex-column gap-4'>
              <ProductInfo data={data} onChange={setPayload} componentRefs={componentRefs} />
              <Images data={data} onChange={setPayload} componentRefs={componentRefs} />
              <Attribute data={data} onChange={setPayload} componentRefs={componentRefs} />
            </div>
          </div>
          <div className='tab-pane fade' id='product_detail'>
            <div className='d-flex flex-column gap-4'>
              <ProductDetail data={data} />
            </div>
          </div>
          <div className='tab-pane fade' id='product_inventory_out'>
            <div className='d-flex flex-column gap-4'>
              <InventoryOuts data={data}/>
            </div>
          </div>
          <div className='tab-pane fade' id='product_review'>
            <div className='d-flex flex-column gap-4'>{/* <ProductInfo data={payload} onChange={setPayload} componentRefs={componentRefs} /> */}</div>
          </div>
        </div>

        <div className='d-flex justify-content-end gap-4'>
          <Button
            title={intl.formatMessage({ id: 'Back' })}
            icon='ArrowLeftOutlined'
            onClick={() => navigate('/prodventory/products/list')}
            tabIndex={tabIndex++}
            disabled={false}
          />

          {PRODUCT_STATUS_ENUM.PENDINGAPPROVAL === data.status && (
            <>
              <Button
                title={intl.formatMessage({ id: 'Confirm.Approve' })}
                icon='CheckOutlined'
                className='btn-primary'
                onClick={handleApprove}
                tabIndex={tabIndex++}
                disabled={false}
              />

              <Button
                title={intl.formatMessage({ id: 'Confirm.Reject' })}
                icon='CloseOutlined'
                className='btn-danger'
                onClick={handleReject}
                tabIndex={tabIndex++}
                disabled={false}
              />
            </>
          )}

          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
        </div>
      </div>
    </form>
  )
}

export default ProductEditor
