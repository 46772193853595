export const BANNER_POSITION_ENUM = {
  TOP: 1,
  MIDDLE: 2,
  BOTTOM: 3,
}

export const ACTIVE_ENUM = {
  INACTIE: false,
  ISACTIVE: true,
}

export const WAREHOUSE_TYPE_ENUM = {
  ADMIN: 1,
  SUPPLIER: 2,
}

export const SUPPLIER_STATUS_ENUM = {
  PENDINGAPPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
}

export const PRODUCT_STATUS_ENUM = {
  PENDINGAPPROVAL: 1,
  APPROVED: 2,
  STOPSELLING: 3,
  REJECTED: 4,
}

export const AFFILIATE_STATUS_ENUM = {
  PENDINGAPPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
}

export const GENDER_ENUM = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
}

export const PROMOTION_DISCOUNT_TYPE_ENUM = {
  PERCENTAGE: 1,
  FIXED_PRICE: 2,
  //BUY_MULTIPLE_GET_ONE: 3,
}

export const PROMOTION_TYPE_ENUM = {
  PROMOTION_PRODUCT: 1,
  PROMOTION_CATEGORY: 2,
  PROMOTION_SUPPLIER: 3,
}

export const VOUCHER_DISCOUNT_TYPE_ENUM = { PERCENTAGE: 1, FIXED_PRICE: 2 }

export const VOUCHER_TYPE_ENUM = {
  VOUCHER_SUPPLIER: 1,
  VOUCHER_SYSTEM: 2,
  VOUCHER_SHIPPING: 3,
}

export const FEATURED_PRODUCT_TYPE_ENUM = {
  TOPSELLING: 1,
  TRENDINGPRODUCT: 2,
  RECENTLYADDED: 3,
  TOPRATED: 4,
  HOT: 5,
  NEW: 6,
}

export const ORDER_STATUS_ENUM = {
  ALL: 0,
  WAITFORCONFIRM: 1,
  PREPARINGGOODS: 2,
  WAITFORDELIVERY: 3,
  DELIVERYRECEIVED: 4,
  COMPLETE: 5,
  CANCEL: 6,
  RETURNREFUND: 7,
}

export const PARTNER_TYPE_ENUM = {
  DISTRIBUTOR: 1,
  IMPORTER: 2,
  PRODUCER: 3,
}

export const WITHDRAWAL_STATUS_ENUM = {
  NEW: 1,
  APPROVED: 2,
  REJECTED: 3,
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const getEnums = (enums, intl, prefix) => {
  return Object.keys(enums).map((key) => ({
    value: enums[key],
    label: intl.formatMessage({ id: `${prefix ?? 'Enum'}.${key}` }),
  }))
}
