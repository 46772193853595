import { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { ACTIVE_ENUM, getEnums } from '../../../utilities/enum'

const AffiliateFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1

  const [code, setCode] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [identificationCardCode, setIdentificationCardCode] = useState(null)
  const [isActive, setIsActive] = useState(true)

  const statuses = getEnums(ACTIVE_ENUM, intl)

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      code,
      name,
      email,
      phoneNumber,
      identificationCardCode,
      isActive,
    }),
    reset: () => {
      setCode(null)
      setName(null)
      setEmail(null)
      setPhoneNumber(null)
      setIdentificationCardCode(null)
      setIsActive(true)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Affiliate.Code' })}
          value={code}
          onChange={setCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Affiliate' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.email = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Affiliate.Email' })}
          value={email}
          onChange={setEmail}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.phoneNumber = ref)}
          controlType={CONTROL_TYPE.PHONENUMBER}
          label={intl.formatMessage({ id: 'Ecom.Affiliate.PhoneNumber' })}
          value={phoneNumber}
          onChange={setPhoneNumber}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.identificationCardCode = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Affiliate.IdentificationCardCode' })}
          value={identificationCardCode}
          onChange={setIdentificationCardCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.positionId = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Supplier.IsActive' })}
          value={isActive}
          onChange={setIsActive}
          options={statuses}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default AffiliateFilter
