/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { VOUCHER_DISCOUNT_TYPE_ENUM, VOUCHER_TYPE_ENUM, getEnums } from '../../../utilities/enum'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useVoucherService } from '../../../services/apiServices'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'

dayjs.extend(utc)

const VoucherEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const { user } = useAuth()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = useVoucherService()

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [discountType, setDiscountType] = useState()
  const [discountValue, setDiscountValue] = useState()
  const [maxDiscountAmount, setMaxDiscountAmount] = useState()
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [minOrderAmount, setMinOrderAmount] = useState()
  const [quantity, setQuantity] = useState()
  const [type, setType] = useState()
  const [isActive, setIsActive] = useState()

  const discountTypes = getEnums(VOUCHER_DISCOUNT_TYPE_ENUM, intl)
  const types = Object.keys(VOUCHER_TYPE_ENUM)
    .filter((key) => {
      const isSupplierRole = user.profile.role === ROLE.SUPPLIER
      const isSupplierVoucher = VOUCHER_TYPE_ENUM[key] === VOUCHER_TYPE_ENUM.VOUCHER_SUPPLIER

      return isSupplierRole ? isSupplierVoucher || VOUCHER_TYPE_ENUM[key] === VOUCHER_TYPE_ENUM.VOUCHER_SHIPPING : !isSupplierVoucher
    })
    .map((key) => ({
      value: VOUCHER_TYPE_ENUM[key],
      label: intl.formatMessage({ id: `Enum.${key}` }),
    }))

  useEffect(() => {
    if (modalState.show) {
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  useEffect(() => {
    if (discountType !== VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE) {
      setMaxDiscountAmount(discountValue)
    }
  }, [discountValue])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setName(item?.name)
    setDescription(item?.description)
    setFromDate(item?.fromDate ? dayjs.utc(item?.fromDate).local() : dayjs(new Date()))
    setToDate(item?.toDate ? dayjs.utc(item?.toDate).local() : null)
    setDiscountType(item?.discountType)
    setDiscountValue(item?.discountValue)
    setMaxDiscountAmount(item?.maxDiscountAmount)
    setType(item?.type ?? (user.profile.role === ROLE.SUPPLIER ? VOUCHER_TYPE_ENUM.VOUCHER_SUPPLIER : VOUCHER_TYPE_ENUM.VOUCHER_SYSTEM))
    setMinOrderAmount(item?.minOrderAmount)
    setQuantity(item?.quantity)
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const payload = {
      id: modalState.id,
      name,
      description,
      fromDate: fromDate.toISOString(),
      toDate: toDate?.toISOString(),
      discountType,
      discountValue,
      maxDiscountAmount,
      type,
      minOrderAmount,
      quantity,
      isActive,
    }

    const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-title'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.name = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Voucher.Name' })}
                  value={name}
                  onChange={setName}
                  isRequired={true}
                  tabIndex={tabIndex++}
                />
              </div>
              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountType = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Voucher.Type' })}
                  value={type}
                  isRequired={true}
                  onChange={setType}
                  tabIndex={tabIndex++}
                  options={types}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fromDate = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.FromDate' })}
                  value={fromDate}
                  isRequired={true}
                  onChange={setFromDate}
                  tabIndex={tabIndex++}
                  hasTime={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.toDate = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.ToDate' })}
                  value={toDate}
                  onChange={setToDate}
                  tabIndex={tabIndex++}
                  hasTime={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountType = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.DiscountType' })}
                  value={discountType}
                  isRequired={true}
                  onChange={setDiscountType}
                  options={discountTypes}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountType = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Promotion.DiscountValue' })}
                  value={discountValue}
                  isRequired={true}
                  onChange={setDiscountValue}
                  tabIndex={tabIndex++}
                  min={0}
                  max={discountType === VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE ? 100 : null}
                  suffix={discountType === VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE ? '%' : discountType === VOUCHER_DISCOUNT_TYPE_ENUM.FIXED_PRICE ? 'đ' : null}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.maxDiscountAmount = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Voucher.MaxDiscountAmount' })}
                  value={maxDiscountAmount}
                  onChange={setMaxDiscountAmount}
                  tabIndex={tabIndex++}
                  min={0}
                  suffix={DEFAULT_VALUE.VND}
                  isRequired={true}
                  readOnly={discountType !== VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.minOrderAmount = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Voucher.MinOrderAmount' })}
                  value={minOrderAmount}
                  onChange={setMinOrderAmount}
                  tabIndex={tabIndex++}
                  min={0}
                  suffix={DEFAULT_VALUE.VND}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.quantity = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.Voucher.Quantity' })}
                  value={quantity}
                  onChange={setQuantity}
                  tabIndex={tabIndex++}
                  min={0}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Ecom.Promotion.Description' })}
                value={description}
                rows={2}
                onChange={setDescription}
                tabIndex={tabIndex++}
              />
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />

          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default VoucherEditor
