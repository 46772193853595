import { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Table from '../../../components/shared/Table'
import { useWithdrawalHistoryService } from '../../../services/apiServices'
import TableSearch from '../../../components/shared/TableSearch'
import Icon from '../../../components/shared/Icon'
import { Tag } from 'antd'
import { WITHDRAWAL_STATUS_ENUM, getEnums } from '../../../utilities/enum'
import ApproveConfirm from '../../../components/shared/ApproveConfirm'
import { DATETIME_FORMAT_SLASH, DEFAULT_VALUE } from '../../../utilities/constant'
import Tabs from '../../../components/shared/Tabs'
import dayjs from 'dayjs'
import NumberFormat from '../../../components/shared/NumberFormat'
import TableFilter from '../../../components/shared/TableFilter'
import FinanceFilter from './FinanceFilter'

const Finances = () => {
  const intl = useIntl()
  const { getListAsync, approveAsync, rejectAsync } = useWithdrawalHistoryService()
  const [filter, setFilter] = useState()
  const [onReload, setOnReload] = useState(false)
  const [status, setStatus] = useState(WITHDRAWAL_STATUS_ENUM.NEW)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const statuses = getEnums(WITHDRAWAL_STATUS_ENUM, intl, 'User.Billing.History.Status')

  useEffect(() => {
    setOnReload(true)
    setSelectedRowKeys([])
  }, [filter, status])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter, status })
  }

  const handleApprove = async (id) => {
    const { error } = await approveAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleReject = async (id) => {
    const { error } = await rejectAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.User' })}`,
      key: 'user',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 150,
      showSorterTooltip: false,
      render: (record) => {
        return (
          <div className='d-flex flex-column gap-1'>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.User.FullName' />:{' '}
              </b>
              {record.fullName}
            </div>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.User.Role' />:{' '}
              </b>
              {record.role}
            </div>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.Status' />:{' '}
              </b>
              {record.status === WITHDRAWAL_STATUS_ENUM.NEW && (
                <ApproveConfirm
                  onApprove={() => handleApprove([record.id])}
                  onReject={() => handleReject([record.id])}
                  button={
                    <Tag color='blue' className='me-1 fs-7 cursor-pointer'>
                      {intl.formatMessage({ id: `User.Billing.History.Status.${record.statusName.toUpperCase()}` })}
                    </Tag>
                  }
                />
              )}

              {record.status !== WITHDRAWAL_STATUS_ENUM.NEW && (
                <Tag color={record.status === WITHDRAWAL_STATUS_ENUM.APPROVED ? 'cyan' : 'red'} className='me-1 fs-7'>
                  {intl.formatMessage({ id: `User.Billing.History.Status.${record.statusName.toUpperCase()}` })}
                </Tag>
              )}
            </div>
          </div>
        )
      },
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Account' })}`,
      key: 'bankAccount',
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      width: 150,
      showSorterTooltip: false,
      render: (record) => {
        return (
          <div className='d-flex flex-column gap-1'>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.Account.AccountNumber' />:{' '}
              </b>
              {record.accountNumber}
            </div>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.Account.HolderName' />:{' '}
              </b>
              {record.holderName}
            </div>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.Account.BankName' />:{' '}
              </b>
              {record.bankName}
            </div>
            <div>
              <b>
                <FormattedMessage id='User.Billing.History.Account.BranchName' />:{' '}
              </b>
              {record.branchName}
            </div>
          </div>
        )
      },
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Amount' })}`,
      dataIndex: 'withdrawalAmount',
      sorter: (a, b) => a.withdrawalAmount - b.withdrawalAmount,
      width: 100,
      showSorterTooltip: false,
      render: (withdrawalAmount) => <NumberFormat amount={withdrawalAmount} suffix={DEFAULT_VALUE.VND} />,
    },

    {
      title: `${intl.formatMessage({ id: 'User.Billing.History.Description' })}`,
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: status === WITHDRAWAL_STATUS_ENUM.APPROVED ? 'User.Billing.History.TransactionDate' : 'User.Billing.History.Date' })}`,
      dataIndex: 'transactionDate',
      sorter: (a, b) => {
        let dateA = status === WITHDRAWAL_STATUS_ENUM.APPROVED ? a.transactionDate : a.createdDate
        let dateB = status === WITHDRAWAL_STATUS_ENUM.APPROVED ? b.transactionDate : b.createdDate
        return dateA.localeCompare(dateB)
      },
      width: 80,
      showSorterTooltip: false,
      render: (transactionDate, record) => {
        let date = status === WITHDRAWAL_STATUS_ENUM.APPROVED ? transactionDate : record.createdDate
        return date ? dayjs.utc(date).local().format(DATETIME_FORMAT_SLASH.ddMMyyyyHHmm) : ''
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      hidden: (item) => item.status !== WITHDRAWAL_STATUS_ENUM.NEW,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <ApproveConfirm
            onApprove={() => handleApprove([item.id])}
            onReject={() => handleReject([item.id])}
            button={
              <div className='btn btn-sm btn-icon btn-light-primary'>
                <Icon iconName='CheckOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <ApproveConfirm
            onApprove={() => handleApprove(selectedRowKeys)}
            onReject={() => handleReject(selectedRowKeys)}
            button={
              <div className='btn btn-primary btn-sm'>
                <div className='d-flex align-items-center gap-2'>
                  <Icon iconName={'CheckOutlined'} />
                  {intl.formatMessage({ id: 'Confirm.Approve' })}
                </div>
              </div>
            }
          />
        )}
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<FinanceFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <Tabs
      header={statuses}
      selectedTab={status}
      onChange={setStatus}
      content={
        <div className='card mb-5'>
          <Table
            columns={columns}
            fetchData={fetchData}
            onReload={onReload}
            rowSelection={WITHDRAWAL_STATUS_ENUM.NEW === status ? rowSelection : false}
            toolbar={toolbar}
          />
        </div>
      }
    />
  )
}

export default Finances
