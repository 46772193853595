/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import _ from 'lodash'
import { useAuth } from '../../../components/auth/AuthContext'
import { checkIsActive } from '../../../utilities/sharedFunction'
import Icon from '../../../components/shared/Icon'
import { FormControl, CONTROL_TYPE } from '../../../components/shared/FormControl'
import NumberFormat from '../../../components/shared/NumberFormat'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import WithdrawalEditor from './components/WithdrawalEditor'
import { useAccountHeaderContext } from './components/AccountHeaderContext'

const AccountHeader = ({ userSettings }) => {
  const intl = useIntl()
  const { user } = useAuth()
  const location = useLocation()
  const componentRefs = useRef({})
  let tabIndex = 1
  const ecomUrl = process.env.REACT_APP_SLEEK_ECOM
  const { setReloadWithdrawalHistories, accountBalance, products, orders } = useAccountHeaderContext()

  const [fileAvatarUrl, setFileAvatarUrl] = useState()
  const [withdrawModal, setWithdrawModal] = useState({ id: null, show: false })

  useEffect(() => {
    setFileAvatarUrl([
      {
        url: user?.profile?.avatarUrl ?? `${ecomUrl}/media/images/no-avatar.png`,
      },
    ])
  }, [user])

  const showWithdrawModal = (id) => {
    setWithdrawModal({ id, show: true })
  }

  const hideWithdrawModal = () => {
    setWithdrawModal({ id: null, show: false })
  }

  return (
    <div className='card mb-5'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4 avatar'>
            <FormControl
              ref={(ref) => (componentRefs.current.thumbnail = ref)}
              controlType={CONTROL_TYPE.FILEUPLOAD}
              label={intl.formatMessage({ id: 'Prodventory.Product.Thumbnail' })}
              value={fileAvatarUrl}
              onChange={setFileAvatarUrl}
              accept='.png,.jpg,.jpeg'
              isRequired={true}
              isMultiple={false}
              isAvatar={true}
              tabIndex={tabIndex++}
              isHideLabel={true}
            />
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <a className='text-gray-800 text-hover-primary fs-2 fw-bold me-2'>{user?.profile?.fullName}</a>
                </div>

                <div className='d-flex flex-wrap fw-semibold fs-6 pe-2'>
                  <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 gap-1'>
                    <Icon iconName='UserOutlined' />
                    {user?.profile?.role}
                  </a>
                  <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 gap-1'>
                    <Icon iconName='MailOutlined' />
                    {user?.profile?.email}
                  </a>

                  {user?.profile?.phoneNumber && (
                    <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 gap-1'>
                      <Icon iconName='PhoneOutlined' />
                      +84 {user?.profile?.phoneNumber}
                    </a>
                  )}

                  {user?.profile?.address && (
                    <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary mb-2 gap-1'>
                      <img src={`/media/svg/icons/location.svg`} alt='icon' />
                      {user?.profile?.address ?? ''}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bold'>
                        <NumberFormat className='fs-2' amount={accountBalance} suffix={DEFAULT_VALUE.VND} />
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-500'>
                      <FormattedMessage id='User.Balance' />
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bold'>
                        <NumberFormat className='fs-2' amount={products} />
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-500'>
                      <FormattedMessage id='User.NumberProduct' />
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bold'>
                        <NumberFormat className='fs-2' amount={orders} />
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-500'>
                      <FormattedMessage id='User.NumberOrder' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-start align-items-xl-center w-200px w-sm-300px flex-column mt-5'>
                <div onClick={() => showWithdrawModal()} className='d-flex gap-2 btn btn-success'>
                  <Icon iconName='TransactionOutlined' />
                  <FormattedMessage id='Ecom.Supplier.Withdrawal' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-semibold flex-nowrap'>
            <>
              {userSettings.map((item) => {
                return (
                  <li className='nav-item' key={item.id}>
                    <Link to={item.path} className={`nav-link text-active-primary py-4 ${checkIsActive(location.pathname, item.to) ? 'active' : ''}`}>
                      <FormattedMessage id={item.title} />
                    </Link>
                  </li>
                )
              })}
            </>
          </ul>
        </div>
      </div>
      <WithdrawalEditor modalState={withdrawModal} handleClose={() => hideWithdrawModal()} reloadList={() => setReloadWithdrawalHistories(true)} />
    </div>
  )
}

export default AccountHeader
