import React, { forwardRef } from 'react'

const Checkbox = forwardRef((props, ref) => {
  const { value, placeholder, onChange, isFromSwitch, tabIndex, onBlur, readOnly, isValid, errorMessage, className } = props
  return (
    <>
      <label className={`form-check ${isFromSwitch ? 'form-switch' : ''} form-check-custom form-check-solid ${className}`}>
        <input
          tabIndex={tabIndex ?? 0}
          className='form-check-input cursor-pointer'
          type='checkbox'
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          onBlur={onBlur}
          disabled={readOnly}
          ref={ref}
        />
        <span className='form-check-label fw-semibold text-gray-800'>{placeholder}</span>
      </label>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default Checkbox
