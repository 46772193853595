import React, { useState } from 'react'
import dayjs from 'dayjs'
import { DatePicker, Button, Space, Tag } from 'antd'
import { useIntl } from 'react-intl'
import { DATETIME_FORMAT_SLASH } from '../../utilities/constant'

const { RangePicker: AntRangePicker } = DatePicker

const RangePicker = ({ dates, onChange, allowClear = true }) => {
  const intl = useIntl()
  const [visible, setVisible] = useState(false)

  const presetRanges = {
    [intl.formatMessage({ id: 'Today' })]: [dayjs(), dayjs()],
    [intl.formatMessage({ id: 'Yesterday' })]: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').startOf('day')],
    [intl.formatMessage({ id: 'Last7Days' })]: [dayjs().subtract(7, 'days').startOf('day'), dayjs().startOf('day')],
    [intl.formatMessage({ id: 'LastMonth' })]: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    [intl.formatMessage({ id: 'ThisMonth' })]: [dayjs().startOf('month'), dayjs().endOf('month')],
  }

  return (
    <AntRangePicker
      value={dates}
      onChange={onChange}
      format={DATETIME_FORMAT_SLASH.ddMMyyyy}
      open={visible}
      onDropdownVisibleChange={(open) => setVisible(open)}
      onClick={() => setVisible(true)}
      size='large'
      allowClear={allowClear}
      renderExtraFooter={() => (
        <Space className='d-flex justify-content-between' style={{ marginTop: 4, marginBottom: 4 }}>
          <div>
            {Object.entries(presetRanges).map(([label, range]) => (
              <Tag key={label} color='blue' style={{ cursor: 'pointer' }} onClick={() => onChange(range)}>
                {label}
              </Tag>
            ))}
          </div>
          <Button type='primary' onClick={() => setVisible(false)}>
            {intl.formatMessage({ id: 'Confirm.Ok' })}
          </Button>
        </Space>
      )}
    />
  )
}

export default RangePicker
