import React from 'react'
import { Link } from 'react-router-dom'
import { withPageData } from './PageData'

const Breadcrumb = ({ pageTitle, pageDescription, pageBreadcrumbs }) => {
  return (
    <div
      className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
      data-swapper='true'
      data-swapper-mode='prepend'
      data-swapper-parent="{default: 'content_container', lg: 'header_container'}"
    >
      <h4 className='text-dark fw-bolder my-0'>
        {pageTitle && pageTitle}
        {pageDescription && <span className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</span>}
      </h4>
      {pageBreadcrumbs && pageBreadcrumbs.length > 0 && (
        <ul className='breadcrumb fw-bold fs-base my-1'>
          {pageBreadcrumbs.map((item, index) => (
            <li className='breadcrumb-item text-muted' key={`${item.path}${index}`}>
              <Link to={item.path} className='text-muted'>
                {item.title}
              </Link>
            </li>
          ))}
          <li className='breadcrumb-item text-dark'>{pageTitle}</li>
        </ul>
      )}
    </div>
  )
}

export default withPageData(Breadcrumb)
