import { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import NumberFormat from '../../../../components/shared/NumberFormat'
import { DATETIME_FORMAT_SLASH, DEFAULT_VALUE } from '../../../../utilities/constant'
import dayjs from 'dayjs'
import { useSalesRevenueService } from '../../../../services/apiServices'
import Tabs from '../../../../components/shared/Tabs'
import Table from '../../../../components/shared/Table'

const SalesRevenues = () => {
  const intl = useIntl()
  const { getListAsync } = useSalesRevenueService()

  const [reloadData, setReloadData] = useState(false)
  const [selectedTab, setSelectedTab] = useState(1)
  const [dates, setDates] = useState([dayjs(), dayjs()])

  useEffect(() => {
    let startDate = null
    let endDate = null

    switch (selectedTab) {
      case 1:
        startDate = dayjs().subtract(6, 'month').startOf('month')
        endDate = dayjs().endOf('day')
        break
      case 2:
        startDate = dayjs().startOf('year')
        endDate = dayjs().endOf('year')
        break
      case 3:
      default:
        startDate = null
        endDate = null
        break
    }

    setReloadData(true)
    setDates([startDate, endDate])
  }, [selectedTab])

  const fetchData = async (pageIndex, pageSize) => {
    setReloadData(false)
    return await getListAsync({ pageIndex, pageSize, fromDate: dates[0], toDate: dates[1] })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'User.Billing.SalesRevenue.ReceievedDate' })}`,
      dataIndex: 'receivedDate',
      sorter: (a, b) => a.receivedDate.localeCompare(b.receivedDate),
      width: 100,
      showSorterTooltip: false,
      render: (receivedDate) => {
        return dayjs.utc(receivedDate).local().format(DATETIME_FORMAT_SLASH.ddMMyyyyHHmm)
      },
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.SalesRevenue.Order' })}`,
      dataIndex: 'orderId',
      sorter: (a, b) => a.orderId.localeCompare(b.orderId),
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'User.Billing.SalesRevenue.TotalAmount' })}`,
      dataIndex: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      width: 100,
      showSorterTooltip: false,
      render: (totalAmount) => <NumberFormat amount={totalAmount} suffix={DEFAULT_VALUE.VND} />,
    },
  ]

  var searchToolbar = [
    { value: 1, label: `${intl.formatMessage({ id: 'User.Billing.History.Month' })}` },
    { value: 2, label: `${intl.formatMessage({ id: 'User.Billing.History.Year' })}` },
    { value: 3, label: `${intl.formatMessage({ id: 'User.Billing.History.AllTime' })}` },
  ]

  return (
    <div className='card card-flush mt-4'>
      <div className='card-header border-0 py-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>
            <FormattedMessage id='User.Billing.SalesRevenue' />
          </span>
        </h3>

        <div className='card-toolbar m-0 align-items-center flex-column'>
          <Tabs header={searchToolbar} selectedTab={selectedTab} onChange={setSelectedTab} type={2} />
        </div>
      </div>
      <div className='card-body p-0'>
        <Table columns={columns} fetchData={fetchData} onReload={reloadData} />
      </div>
    </div>
  )
}

export default SalesRevenues
