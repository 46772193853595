import { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import { useVoucherService } from '../../../services/apiServices'
import VoucherFilter from './VoucherFilter'
import Icon from '../../../components/shared/Icon'
import VoucherEditor from './VoucherEditor'
import TableFilter from '../../../components/shared/TableFilter'
import NumberFormat from '../../../components/shared/NumberFormat'
import { VOUCHER_DISCOUNT_TYPE_ENUM } from '../../../utilities/enum'
import { DEFAULT_VALUE } from '../../../utilities/constant'

const Vouchers = () => {
  const intl = useIntl()
  const { getListAsync, deleteAsync } = useVoucherService()

  const [modalState, setModalState] = useState({ id: null, show: false })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const calculatePercentage = (usedNumber, maxUsesNumber) => {
    if (maxUsesNumber <= 0) return 0
    return ((usedNumber ?? 0) / maxUsesNumber) * 100
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Voucher.Code' })}`,
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Voucher.Name' })}`,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Voucher.FromDate' })}`,
      dataIndex: 'fromDateStr',
      sorter: (a, b) => a.fromDateStr.localeCompare(b.fromDateStr),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Voucher.DiscountType' })}`,
      key: 'discountType',
      sorter: (a, b) => a.discountType - b.discountType,
      width: 150,
      showSorterTooltip: false,
      render: (item) => (
        <div>
          <div className='d-flex flex-column'>
            {item.discountType === VOUCHER_DISCOUNT_TYPE_ENUM.FIXED_PRICE ? (
              <div className='d-flex gap-1'>
                <FormattedMessage
                  id='Ecom.Voucher.MaxDiscountAmount.Label'
                  values={{ maxDiscountAmount: <NumberFormat amount={item.discountValue} suffix={DEFAULT_VALUE.VND} /> }}
                />
              </div>
            ) : item.discountType === VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE ? (
              <div className='d-flex gap-1'>
                <FormattedMessage id='Ecom.Voucher.DiscountPercent' values={{ discountValue: item.discountValue }} />{', '}
                <FormattedMessage
                  id='Ecom.Voucher.MaxDiscountAmount.Label'
                  values={{ maxDiscountAmount: <NumberFormat amount={item.maxDiscountAmount} suffix={DEFAULT_VALUE.VND} /> }}
                />
              </div>
            ) : (
              <div className='d-flex gap-1'>
                <FormattedMessage
                  id='Ecom.Voucher.MaxDiscountAmount.Label'
                  values={{ maxDiscountAmount: <NumberFormat amount={item.maxDiscountAmount} suffix={DEFAULT_VALUE.VND} /> }}
                />
              </div>
            )}
            <div className='d-flex gap-1'>
              <FormattedMessage
                id='Ecom.Voucher.MinOrderAmount.Label'
                values={{ minOrderAmount: <NumberFormat amount={item.minOrderAmount ?? 0} suffix={DEFAULT_VALUE.VND} /> }}
              />
            </div>
          </div>
          <div className='d-flex gap-1'>
            {item.quantity && <FormattedMessage id='Ecom.Voucher.UsedNumber' values={{ usedNumber: calculatePercentage(item.timesUsed, item.quantity) }} />}{' '}
            {item.toDate && <FormattedMessage id='Ecom.Voucher.ToDate.Label' values={{ toDate: item.toDateStr }} />}
          </div>
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
            <Icon iconName='EditOutlined' />
          </div>
          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<VoucherFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
      <VoucherEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default Vouchers
