import useApiConnection from '../apiConnection'
import { PRODVENTORY_ENDPOINTS } from '../apiEndpoints'

const useProductService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, PRODVENTORY_ENDPOINTS.PRODUCT)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getByIdsAsync = async (ids) => {
    return await api.postAsync('get-by-ids', { ids })
  }

  const getListAsync = async (payload) => {
    return await api.postAsync('get-list', { payload })
  }

  const getListExportAsync = async (payload) => {
    return await api.postAsync('export-excel', { payload })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  const approveAsync = async (payload) => {
    return await api.postAsync(`approve`, { payload })
  }

  const rejectAsync = async (payload) => {
    return await api.postAsync(`reject`, { payload })
  }

  const countProductAsync = async () => {
    return await api.getAsync(`count-product`)
  }

  return { getByIdAsync, getListAsync, getByIdsAsync, insertAsync, updateAsync, deleteAsync, approveAsync, rejectAsync, getListExportAsync, countProductAsync }
}

export default useProductService
