import React, { useEffect } from 'react'
import { useAuthService } from '../../services/apiServices'
import { useAuth } from './AuthContext'

const Logout = () => {
  const { reloadUser } = useAuth()
  const { logoutAsync } = useAuthService()

  useEffect(() => {
    logoutAsync()
    reloadUser()
  }, [])

  return <></>
}

export default Logout
