import React from 'react'
import { Bar } from '@ant-design/plots'

const BarChart = ({ data }) => {
  const config = {
    data: data,
    xField: 'label',
    yField: 'value',
    colorField: 'label',
    legend: true,
    state: {
      unselected: { opacity: 0.5 },
      selected: { lineWidth: 3, stroke: 'red' },
    },
    interaction: {
      elementSelect: true,
    },
  }
  return <Bar {...config} />
}

export default BarChart
