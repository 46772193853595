export const supplierPermissions = [
  'user-overview',
  'user-settings',
  'user-billing',
  'user-changePassword',

  'prodventory-category-tag-list',
  'prodventory-product-list',
  'prodventory-product-add',
  'prodventory-product-update',
  'prodventory-warehouse-list',

  'ecom-dashboard',
  'ecom-order',
  'ecom-promotion',
  'ecom-promotion-product',
  'ecom-promotion-category',
  'ecom-promotion-supplier',
  'ecom-voucher'
]
