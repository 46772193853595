/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../../components/shared/Toast'
import { useBankAccountService } from '../../../../services/apiServices'

const BankAccountEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getBanksAsync, getByIdAsync, insertAsync, updateAsync } = useBankAccountService()

  const [accountNumber, setAccountNumber] = useState()
  const [holderName, setHolderName] = useState()
  const [bankId, setBankId] = useState()
  const [branchName, setBranchName] = useState()
  const [isActive, setIsActive] = useState(true)

  const [banks, setBanks] = useState([])

  useEffect(() => {
    const fetchBanks = async () => {
      const { result } = await getBanksAsync()
      if (result) {
        setBanks(
          result.map((item) => {
            return { value: item.id, label: `${item.name} (${item.code})` }
          })
        )
      }
    }

    if (modalState.show) {
      fetchBanks()
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setAccountNumber(item?.accountNumber)
    setHolderName(item?.holderName)
    setBankId(item?.bankId)
    setBranchName(item?.branchName)
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id: modalState.id,
      accountNumber,
      holderName,
      bankId,
      branchName,
      isActive,
    }

    const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-md'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-title'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.accountNumber = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Supplier.AccountNumber' })}
                value={accountNumber}
                onChange={setAccountNumber}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.holderName = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Supplier.HolderName' })}
                value={holderName}
                onChange={setHolderName}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                controlType={CONTROL_TYPE.SELECT}
                label={intl.formatMessage({ id: 'Ecom.Supplier.BankName' })}
                value={bankId}
                onChange={setBankId}
                tabIndex={tabIndex++}
                isRequired={true}
                options={banks}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Supplier.BranchName' })}
                value={branchName}
                onChange={setBranchName}
                tabIndex={tabIndex++}
              />
            </div>
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />

          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default BankAccountEditor
