import { useState, useEffect } from 'react'
import { FormControl, CONTROL_TYPE } from '../../../../components/shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'

const Thumbnail = ({ data, onChange, componentRefs }) => {
  const intl = useIntl()
  let tabIndex = 1

  const [thumbnail, setThumbnail] = useState()
  const [fileThumbnail, setFileThumbnail] = useState()

  useEffect(() => {
    onChange((prevData) => ({
      ...prevData,
      thumbnail,
      fileThumbnail,
    }))
  }, [fileThumbnail, thumbnail])

  useEffect(() => {
    setThumbnail(data?.thumbnail ?? null)
    setFileThumbnail(data?.fileThumbnail ?? [])
  }, [data])

  return (
    <div className='card card-flush'>
      <div className='card-header min-h-10px'>
        <div className='card-title'>
          <h4>
            <FormattedMessage id='Prodventory.Product.Thumbnail' />
          </h4>
        </div>
      </div>
      <div className='card-body text-center avatar'>
        <FormControl
          ref={(ref) => (componentRefs.current.thumbnail = ref)}
          controlType={CONTROL_TYPE.FILEUPLOAD}
          label={intl.formatMessage({ id: 'Prodventory.Product.Thumbnail' })}
          value={fileThumbnail}
          onChange={setFileThumbnail}
          accept='.png,.jpg,.jpeg'
          isRequired={true}
          isMultiple={false}
          isAvatar={true}
          tabIndex={tabIndex++}
          isHideLabel={true}
        />

        <div className='text-muted fs-7 mt-4'>
          <FormattedMessage id='Prodventory.Product.ThumbnailGuidance' />
        </div>
      </div>
    </div>
  )
}

export default Thumbnail
