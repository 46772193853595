import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { useSupplierService, useCategoryService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'

const FeaturedProductFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  const { getComboboxAsync: getSupplierComboboxAsync } = useSupplierService()
  const { getCurrentListAsync: getCategoriesAsync } = useCategoryService()

  let tabIndex = 1

  const [code, setCode] = useState(null)
  const [name, setName] = useState(null)
  const [supplierId, setSupplierId] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  useEffect(() => {
    const fetchSuppliers = async () => {
      const { result } = await getSupplierComboboxAsync()
      if (result) {
        setSuppliers(result)
      }
    }

    const fetchCategories = async () => {
      const { result } = await getCategoriesAsync()
      if (result) {
        setCategories(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchSuppliers()
    fetchCategories()
  }, [])

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      name,
      code,
      supplierId,
      categoryId,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
    }),
    reset: () => {
      setName(null)
      setCode(null)
      setSupplierId(null)
      setCategoryId(null)
      setFromDate(null)
      setToDate(null)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.code = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Prodventory.Product.Code' })}
          value={code}
          onChange={setCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Prodventory.Product.Name' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>

      {user.profile.role === ROLE.ADMIN && (
        <div className='col-md-12'>
          <FormControl
            controlType={CONTROL_TYPE.SELECT}
            ref={(ref) => (componentRefs.current.supplierId = ref)}
            label={intl.formatMessage({ id: 'Prodventory.Warehouse.SupplierName' })}
            value={supplierId}
            onChange={setSupplierId}
            tabIndex={tabIndex++}
            options={suppliers}
          />
        </div>
      )}

      <div className='col-md-12'>
        <FormControl
          controlType={CONTROL_TYPE.SELECT}
          ref={(ref) => (componentRefs.current.categoryId = ref)}
          label={intl.formatMessage({ id: 'Prodventory.Product.Category' })}
          value={categoryId}
          onChange={setCategoryId}
          tabIndex={tabIndex++}
          options={categories}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.fromDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.FeaturedProduct.FromDate' })}
          value={fromDate}
          onChange={setFromDate}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.toDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.FeaturedProduct.ToDate' })}
          value={toDate}
          onChange={(date) => setToDate(date)}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default FeaturedProductFilter
