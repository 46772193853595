/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Button from '../../../components/shared/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, CONTROL_TYPE, validationAllComponents } from '../../../components/shared/FormControl'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import { useLevelGroupService } from '../../../services/apiServices'

const LevelGroupEditor = ({ modalState, handleClose, reloadList }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync } = useLevelGroupService()

  const [fromRevenueAmount, setFromRevenueAmount] = useState()
  const [toRevenueAmount, setToRevenueAmount] = useState()
  const [levelNumber, setLevelNumber] = useState()
  const [percent, setPercent] = useState()
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (modalState.show) {
      modalState.id ? fetchData() : setData()
    } else {
      setData()
    }
  }, [modalState])

  const fetchData = async () => {
    const { result } = await getByIdAsync(modalState.id)
    if (result) {
      setData(result)
    }
  }

  const setData = (item) => {
    setFromRevenueAmount(item?.fromRevenueAmount)
    setToRevenueAmount(item?.toRevenueAmount)
    setLevelNumber(item?.levelNumber)
    setPercent(item?.percent)
    setIsActive(item?.isActive ?? true)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    var payload = {
      id: modalState.id ?? null,
      fromRevenueAmount,
      toRevenueAmount,
      levelNumber,
      percent,
      isActive,
    }

    const { error } = modalState.id ? await updateAsync(payload) : await insertAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadList()
      handleClose()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={modalState.show}
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-between'>
          <h5 className='modal-name'>
            <FormattedMessage id={modalState.id ? 'Update' : 'AddNew'} />
          </h5>
          <Button className='btn-icon btn-active-light-danger' onClick={handleClose} icon='CloseOutlined' />
        </div>
        <div className='modal-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelNumber = ref)}
                controlType={CONTROL_TYPE.NUMBERBOX}
                label={intl.formatMessage({ id: 'Ecom.LevelGroup.Level' })}
                value={levelNumber}
                onChange={setLevelNumber}
                tabIndex={tabIndex++}
                isRequired={true}
                min={0}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.percent = ref)}
                controlType={CONTROL_TYPE.NUMBERBOX}
                label={intl.formatMessage({ id: 'Ecom.LevelGroup.Percent' })}
                value={percent}
                onChange={setPercent}
                tabIndex={tabIndex++}
                isRequired={true}
                min={0}
                max={100}
                suffix={'%'}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fromRevenueAmount = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.LevelGroup.FromRevenueAmount' })}
                  value={fromRevenueAmount}
                  onChange={setFromRevenueAmount}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  min={0}
                  suffix={'đ'}
                />
              </div>
              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.maintainForMonths = ref)}
                  controlType={CONTROL_TYPE.NUMBERBOX}
                  label={intl.formatMessage({ id: 'Ecom.LevelGroup.ToRevenueAmount' })}
                  value={toRevenueAmount}
                  onChange={setToRevenueAmount}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  min={0}
                  suffix={'đ'}
                />
              </div>
            </div>

            {modalState.id && (
              <div className='col-md-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  controlType={CONTROL_TYPE.CHECKBOX}
                  label={intl.formatMessage({ id: 'IsActive' })}
                  value={isActive}
                  onChange={setIsActive}
                  isFromSwitch={true}
                  tabIndex={tabIndex++}
                />
              </div>
            )}
          </form>
        </div>
        <div className='modal-footer gap-2'>
          <Button
            title={intl.formatMessage({ id: 'Save' })}
            icon='SaveOutlined'
            className='btn-success'
            onClick={handleSave}
            tabIndex={tabIndex++}
            disabled={false}
          />
          <Button title={intl.formatMessage({ id: 'Close' })} icon='CloseOutlined' onClick={handleClose} tabIndex={tabIndex++} disabled={false} />
        </div>
      </div>
    </Modal>
  )
}

export default LevelGroupEditor
