import React from 'react'
import { Popconfirm, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const Confirm = ({ title, description, onConfirm, button, tooltip }) => {
  const intl = useIntl()

  const confirm = async () => {
    await onConfirm()
  }

  return (
    <Popconfirm
      title={title ?? intl.formatMessage({ id: 'Notifications' })}
      description={description ?? intl.formatMessage({ id: 'Confirm.Remove' })}
      onConfirm={onConfirm}
      okText={intl.formatMessage({ id: 'Confirm.Ok' })}
      cancelText={intl.formatMessage({ id: 'Confirm.Cancel' })}
      okButtonProps={{
        type: 'primary',
        icon: <CheckOutlined />,
      }}
      cancelButtonProps={{
        type: 'default',
        icon: <CloseOutlined />,
      }}
    >
      <Tooltip title={tooltip}>{button}</Tooltip>
    </Popconfirm>
  )
}
export default Confirm
