import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Table from '../../../components/shared/Table'
import TableSearch from '../../../components/shared/TableSearch'
import Icon from '../../../components/shared/Icon'
import TableFilter from '../../../components/shared/TableFilter'
import { useProductService } from '../../../services/apiServices'
import { Tag, Tooltip } from 'antd'
import { useAuth } from '../../../components/auth/AuthContext'
import { BASE_URL_API_SLEEK_PROVENTOY, ROLE } from '../../../utilities/constant'
import { PRODUCT_STATUS_ENUM, getEnums } from '../../../utilities/enum'
import { MODULE } from '../../../utilities/constant'
import ApproveConfirm from '../../../components/shared/ApproveConfirm'
import Select from '../../../components/shared/Select'
import ChildTable from '../../../components/shared/ChildTable'
import NumberFormat from '../../../components/shared/NumberFormat'
import ProductFilter from './ProductFilter'
import { downloadFile } from '../../../utilities/sharedFunction'

const Products = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const navigate = useNavigate()
  const { getListAsync, deleteAsync, approveAsync, rejectAsync, getListExportAsync } = useProductService()
  const [filter, setFilter] = useState({})
  const [onReload, setOnReload] = useState()
  const [status, setStatus] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const statuses = getEnums(PRODUCT_STATUS_ENUM, intl)

  useEffect(() => {
    setOnReload(true)
    setSelectedRowKeys([])
  }, [filter, status])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter, status })
  }

  const handleExportExcel = async () => {
    setOnReload(false)
    const { result } = await getListExportAsync({ ...filter, status })
    if (result && result !== '') {
      downloadFile(BASE_URL_API_SLEEK_PROVENTOY + result)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleApprove = async (id) => {
    const { error } = await approveAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleReject = async (id) => {
    const { error } = await rejectAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Thumbnail' })}`,
      dataIndex: 'fileThumbnail',
      width: 60,
      render: (fileThumbnail) => (
        <div className='symbol d-flex justify-content-center'>
          <img className={`symbol-label`} src={fileThumbnail.url} alt={fileThumbnail.name} />
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Name' })}`,
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 150,
      showSorterTooltip: false,
      render: (data) => (
        <div className='d-flex flex-column'>
          {data.name}
          <div className='d-flex gap-1'>
            {data.status === PRODUCT_STATUS_ENUM.PENDINGAPPROVAL && (
              <ApproveConfirm
                onApprove={() => handleApprove([data.id])}
                onReject={() => handleReject([data.id])}
                button={
                  <Tag
                    color={`${
                      data.status === PRODUCT_STATUS_ENUM.PENDINGAPPROVAL
                        ? 'volcano'
                        : data.status === PRODUCT_STATUS_ENUM.APPROVED
                        ? 'blue'
                        : data.status === PRODUCT_STATUS_ENUM.STOPSELLING
                        ? 'red'
                        : 'default'
                    }`}
                    className='me-1 fs-7 cursor-pointer'
                  >
                    {intl.formatMessage({ id: `Enum.${data.statusName.toUpperCase()}` })}
                  </Tag>
                }
              />
            )}

            {data.status !== PRODUCT_STATUS_ENUM.PENDINGAPPROVAL && (
              <Tag
                color={`${
                  data.status === PRODUCT_STATUS_ENUM.PENDINGAPPROVAL
                    ? 'volcano'
                    : data.status === PRODUCT_STATUS_ENUM.APPROVED
                    ? 'blue'
                    : data.status === PRODUCT_STATUS_ENUM.STOPSELLING
                    ? 'red'
                    : 'default'
                }`}
                className='me-1 fs-7'
              >
                {intl.formatMessage({ id: `Enum.${data.statusName.toUpperCase()}` })}
              </Tag>
            )}
          </div>
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Category' })}`,
      dataIndex: 'categoryName',
      width: 80,
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.SubCategory' })}`,
      dataIndex: 'subCategoryName',
      width: 80,
      sorter: (a, b) => a.subCategoryName.localeCompare(b.subCategoryName),
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.CategoryTag' })}`,
      dataIndex: 'tagNames',
      width: 100,
      render: (tagNames) =>
        tagNames ? (
          <>
            {tagNames.map((tag, index) => (
              <Tag key={index} color='blue' className='me-1 mb-1 fs-7'>
                {tag}
              </Tag>
            ))}
          </>
        ) : (
          ''
        ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Supplier' })}`,
      dataIndex: 'supplierName',
      sorter: (a, b) => a.supplierName.localeCompare(b.supplierName),
      showSorterTooltip: false,
      width: 100,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => {
        return (
          <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
            <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
              <div onClick={() => navigate(`/${MODULE.PRODVENTORY}/products/${item.id}`)} className='btn btn-sm btn-icon btn-light-success'>
                <Icon iconName='EditOutlined' />
              </div>
            </Tooltip>

            <Confirm
              title={intl.formatMessage({ id: 'Notifications' })}
              description={intl.formatMessage({ id: 'Confirm.Remove' })}
              onConfirm={() => deleteData(item.id)}
              tooltip={intl.formatMessage({ id: 'Delete' })}
              button={
                <div className='btn btn-sm btn-icon btn-light-danger'>
                  <Icon iconName='DeleteOutlined' />
                </div>
              }
            />
          </div>
        )
      },
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const toolbar = (
    <div className='d-flex justify-content-between flex-column flex-md-row gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success'
          onClick={() => navigate(`/${MODULE.PRODVENTORY}/products/add-new`)}
          tabIndex={0}
        />

        <Button
          title={intl.formatMessage({ id: 'ExportExcel' })}
          icon='ExportOutlined'
          className='btn-primary'
          onClick={() => handleExportExcel()}
          tabIndex={1}
          disabled={false}
        />

        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <ApproveConfirm
            onApprove={() => handleApprove(selectedRowKeys)}
            onReject={() => handleReject(selectedRowKeys)}
            button={
              <div className='btn btn-primary btn-sm'>
                <div className='d-flex align-items-center gap-2'>
                  <Icon iconName={'CheckOutlined'} />
                  {intl.formatMessage({ id: 'Confirm.Approve' })}
                </div>
              </div>
            }
          />
        )}
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<ProductFilter />} />
        <div className='w-200px'>
          <Select value={status} onChange={setStatus} options={statuses} placeholder={intl.formatMessage({ id: 'Ecom.Supplier.Status' })} />
        </div>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table
        columns={columns}
        fetchData={fetchData}
        onReload={onReload}
        toolbar={toolbar}
        rowSelection={PRODUCT_STATUS_ENUM.PENDINGAPPROVAL === status ? rowSelection : false}
        colExpandable={{
          expandedRowRender: (record) => {
            if (!record.details || record.details.length === 0) {
              return null
            }

            const firstDetail = record.details[0]

            const childColumns = [
              {
                title: `${intl.formatMessage({ id: 'Prodventory.Product.Code' })}`,
                dataIndex: 'code',
                width: 100,
              },
              {
                title: `${intl.formatMessage({ id: 'Prodventory.Product.SellingPrice' })}`,
                key: 'sellingPrice',
                width: 80,
                render: (item) => <NumberFormat amount={item.sellingPrice} suffix='đ' />,
              },
              {
                title: `${intl.formatMessage({ id: 'Prodventory.Product.PercentageCommission' })}`,
                key: 'percentageDiscount',
                width: 50,
                render: (item) => <div className='d-flex gap-1'>{item.percentageDiscount}%</div>,
              },
              {
                title: `${intl.formatMessage({ id: 'Prodventory.Product.PercentageDiscount' })}`,
                key: 'percentageCommission',
                width: 50,
                render: (item) => <div className='d-flex gap-1'>{item.percentageCommission}%</div>,
              },
              {
                title: `${intl.formatMessage({ id: 'Prodventory.Product.Stock' })}`,
                dataIndex: 'stock',
                width: 50,
              },
            ]

            firstDetail.attributeValues?.forEach((attr, index) => {
              if (!_.isEmpty(attr.attributeName)) {
                childColumns.push({
                  title: attr.attributeName || `Attribute ${index + 1}`,
                  key: `attributeValue-${index + 1}`,
                  render: (record) => record.attributeValues[index]?.value || '',
                  width: 50,
                })
              }
            })

            return <ChildTable columns={childColumns} dataSource={record.details} />
          },
        }}
      />
    </div>
  )
}

export default Products
