import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import Tabs from '../../../components/shared/Tabs'
import { FEATURED_PRODUCT_TYPE_ENUM, getEnums } from '../../../utilities/enum'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import Icon from '../../../components/shared/Icon'
import { useFeaturedProductService } from '../../../services/apiServices'
import Table from '../../../components/shared/Table'
import TableSearch from '../../../components/shared/TableSearch'
import TableFilter from '../../../components/shared/TableFilter'
import Checkbox from '../../../components/shared/Checkbox'
import FeaturedProductEditor from './FeaturedProductEditor'
import FeaturedProductFilter from './FeaturedProductFilter'

const FeaturedProducts = () => {
  const intl = useIntl()
  const { getListAsync, deleteAsync, deleteRangeAsync } = useFeaturedProductService()

  const [modalState, setModalState] = useState({ id: null, show: false })
  const [type, setType] = useState(FEATURED_PRODUCT_TYPE_ENUM.TOPSELLING)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)
  const types = getEnums(FEATURED_PRODUCT_TYPE_ENUM, intl, 'Ecom.FeaturedProduct')

  useEffect(() => {
    setOnReload(true)
  }, [filter, type])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter, type })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const deleteRange = async () => {
    const { error } = await deleteRangeAsync(selectedRowKeys)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = () => {
    setModalState({ show: true, type })
  }

  const hideModal = () => {
    setModalState({ show: false })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Thumbnail' })}`,
      key: 'thumbnail',
      width: 60,
      render: (item) => (
        <div className='symbol d-flex justify-content-center'>
          <img className={`symbol-label`} src={item.product.fileThumbnail.url} alt={item.product.fileThumbnail.name} />
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Name' })}`,
      key: 'name',
      sorter: (a, b) => a.product.name.localeCompare(b.product.name),
      width: 150,
      showSorterTooltip: false,
      render: (item) => item.product.name,
    },
    {
      title: `${intl.formatMessage({ id: 'Prodventory.Product.Supplier' })}`,
      key: 'supplierName',
      sorter: (a, b) => a.product.supplierName.localeCompare(b.product.supplierName),
      showSorterTooltip: false,
      width: 100,
      render: (item) => item.product.supplierName,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.FromDate' })}`,
      dataIndex: 'fromDateStr',
      sorter: (a, b) => a.fromDateStr.localeCompare(b.fromDateStr),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Promotion.ToDate' })}`,
      dataIndex: 'toDateStr',
      sorter: (a, b) => a.toDateStr?.localeCompare(b.toDateStr),
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button title={intl.formatMessage({ id: 'AddNew' })} icon='PlusOutlined' className='btn-success' onClick={showModal} tabIndex={0} disabled={false} />

        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteRange()}
            button={
              <div className='btn btn-danger btn-sm'>
                <div className='d-flex align-items-center gap-2'>
                  <Icon iconName={'DeleteOutlined'} />
                  {intl.formatMessage({ id: 'Delete' })}
                </div>
              </div>
            }
          />
        )}
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<FeaturedProductFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <>
      <Tabs
        header={types}
        selectedTab={type}
        onChange={setType}
        content={
          <div className='card mb-5'>
            <Table
              columns={columns}
              fetchData={fetchData}
              onReload={onReload}
              toolbar={toolbar}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  setSelectedRowKeys(selectedRowKeys)
                },
              }}
            />
          </div>
        }
      />
      <FeaturedProductEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </>
  )
}

export default FeaturedProducts
