import { forwardRef, useEffect, useState } from 'react'
import { Upload, Image, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useIntl, FormattedMessage } from 'react-intl'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import { getBase64 } from '../../utilities/sharedFunction'

const FileUpload = forwardRef((props, ref) => {
  const intl = useIntl()
  const { onChange, value, accept, tabIndex, isValid, errorMessage, isMultiple, isAvatar } = props
  const maxFileSize = 5 * 1024 * 1024
  const [fileList, setFileList] = useState([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  useEffect(() => {
    const updateFileList = async () => {
      const transformedFiles = await transformFileData()
      setFileList(transformedFiles)
    }

    updateFileList()
  }, [value])

  const transformFileData = async () => {
    if (!value) return []

    const filesArray = Array.isArray(value) ? value : [value]

    return await Promise.all(
      filesArray.map(async (file) => {
        return {
          uid: file.id,
          name: file.name,
          status: 'done',
          url: file.url,
          size: file.size,
          type: file.type,
          preview: file.originFileObj ? await getBase64(file.originFileObj) : file.url,
          originFileObj: file.originFileObj ?? null,
        }
      })
    )
  }

  const beforeUpload = (file) => {
    if (file.size > maxFileSize) {
      showToast(`${intl.formatMessage({ id: 'FileSizeExceeded' })}`, TOAST_TYPES.ERROR)
      return Upload.LIST_IGNORE
    }

    return false
  }

  const handleChange = ({ fileList: newFileList }) => {
    const newFiles = newFileList?.map((file) => ({
      id: file.uid,
      name: file.name,
      url: file.url,
      size: file.size,
      type: file.type,
      originFileObj: file.originFileObj,
    }))

    onChange(newFiles)
  }

  const handlePreview = async (file) => {
    setPreviewImage(file.preview)
    setPreviewVisible(true)
  }

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type='button'
      tabIndex={tabIndex}
    >
      <UploadOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        <FormattedMessage id='SelectFile' />
      </div>
    </button>
  )

  return (
    <>
      <Upload
        ref={ref}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onPreview={handlePreview}
        listType={isMultiple || isAvatar ? 'picture-card' : 'picture'}
        fileList={fileList}
        accept={accept}
        multiple={isMultiple}
      >
        {!isMultiple && fileList.length >= 1 ? null : uploadButton}
      </Upload>

      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}

      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewVisible,
            onVisibleChange: (visible) => setPreviewVisible(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  )
})

export default FileUpload
