import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { Input } from 'antd'
const { Search } = Input

const TableSearch = ({ onSearch }) => {
  const intl = useIntl()
  const [searchValue, setSearchValue] = useState('')
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    return () => clearTimeout(timer)
  }, [timer])

  const handleSearch = (value) => {
    clearTimeout(timer)

    onSearch((prevFilter) => ({
      ...prevFilter,
      search: value,
    }))
  }

  const onChange = (value) => {
    setSearchValue(value)
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      handleSearch(value)
    }, 1000)

    setTimer(newTimer)
  }

  return (
    <Search
      placeholder={`${intl.formatMessage({ id: 'Search' })}...`}
      allowClear
      onSearch={(e) => handleSearch(e)}
      onChange={(e) => onChange(e.target.value)}
      value={searchValue}
      style={{
        width: 250,
      }}
      size='large'
    />
  )
}

export default TableSearch
