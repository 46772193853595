import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useAffiliateService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.AFFILIATE)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getListExportAsync = async (payload) => {
    return await api.postAsync('export-excel', { payload })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  const approveAsync = async (payload) => {
    return await api.postAsync(`approve`, { payload })
  }

  const rejectAsync = async (payload) => {
    return await api.postAsync(`reject`, { payload })
  }

  return { getByIdAsync, getListAsync, insertAsync, updateAsync, deleteAsync, approveAsync, rejectAsync, getListExportAsync }
}

export default useAffiliateService
