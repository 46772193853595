import { useEffect } from 'react'
import { DrawerComponent, MenuComponent, ScrollComponent, ToggleComponent } from '../../../../assets/ts/components/index.ts'
import Menu from './Menu'
import { ecomMenuItems } from '../../../../routing/EcomRoutes.js'
import { MODULE } from '../../../../utilities/constant.js'
import { getMenuItems } from '../../../../utilities/sharedFunction.js'
import { useAuth } from '../../../auth/AuthContext.js'

const EcomTab = () => {
  const { userPermissions } = useAuth()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
  }, [])

  return (
    <div
      className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary fw-semibold fs-6 px-2'
      id='eco_menu'
      data-kt-menu='true'
    >
      <div id='eco_menu_menu_wrapper' className='menu-fit'>
        <Menu menuItems={getMenuItems(ecomMenuItems, userPermissions)} prefix={MODULE.ECOM} />
      </div>
    </div>
  )
}

export default EcomTab
