import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import Button from './Button'
import { MenuComponent } from '../../assets/ts/components/index.ts'

const TableFilter = ({ onSearch, form, size = 'w-300px' }) => {
  const intl = useIntl()
  const filterMenuRef = useRef()
  const filterFormRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  function handleClickOutside() {
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
    })

    if (filterMenuRef.current) {
      filterMenuRef.current.dispatchEvent(event)
    }
  }

  const handleApply = () => {
    if (filterFormRef.current) {
      const values = filterFormRef.current.getValues()
      onSearch((prevFilter) => ({
        ...prevFilter,
        ...values,
      }))
    }

    handleClickOutside()
  }

  const handleReset = () => {
    if (filterFormRef.current) {
      filterFormRef.current.reset()
    }
  }

  return (
    <>
      <div
        ref={filterMenuRef}
        className='cursor-pointer symbol symbol-35px'
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-start'
      >
        <Button title={intl.formatMessage({ id: 'Filter' })} icon='FilterOutlined' className='btn-light-info' />
      </div>
      <div
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded shadow rounded p-4 mt-2 ${size}`}
        data-kt-menu='true'
        style={{
          zIndex: 105,
          position: 'fixed',
          inset: '0px auto auto 0px',
          margin: '0px',
          transform: 'translate(852px, 167px)',
          maxHeight: '400px',
          overflowY: 'hidden',
        }}
      >
        <div className='menu-item'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='text-dark fw-bold'>{intl.formatMessage({ id: 'FilterOptions' })}</h5>
            <Button className='btn-close btn-active-light-danger btn-icon' onClick={handleClickOutside} />
          </div>
        </div>
        <div className='separator my-2' />
        <div style={{ overflowY: 'auto', flex: 1 }}>{React.cloneElement(form, { ref: filterFormRef })}</div>
        <div className='separator my-2' />
        <div className='d-flex justify-content-end gap-4'>
          <Button onClick={handleReset} title={intl.formatMessage({ id: 'Reset' })} icon='ClearOutlined' />
          <Button type='button' className='btn-success' onClick={handleApply} title={intl.formatMessage({ id: 'Apply' })} icon='CheckOutlined' />
        </div>
      </div>
    </>
  )
}

export default TableFilter
