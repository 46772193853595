import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import Confirm from '../../../components/shared/Confirm'
import BannerEditor from './BannerEditor'
import Table from '../../../components/shared/Table'
import { useBannerService } from '../../../services/apiServices'
import Checkbox from '../../../components/shared/Checkbox'
import TableSearch from '../../../components/shared/TableSearch'
import TableFilter from '../../../components/shared/TableFilter'
import BannerFilter from './BannerFilter'
import Icon from '../../../components/shared/Icon'
import { Tooltip } from 'antd'

const Banners = () => {
  const intl = useIntl()
  const { getListAsync, deleteAsync } = useBannerService()
  const [modalState, setModalState] = useState({ id: null, show: false })
  const [filter, setFilter] = useState({ isActive: true })
  const [onReload, setOnReload] = useState(false)

  useEffect(() => {
    setOnReload(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setOnReload(false)
    return await getListAsync({ pageIndex, pageSize, ...filter })
  }

  const deleteData = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setOnReload(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const showModal = (id) => {
    setModalState({ id, show: true })
  }

  const hideModal = () => {
    setModalState({ id: null, show: false })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.Position' })}`,
      dataIndex: 'positionName',
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 60,
      render: (positionName) => intl.formatMessage({ id: `Enum.${positionName.toUpperCase()}` }),
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.Title' })}`,
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 150,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.Promotion' })}`,
      dataIndex: 'promotion',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => {
        const promotionA = a.promotion ? a.promotion.name : ''
        const promotionB = b.promotion ? b.promotion.name : ''
        return promotionA.localeCompare(promotionB)
      },
      render: (promotion) => (promotion ? promotion.name : ''),
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.FromDate' })}`,
      dataIndex: 'fromDateStr',
      sorter: (a, b) => a.fromDate - b.fromDate,
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.ToDate' })}`,
      dataIndex: 'toDateStr',
      sorter: (a, b) => a.toDate - b.toDate,
      width: 80,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Ecom.Banner.IsActive' })}`,
      dataIndex: 'isActive',
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          <Checkbox value={isActive} readOnly={true} />
        </div>
      ),
      width: 60,
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
            <div onClick={() => showModal(item.id)} className='btn btn-sm btn-icon btn-light-success'>
              <Icon iconName='EditOutlined' />
            </div>
          </Tooltip>

          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => deleteData(item.id)}
            tooltip={intl.formatMessage({ id: 'Delete' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-success me-5'
          onClick={() => showModal()}
          tabIndex={0}
          disabled={false}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableFilter onSearch={setFilter} form={<BannerFilter />} />
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5'>
      <Table columns={columns} fetchData={fetchData} onReload={onReload} toolbar={toolbar} />
      <BannerEditor modalState={modalState} handleClose={() => hideModal()} reloadList={() => setOnReload(true)} />
    </div>
  )
}

export default Banners
