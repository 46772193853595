import { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'
import { useIntl } from 'react-intl'
import { ACTIVE_ENUM, getEnums, VOUCHER_DISCOUNT_TYPE_ENUM, VOUCHER_TYPE_ENUM } from '../../../utilities/enum'

const VoucherFilter = forwardRef((_, ref) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1

  const [code, setCode] = useState(null)
  const [name, setName] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [discountType, setDiscountType] = useState(null)
  const [type, setType] = useState(null)
  const [isActive, setIsActive] = useState(true)

  const statuses = getEnums(ACTIVE_ENUM, intl)
  const types = getEnums(VOUCHER_TYPE_ENUM, intl)
  const discountTypes = getEnums(VOUCHER_DISCOUNT_TYPE_ENUM, intl)

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      code,
      name,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
      discountType,
      type,
      isActive,
    }),
    reset: () => {
      setCode(null)
      setName(null)
      setFromDate(null)
      setToDate(null)
      setDiscountType(null)
      setType(null)
      setIsActive(true)
    },
  }))

  return (
    <form className='d-flex flex-column gap-4'>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Voucher.Code' })}
          value={code}
          onChange={setCode}
          tabIndex={tabIndex++}
        />
      </div>

      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.name = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Ecom.Voucher.Name' })}
          value={name}
          onChange={setName}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.fromDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Voucher.FromDate' })}
          value={fromDate}
          onChange={setFromDate}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.toDate = ref)}
          controlType={CONTROL_TYPE.DATEPICKER}
          label={intl.formatMessage({ id: 'Ecom.Voucher.ToDate' })}
          value={toDate}
          onChange={(date) => setToDate(date)}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.discountType = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Voucher.DiscountType' })}
          value={discountType}
          onChange={setDiscountType}
          options={discountTypes}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.type = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'Ecom.Voucher.Type' })}
          value={type}
          onChange={setType}
          options={types}
          tabIndex={tabIndex++}
        />
      </div>
      <div className='col-md-12'>
        <FormControl
          ref={(ref) => (componentRefs.current.positionId = ref)}
          controlType={CONTROL_TYPE.SELECT}
          label={intl.formatMessage({ id: 'IsActive' })}
          value={isActive}
          onChange={setIsActive}
          options={statuses}
          tabIndex={tabIndex++}
        />
      </div>
    </form>
  )
})

export default VoucherFilter
