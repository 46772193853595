/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../contexts/I18nContext'
import { FormattedMessage } from 'react-intl'

const AuthLayout = () => {
  const homeUrl = process.env.REACT_APP_SLEEK_HOME

  return (
    <I18nProvider>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid auth-bg-9'>
        <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
          <div className='d-flex flex-center flex-lg-start flex-column'>
            <a href='index.html' className='mb-7'>
              <img alt='Logo' src='/media/logos/logo_large.png' />
            </a>
            <h2 className='text-white fw-normal m-0'>Mẫu trang web cửa hàng tạp hóa tuyệt vời</h2>
          </div>
        </div>

        <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20'>
            <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20'>
              <Outlet />
            </div>
            <div className='d-flex flex-stack px-lg-10'>
              <div className='me-0'>{/* @await Html.PartialAsync("_CultureSelector") */}</div>
              <div className='d-flex fw-semibold text-success fs-base gap-5'>
                <a href={`${homeUrl}/dieu-khoan-su-dung-dich-vu`} target='_blank' className='link-success'>
                  <FormattedMessage id='TermsLink' />
                </a>
                <a href={`${homeUrl}/lien-he`} target='_blank' className='link-success'>
                  <FormattedMessage id='ContactLink' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </I18nProvider>
  )
}

export default AuthLayout
