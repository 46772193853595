/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import Icon from '../../../shared/Icon'
import { useIntl } from 'react-intl'
import { MODULE } from '../../../../utilities/constant'

const Tabs = ({ currentTab, setTab }) => {
  const intl = useIntl()

  var [modules] = useState([
    {
      id: MODULE.ECOM,
      title: 'Menu.Ecom',
      path: '/ecom',
      icon: 'ShopOutlined',
      component: 'EcommTab',
    },
    {
      id: MODULE.PRODVENTORY,
      title: 'Menu.Prodventory',
      path: '/prodventory',
      icon: 'ProductOutlined',
      component: 'ProdventoryTab',
    },
    {
      id: MODULE.SETTINGS,
      title: 'Menu.Settings',
      path: '/setting',
      icon: 'SettingOutlined',
      component: 'SettingTab',
    },
  ])

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      <ul className='nav flex-column' id='kt_aside_nav_tabs'>
        {modules.map((t, index) => {
          return (
            <li key={index} className='pb-4'>
              <a
                className={`nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light ${t.id === currentTab ? 'active' : ''}`}
                title={intl.formatMessage({ id: t.title })}
                onClick={() => setTab(t.id)}
              >
                <Icon iconName={t.icon} className='fs-2x' />
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Tabs
