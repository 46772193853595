/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Breadcrumb from './breadcrumb/Breadcrumb'
import ThemeMode from './ThemeMode'
import Languages from './Languages'
import Notification from './Notification'
import Icon from '../../shared/Icon'
import UserMenu from './UserMenu'
import { useAuth } from '../../../components/auth/AuthContext'

const Header = () => {
  const { user } = useAuth()
  const [offset] = useState(`{default: '200px', lg: '300px'}`)

  return (
    <div id='kt_header' className='header' data-kt-sticky='true' data-kt-sticky-name='header' data-kt-sticky-offset={offset}>
      <div className='container-xxl d-flex align-items-center justify-content-between' id='header_container'>
        <Breadcrumb />

        <div className='d-flex flex-shrink-0 gap-4'>
          <div className='d-flex align-items-center'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-40px h-40px menu-dropdown'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <Icon iconName='BellOutlined' className='fs-5' />
            </div>
            <Notification />
          </div>
          <div className='d-flex align-items-center'>
            <ThemeMode />
          </div>

          <div className='d-flex align-items-center'>
            <Languages />
          </div>

          <div className='app-navbar-item'>
            <div
              className='cursor-pointer symbol symbol-35px'
              data-kt-menu-trigger="{default: 'click'}"
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
            >
              <img src={user?.profile?.avatarUrl ?? '/media/images/no-avatar.png'} alt='avatar' />
            </div>
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
